import React, { FC } from "react";
import { ModalFooterWrapper, FooterButtonWrapper, CancelBtn, ImportBtn} from "./modal.footer.style";
import { getThemeReducer, useAppSelector } from "../../../../store";

interface IModalFooter {
	isDisabled?: boolean
	cancelButtonText: string
	actionButtonText: JSX.Element | string
	handleClose: () => void
	onActiveClick: () => void
}

const ModalFooter: FC<IModalFooter> = ({ cancelButtonText = '', actionButtonText = '', handleClose, onActiveClick, isDisabled}) => {
		const {themeMode} = useAppSelector(getThemeReducer);

    return (
				<ModalFooterWrapper>
					{/* @ts-ignore */}
					<FooterButtonWrapper
						cancelButtonText={cancelButtonText}
						actionButtonText={actionButtonText}
					>
						{cancelButtonText !== '' && (
							<CancelBtn sx={{themeMode}} onClick={() => handleClose()}>
								{cancelButtonText}
							</CancelBtn>
						)}
						{actionButtonText !== '' && (
							<ImportBtn sx={{themeMode}} disabled={isDisabled} onClick={() => onActiveClick()}>{actionButtonText}</ImportBtn>
						)}
					</FooterButtonWrapper>
				</ModalFooterWrapper>
		)
}

export default ModalFooter;