import { FC } from 'react'
import {
	ActionIcon,
	ActionFontStatus,
	ActionFontStatusWrapper,
} from '../../../layouts/rightSideBar/components/actionButton/actionButton.style'
import { getThemeReducer, useAppSelector } from '../../../store'

interface ICustomActionWrapperStatus {
	src?: string
	message: string
	isSuccess?: boolean
	isError?: boolean
}
const CustomActionWrapperStatus: FC<ICustomActionWrapperStatus> = ({
	src,
	message,
	isSuccess,
	isError,
}) => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ActionFontStatusWrapper>
			{src && <ActionIcon src={src} />}
			<ActionFontStatus
				sx={{ themeMode }}
				isSuccess={isSuccess}
				isError={isError}
			>
				{message}
			</ActionFontStatus>
		</ActionFontStatusWrapper>
	)
}

export { CustomActionWrapperStatus }
