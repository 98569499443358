import { useState, useEffect, useMemo, ReactNode } from 'react'
import { Box } from '@mui/material'
import InnerSideNav from '../../layouts/innerSideBar'
import {
	ArchivePageWrapper,
	ArchivePageInnerNav,
	SettingsPageBg,
	MainDiv,
	Heading,
	MainListItem,
	ArchivePageBgMobile,
} from './archive.style'
import { Year } from './components/Year/Year'
import { useArchive, YearAndMonths } from '../../hook/useArchive'
import {
	getCollapseReducer,
	getThemeReducer,
	useAppSelector,
} from '../../store'
import { CardWrapper, CardWrapperMain } from '../account/settings.style'

const ArchivePage = () => {
	const [isSmallScreen, setIsSmallScreen] = useState(false)
	const { yearsAndMonths } = useArchive()
	const { themeMode } = useAppSelector(getThemeReducer)
	const { collapsed } = useAppSelector(getCollapseReducer)

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 600)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const mappedContent = useMemo((): ReactNode => {
		return yearsAndMonths.map(({ year }: YearAndMonths, index: number) => (
			<Year key={year} year={year} index={index} />
		))
	}, [yearsAndMonths])

	return (
		<>
			{!isSmallScreen ? (
				<ArchivePageWrapper>
					<ArchivePageInnerNav>
						<InnerSideNav />
					</ArchivePageInnerNav>
					<SettingsPageBg sx={{ themeMode }} collapsed={collapsed}>
						<CardWrapperMain>
							<CardWrapper sx={{ themeMode }}>
								<MainDiv sx={{ themeMode }}>
									<Heading>Archive</Heading>
									<Box>
										<MainListItem>{mappedContent}</MainListItem>
									</Box>
								</MainDiv>
							</CardWrapper>
						</CardWrapperMain>
					</SettingsPageBg>
				</ArchivePageWrapper>
			) : (
				<ArchivePageBgMobile themeMode={themeMode}>
					<MainListItem>{mappedContent}</MainListItem>
				</ArchivePageBgMobile>
			)}
		</>
	)
}

export default ArchivePage
