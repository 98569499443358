import React, { Fragment, useState } from 'react'
import ModalBox from '../common/modal'
import { ArrowIconModal, ModalBodyWrapper, GuideListItem, GuideItemBox, ItemText } from './allModals.style'
import { Box, Checkbox, Divider, List, ListItem, Typography } from '@mui/material'
import { agencyDummyData } from '../../dummyData'
import UnCheckRight from '../../assets/unCheckRight'
import CheckRight from '../../assets/checkRight'
import { getThemeReducer, useAppSelector } from '../../store'

const GuidesModal = ({ openModal, setOpenModal }: any) => {
	const { themeMode } = useAppSelector(getThemeReducer)
    const [showInnerList,] = useState(false);
    const [activeDoc, setActiveDoc] = React.useState<any>({
        2: true
    });

    const clickHandler = (id: any) => {
        if (activeDoc.hasOwnProperty(id)) {
            setActiveDoc({
                ...activeDoc,
                [id]: !activeDoc[id],
            });
        } else {
            setActiveDoc({ [id]: true })
        }
    }   

    return (
			<>
				<ModalBox
					open={openModal}
					handleClose={() => setOpenModal(!openModal)}
					width='512px'
					crossIcon=''
					headerText='Add Agency/Investor Guides'
					cancelButtonText=''
					actionButtonText=''
				>
					{/* @ts-ignore */}
					<ModalBodyWrapper header={true} footer={true} sx={{ themeMode }}>
						<Box>
							<List>
								{agencyDummyData.map((item: any, id: any) => (
									<Fragment key={id}>
										{/* @ts-ignore */}
										<GuideListItem themeMode={themeMode}>
											<GuideItemBox onClick={() => clickHandler(id)}>
												<ItemText>{item.title}</ItemText>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													{showInnerList ? (
														<ArrowIconModal
															src={`${
																themeMode === 'light'
																	? '/images/svgs/arrowDown-Agency.svg'
																	: '/images/svgs/arrow-dark.svg'
															}`}
														/>
													) : (
														<ArrowIconModal
															src={`${
																themeMode === 'light'
																	? '/images/svgs/arrowDown-Agency.svg'
																	: '/images/svgs/arrow-dark.svg'
															}`}
														/>
													)}
												</Box>
											</GuideItemBox>
											{activeDoc[id] && (
												<List sx={{ width: '100%' }}>
													{item.innerItems.map((item: any, index: number) => (
														<Fragment key={index}>
															<ListItem
																sx={{
																	display: 'flex',
																	justifyContent: 'start',
																}}
															>
																<Checkbox
																	icon={
																		<UnCheckRight
																			color={
																				themeMode === 'light'
																					? '#03363D'
																					: '#FFFFFF'
																			}
																		/>
																	}
																	checkedIcon={<CheckRight />}
																	defaultChecked
																/>
																<Box>
																	<Typography>{item.subtitle}</Typography>
																</Box>
															</ListItem>
															<Divider
																sx={{
																	marginTop: '0px',
																	width: '80%',
																	margin: '0 auto',
																}}
															/>
														</Fragment>
													))}
												</List>
											)}
										</GuideListItem>
									</Fragment>
								))}
							</List>
						</Box>
					</ModalBodyWrapper>
				</ModalBox>
			</>
		)
}

export default GuidesModal