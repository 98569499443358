import { FormWrapper } from '../../components/common/FormWrapper/FormWrapper'
import InnerSideNav from '../../layouts/innerSideBar'
import { MainWrapper } from '../home/home.style'
import {
	CardWrapper,
	CardWrapperMain,
	InnerSideWrapper,
} from '../account/settings.style'
import {
	getCollapseReducer,
	getThemeReducer,
	useAppSelector,
} from '../../store'
import { SettingsPageBg } from '../archive/archive.style'

const BillingPage = () => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const { collapsed } = useAppSelector(getCollapseReducer)

	return (
		<MainWrapper>
			<InnerSideWrapper>
				<InnerSideNav />
			</InnerSideWrapper>
			<SettingsPageBg sx={{ themeMode }} collapsed={collapsed}>
				<CardWrapperMain>
					<CardWrapper sx={{ themeMode }}>
						<FormWrapper title='Billing' formConfigKey='billing' />
					</CardWrapper>
				</CardWrapperMain>
			</SettingsPageBg>
		</MainWrapper>
	)
}

export { BillingPage }
