import { useContext, useEffect, useRef, useState } from 'react'
import { useMessages } from '../../../hook/useMessages'
import {
	MsgBoxWrapper,
} from '../../../pages/home/home.style'
import { useAuth0 } from '@auth0/auth0-react'
import {
	MainMessagesMobileWrapper,
} from './messagesMobile.styles'
import { ActiveListContext, ChatContext } from '../../../context'
import '../Messages/Message/message.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from '../Loader/Loader'
import { useWrapper } from '../../../hook/useWrapper'
import { useModal } from '../../../hook/useModal'
import { useEdit } from '../../../hook/useEdit'
import { ToolTip } from '../../../enum'
import { getThemeReducer, useAppSelector } from '../../../store'
import { MessageMobile } from './MessageMobile'

const MessagesMobile = () => {
	const {
		sortedMessages,
		isMessagesPending,
		shouldUseTypingEffect,
		fetchNextMessagesPage,
		hasMessagesNextPage,
		isMessagesFetchingNextPage
	} = useMessages()
	const { user } = useAuth0()
	const msgBoxWrapper = useRef<HTMLDivElement>()
	const { setMsgWrapperMobile } = useContext(ChatContext)
	const { scrollDown } = useWrapper();
	const {openModalFeedback} = useModal();
	const { handleIsTypingEffectInProgress } = useMessages()
	const {insertLink} = useEdit();
	const {activeEntity} = useContext(ActiveListContext);
	const { themeMode } = useAppSelector(getThemeReducer)
	const [shouldAutoFetch, setShouldAutoFetch] = useState<boolean>(false)

	useEffect(() => {
		if (msgBoxWrapper) {
			if (
				msgBoxWrapper?.current?.scrollHeight ===
					msgBoxWrapper?.current?.clientHeight &&
				hasMessagesNextPage
			) {
				setShouldAutoFetch(true)
				fetchNextMessagesPage()
			} else {
				setShouldAutoFetch(false)
			}
		}
	}, [
		msgBoxWrapper,
		sortedMessages,
		hasMessagesNextPage,
		fetchNextMessagesPage,
	])

	useEffect(() => {
		setMsgWrapperMobile(msgBoxWrapper)
	}, [setMsgWrapperMobile])

	return (
		<MainMessagesMobileWrapper>
			{isMessagesPending ? (
				<Loader />
			) : (
				<MsgBoxWrapper
					sx={{ themeMode }}
					id='msgWrapperMobile'
					ref={msgBoxWrapper}
				>
					{activeEntity?.tooltipTitle === ToolTip.REFRESH && <Loader />}
					<InfiniteScroll
						dataLength={sortedMessages.length}
						next={fetchNextMessagesPage}
						inverse={true}
						loader={<Loader />}
						style={{ display: 'flex', flexDirection: 'column-reverse' }}
						hasMore={hasMessagesNextPage}
						scrollableTarget='msgWrapperMobile'
					>
						{sortedMessages &&
							sortedMessages.map((item, index: number) => (
								<MessageMobile
									key={item.id}
									item={item}
									user={user}
									scrollDown={scrollDown}
									shouldUseTypingEffect={shouldUseTypingEffect(index)}
									handleIsTypingEffectInProgress={
										handleIsTypingEffectInProgress
									}
									openModalFeedback={openModalFeedback}
									insertLink={insertLink}
								/>
							))}
					</InfiniteScroll>
					{shouldAutoFetch && isMessagesFetchingNextPage && <Loader />}
				</MsgBoxWrapper>
			)}
		</MainMessagesMobileWrapper>
	)
}

export { MessagesMobile }
