import { loanConstants, skipStep } from './../constants/loanConstants';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { QueryKey, Url } from '../enum'
import { loanService } from '../services'
import { useLocation, useParams } from 'react-router-dom'
import { useContext, useMemo } from 'react'
import { ActiveListContext, ModalContext } from '../context'
import { CurrentStep, ILoan, ILoanParams } from '../interfaces'

export const useLoans = (params?: ILoanParams) => {
	const { pathname } = useLocation()
	const { LOANS, FHA, FHLMC, FNMA } = Url
	const isPathnameIsLoans = [LOANS, FHA, FNMA, FHLMC].some(
		path => pathname.indexOf(path) !== -1
	)
	const { setStep } = useContext(ModalContext)
	const { loanId } = useParams();
	const {limit} = loanConstants;
	const {setActiveList} = useContext(ActiveListContext); 

	const {
		data: loansData,
		hasNextPage: hasLoansNextPage,
		fetchNextPage: fetchLoansNextPage,
		isFetchingNextPage: isLoansFetchingNextPage,
		isLoading: isLoansLoading,
		isPending: isLoansPending,
		isFetching: isLoansFetching,
		refetch: refetchLoans,
	} = useInfiniteQuery<ILoan[], Error>({
		queryKey: [QueryKey.LOANS, params],
		queryFn: ({ pageParam }) =>
			loanService.getPage({ ...loanConstants, ...params, skip: (pageParam as number) * skipStep}),
		getNextPageParam: (
			lastPage: ILoan[],
			allPages: ILoan[][],
			lastPageParam: unknown
		) => {
			if (lastPage.length === limit) {
				return (lastPageParam as number) + 1
			}
			return null
		},
		initialPageParam: 0,
		enabled: isPathnameIsLoans,
		staleTime: Infinity,
	})

	const loans = useMemo(() => loansData?.pages.flatMap(page => page) || [], [loansData]);

	const uploadNewLoanMutation = useMutation({
		mutationFn: (file: FormData) => loanService.uploadNewLoan(file),
		onSuccess: () => {
			setStep(CurrentStep.STEP3)
			refetchLoans()
		},
		onError: e => {
			setStep(CurrentStep.STEP4)
			console.error(e)
		},
	})

	const updateLoanStatusMutation = useMutation({
		mutationFn: ({
			updatingFields,
			loanIdFromParams,
		}: {
			updatingFields: Partial<ILoan>
			loanIdFromParams?: number
		}) =>
			loanService.updateLoanStatus(loanIdFromParams ?? +loanId, updatingFields),
		onSuccess: () => {
			isPathnameIsLoans && setStep(CurrentStep.STEP4)
			refetchLoans().then(({data}) => {
				if(isPathnameIsLoans) {
					const newLoansArray = data?.pages.flatMap(page => page)
					setActiveList(String(newLoansArray[0].id))
				}
			});
		},
		onError: e => {
			isPathnameIsLoans && setStep(CurrentStep.STEP3)
			console.error(e)
		},
	})

	const updateLoanStatusClickHandler = (
		updatingFields: Partial<ILoan>
	): void => {
		updateLoanStatusMutation.mutate({updatingFields})
		setStep(CurrentStep.STEP2)
	}

	return {
		sortedLoans: loans,
		isLoansLoading,
		refetchLoans,
		isPathnameIsLoans,
		uploadNewLoanMutation,
		updateLoanStatusMutation,
		updateLoanStatusClickHandler,
		isLoansPending,
		isLoansFetching,
		hasLoansNextPage,
		fetchLoansNextPage,
		isLoansFetchingNextPage,
	}
}
