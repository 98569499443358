import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { IStyle } from "../../../interfaces";

export const PowerUpWrapper = styled(Typography)(
	({sx: {themeMode} }: IStyle) => ({
		position: 'relative',
		bottom: '2px',
		display: 'inline',
		width: '60px',
    padding: '2px 5px',
    textAlign: 'center',
		borderRadius: '15px',
		background: `${themeMode === 'light' ? '#d9e9e7' : '#5D686D' }`,
		color: `${themeMode === 'light' ? '#248E9C' : '#FFFFFF' }`,
		fontSize: '10px',
		whiteSpace: 'nowrap'
	})
)


