export const settingsDummyData = {
	settings: [
		{
			title: 'Account',
			url: '/account',
		},
		{
			title: 'Company',
			url: '/company',
		},
		{
			title: 'Billing',
			url: '/billing',
		},
		{
			title: 'Integrations',
			url: '/integration',
		},
		{
			title: 'Notifications',
			url: '/notification',
		},
		{
			title: 'Archive',
			url: '/archive',
		},
		{
			title: 'Logout',
			url: '#',
		},
	],
}

export const agencyDummyData = [
    {
        title: "Fannie Mae",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: "Freddie Mac",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: "VA",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: "Chase Correspondent",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: "Wells Fargo Corresponden",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: "Flagstart Correspondent",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
    {
        title: " Truist Correspondent",
        innerItems: [{
           subtitle: "2023 Selling Guide",
           checked: true
        },
        {
            subtitle: "2022 Selling Guide",
            checked: true
         },
         {
            subtitle: "2021 Selling Guide",
            checked: true
         }],
        
    },
]
