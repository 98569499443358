import { ReactNode, useContext, useEffect } from 'react'
import { IDetails, IDocument, ILoan, ISetting, ITooltip, ModalKey } from '../interfaces'
import { CustomToolTip } from '../components/common/ToolTip/ToolTip'
import { ToolTip, DetailsType } from '../enum'
import { ActiveListContext, ModalContext } from '../context'
import { useLoans } from './useLoans'
import { useMessages } from './useMessages'
import { useDocuments } from './useDocuments'
import { useSuggestions } from './useSuggestions'
import { documentService } from '../services'
import { useParams } from 'react-router-dom'
import { setFileExtension } from '../utils/fileHandlers'
import { getThemeReducer, useAppSelector } from '../store'

export const useTooltip = () => {
	const { openModal, setDocumentId } = useContext(ModalContext)
	const { refetchLoans, isLoansFetching } = useLoans();
	const { refetchMessages, isMessagesFetching } =
		useMessages()
	const { refetchDocuments, isDocumentsFetching, sortedDocuments } =
		useDocuments()
	const {refetchSuggestions} = useSuggestions();
	const {loanId} = useParams();
	const {setActiveEntity, activeEntity} = useContext(ActiveListContext);
	const {isSuggestionFetching} = useSuggestions();
	const {themeMode} = useAppSelector(getThemeReducer);

	const isEntitiesRefreshing = isLoansFetching || isMessagesFetching || isDocumentsFetching || isSuggestionFetching

		const compareEntitiesKeys = (
			obj1: Record<string, any>,
			obj2: Record<string, any>
		): boolean => {
			let isTheSame = true
			let i = 0
			while (i < Object.keys(obj1).length) {
				if (Object.keys(obj1)[i] === Object.keys(obj2)[i]) {
					i++
				} else {
					isTheSame = false
					break
				}
			}

			return isTheSame
		}

	const viewTooltipHandler = async (entity: IDocument | ILoan | ISetting): Promise<void> => {
		if (compareEntitiesKeys(entity, Object.values(sortedDocuments)[0][0])) {
			const doc = entity as IDocument
			setActiveEntity({ entity, tooltipTitle: ToolTip.VIEW })
			
			try{
				const res = await documentService.docStream({
				loanId: +loanId,
				document_id: doc.id,
			})
				const contentType = res.headers['content-type']
				const fileType =
					contentType === 'application/octet-stream'
						? 'application/pdf'
						: contentType
				const blob = new Blob([res.data], { type: fileType })
				let url = URL.createObjectURL(blob)
				
				if (contentType === 'application/pdf') {
					localStorage.setItem('documentUrl', url)
					localStorage.setItem('documentName', doc.name)
					localStorage.setItem('themeMode', themeMode);
					window.open(
						`${window.location.origin}/loans/${loanId}/documents/${doc.id}`,
						'_blank'
					)
				} else {
					const a = document.createElement('a')
					a.style.display = 'none'
					document.body.append(a)
					a.href = url
					const fileExt = setFileExtension(contentType)
					a.download = doc.name + fileExt
					a.click()
					URL.revokeObjectURL(url)
					document.body.removeChild(a)
			}
			} catch(e) {
				console.error('Error during downloading file', e);
			} finally {
				setActiveEntity(null);
			}
		}
	}

	const refreshHandler = (entity: any): void => {
		if (!isEntitiesRefreshing) {
			setActiveEntity({ entity, tooltipTitle: ToolTip.REFRESH })
			refetchLoans()
			refetchDocuments()
			refetchMessages()
			refetchSuggestions()
		}
	}

	useEffect(() => {
		if (!isEntitiesRefreshing && activeEntity?.tooltipTitle === ToolTip.REFRESH) {
			setActiveEntity(null)
		}
	}, [isEntitiesRefreshing, activeEntity, setActiveEntity])

	const openModalBySettingModaLKeyAndSetDocId = (
		modalKey: ModalKey,
		entity?: IDocument | ILoan | ISetting
	): void => {
		openModal(modalKey)
		if (
			sortedDocuments &&
			Array.isArray(sortedDocuments[0]) &&
			compareEntitiesKeys(entity, Object.values(sortedDocuments)[0][0])
		) {
			setDocumentId((entity as IDocument).id)
		}
	}

	const tooltipHandlers: Record<string, Function | null> = {
		[ToolTip.FRAUD_CHECK]: null,
		[ToolTip.SUMMARY]: null,
		[ToolTip.VIEW]: (entity: IDocument | ILoan | ISetting) =>
			viewTooltipHandler(entity),
		[ToolTip.DELETE]: (entity: IDocument | ILoan | ISetting) =>
			openModalBySettingModaLKeyAndSetDocId('delete', entity),
		[ToolTip.ARCHIVE]: (entity: IDocument | ILoan | ISetting) =>
			openModalBySettingModaLKeyAndSetDocId('archived', entity),
		[ToolTip.REFRESH]: (entity: any) => refreshHandler(entity),
	}

	const attachHandlers = (
		title: ToolTip | string,
		entity: IDocument | ILoan | ISetting
	): (() => void) => {
		return tooltipHandlers[title]
			? () => tooltipHandlers[title](entity)
			: undefined
	}

	const parseDocumentTooltips = (
		tooltips: ITooltip[],
		document: IDocument
	): ReactNode[] => {
		return tooltips.map((tooltip: ITooltip, index: number) => {
			const { title } = tooltip
			if (!title) {
				tooltip = { ...tooltip, title: `**Summary**\n\n${document.summary}` }
			}
			return (
				<CustomToolTip
					key={index}
					tooltip={tooltip}
					marginLeft='0px'
					onClick={attachHandlers(tooltip.title, document)}
					isScanned={document.scanned}
					id={document.id}
				/>
			)
		})
	}

	const parseDetailsTooltip = (details: string): string => {
		const { title, items, type }: IDetails = JSON.parse(details)

		switch(type as DetailsType) {
			case DetailsType.LIST: {
				return `**${title}**\n\n${items
					.map(item => `* ${item}`)
					.join('\n')}`
			}
			default: {
				return null;
			}
		}
	}

	return {
		parseDocumentTooltips,
		tooltipHandlers,
		attachHandlers,
		parseDetailsTooltip,
	}
}
