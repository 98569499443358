import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {FC} from 'react';
import { FeedbackIcon } from '../modal/header/modal.header.style';
import { FeedbackIconWrapperComponent } from '../../../pages/home/home.style';
import { getThemeReducer, useAppSelector } from '../../../store';

interface IFeedbackIconWrapperProps {
  columnNumber: number;
  src: string;
  onClickHandler: () => void;
}

const FeedbackIconWrapper: FC<IFeedbackIconWrapperProps> = ({
	columnNumber,
	src,
	onClickHandler,
}) => {
	const { themeMode } = useAppSelector(getThemeReducer)
	
	return (
		<Grid2 xs={columnNumber}>
			<FeedbackIconWrapperComponent sx={{themeMode}}>
				<FeedbackIcon
					src={src}
					onClick={onClickHandler}
				/>
			</FeedbackIconWrapperComponent>
		</Grid2>
	)
}

export { FeedbackIconWrapper };