import {FC} from 'react';
import { BillingListItemIcon, BillingListItemText, BillingModalListItem } from './Billing.style';
import { getThemeReducer, useAppSelector } from '../../../store';

interface IBillingListItemProps {
  listItem: string;
}

const BillingListItem: FC<IBillingListItemProps> = ({listItem}) => {
  const {themeMode} = useAppSelector(getThemeReducer);

  return (
    <BillingModalListItem>
      <BillingListItemIcon src={`/images/svgs/check-${themeMode}.svg`} />
      <BillingListItemText>{listItem}</BillingListItemText>
    </BillingModalListItem>
  );
};

export { BillingListItem };