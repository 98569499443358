import { getThemeReducer, useAppSelector } from "../../../store"
import { DeleteModalBody, DeleteModalHeading, DeleteModalSubHeading, ModalBodyWrapper, SubheadingInner, SubheadingWrapper, TrashIconModal, TrashIconModalWrapper } from "../allModals.style"

const RefreshModal = () => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
      <ModalBodyWrapper sx={{ themeMode }}>
        <TrashIconModalWrapper sx={{themeMode}}>
          <TrashIconModal
            alt='Modal Trash'
            src='/images/svgs/trash-modal.svg'
          />
        </TrashIconModalWrapper>
        <DeleteModalBody>
          <DeleteModalHeading>
            Sure you want to archive this item?
          </DeleteModalHeading>
        </DeleteModalBody>
        <SubheadingWrapper>
          <SubheadingInner>
            <DeleteModalSubHeading>
              This action will move the item to the archive, and it can be
              retrieved later if needed.
            </DeleteModalSubHeading>
          </SubheadingInner>
        </SubheadingWrapper>
      </ModalBodyWrapper>
	)
}

export {RefreshModal}
