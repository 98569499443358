import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppRootState } from "../store";

interface InitialState {
  showMessages: boolean;
  isMobile: boolean;
}

const initialState: InitialState = {
  showMessages: false,
  isMobile: false,
}

const mobileSlice = createSlice({
	name: 'mobileSlice',
	initialState,
	reducers: {
		setShowMessages: (state: InitialState, action: PayloadAction<boolean>) => {
			state.showMessages = action.payload;
		},
    toggleShowMessages: (state: InitialState) => {
      state.showMessages = !state.showMessages;
    },
		setIsMobile: (state: InitialState, action: PayloadAction<boolean>) => {
			state.isMobile = action.payload;
		},
    toggleIsMobile: (state: InitialState) => {
      state.isMobile = !state.isMobile;
    }
	},
})

const getMobileReducer = (state: AppRootState) => state.mobileReducer;
const {reducer: mobileReducer, actions: mobileActions} = mobileSlice;

export { mobileActions, mobileReducer, getMobileReducer }