import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import {
	BackArrow,
	BackArrowWrapper,
	MobileHeader,
} from '../../../pages/home/home.style'
import { memo } from 'react'
import {
	HeaderIconWrapper,
	HeaderRightIcon,
} from '../../../layouts/header/header.style'
import { useLoans } from '../../../hook/useLoans'
import { MobileHeaderLoanInfo } from './MobileHeaderLoanInfo/MobileHeaderLoanInfo'
import { Loader } from '../Loader/Loader'
import { drawerActions, getThemeReducer, mobileActions, useAppDispatch, useAppSelector } from '../../../store'

const CustomMobileHeader = memo(
	() => {
		const { isLoansLoading } = useLoans()
		const dispatch = useAppDispatch()
		const { toggleIsRightDrawerOpen: toggleIsDrawerOpen } = drawerActions
		const {toggleShowMessages} = mobileActions;
		const { themeMode } = useAppSelector(getThemeReducer)

		return (
			<MobileHeader>
				<Grid2 container columns={12}>
					<Grid2 xs={1.6}>
						<BackArrowWrapper>
							<BackArrow
								src={`${
									themeMode === 'light'
										? '/images/svgs/backArrow.svg'
										: '/images/svgs/backArrow-dark.svg'
								}`}
								onClick={() => dispatch(toggleShowMessages())}
							/>
						</BackArrowWrapper>
					</Grid2>
					<Grid2 xs={8}>
						{isLoansLoading ? <Loader /> : <MobileHeaderLoanInfo />}
					</Grid2>
					<Grid2 xs={2.4}>
						<HeaderIconWrapper themeMode={themeMode}>
							<HeaderRightIcon
								src={`${
									themeMode === 'light'
										? '/images/svgs/rightNavIcon.svg'
										: '/images/svgs/rightNavIcon-dark.svg'
								}`}
								themeMode={themeMode}
								onClick={() => dispatch(toggleIsDrawerOpen())}
							/>
						</HeaderIconWrapper>
					</Grid2>
				</Grid2>
			</MobileHeader>
		)
	}
)

export { CustomMobileHeader }
