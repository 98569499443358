import { SubmitHandler, useForm } from 'react-hook-form'
import { CurrentStep, IModalWithProps, IUser } from '../../../interfaces'
import { useUsers } from '../../../hook/useUsers'
import { Button, FormGroup } from '@mui/material'
import { PowerUpFormItem } from './PowerUpFormItem/PowerUpFormItem'
import { ModalBodyWrapper } from '../allModals.style'
import { FC, useContext, useEffect } from 'react'
import { ModalContext } from '../../../context'
import { powerUpFormConfig } from '../../../pages/account/formConfigs'
import { OptionalEntity } from '../../../hook/useConfig'
import { useFormMethods } from '../../../hook/useFormMethods'
import { getThemeReducer, useAppSelector } from '../../../store'

const PowerUpModal: FC<IModalWithProps> = ({ setIsDisabled }) => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const { submitRef, setStep } = useContext(ModalContext)
	const { user, updateUserHandler } = useUsers()
	const config = powerUpFormConfig(user)
	const { getDirtyKeys, defaultValues } = useFormMethods({
		entity: user,
		config,
	})

	const {
		control,
		handleSubmit,
		formState: { isDirty, dirtyFields },
	} = useForm<Partial<OptionalEntity>>({
		defaultValues,
	})

	const onSubmit: SubmitHandler<Partial<OptionalEntity>> = (data): void => {
		const formResult = getDirtyKeys(data, dirtyFields) as Partial<IUser>
		updateUserHandler(formResult)
		setStep(CurrentStep.STEP2)
	}

	useEffect(() => {
		setIsDisabled(!isDirty)
	}, [isDirty])

	return (
		<ModalBodyWrapper sx={{ themeMode }} header={true} paddingBlock={'30px 0'}>
			<form
				style={{ width: '85%', margin: '0 auto' }}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormGroup>
					{config.map(item => (
						<PowerUpFormItem control={control} item={item} />
					))}
				</FormGroup>
				<Button type='submit' ref={submitRef} sx={{ display: 'none' }} />
			</form>
		</ModalBodyWrapper>
	)
}

export { PowerUpModal }
