import {FC} from 'react';
import { ListItemIcon } from '@mui/material';
import { ArrowIcon } from '../../../account/settings.style';
import { ListItemTitle } from '../../archive.style';
import { getThemeReducer, useAppSelector } from '../../../../store';

interface IListItemIconComponentProps {
  year: number;
  isActiveDate: boolean;
}

const ListItemIconComponent: FC<IListItemIconComponentProps> = ({year, isActiveDate}) => {
	const { themeMode } = useAppSelector(getThemeReducer)

  return (
		<>
			<ListItemIcon>
				<ArrowIcon
					src={`/images/svgs/arrow-right-${themeMode}.svg`}
					sx={{rotate: isActiveDate && 'rotate(90deg)'}}
					width='24'
					height='24'
				/>
			</ListItemIcon>
			<ListItemTitle variant='body1'>{year}</ListItemTitle>
		</>
	)
};

export { ListItemIconComponent };