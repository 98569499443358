import { AxiosError } from "axios";
import { urls } from "../constants";
import { IBillingAccount } from "../interfaces";
import { apiService } from "./api.service";

export const billingService = {
  getInfo: async (signal: AbortSignal) => {
    try {
      const {data} = await apiService.get<IBillingAccount>(urls.billingAccount, {signal});
      return data;
    } catch(e) {
      const err = e as AxiosError;
      if(err.response.status === 404) {
        return e;
      }
      console.error(e);
    }
  },
  updateBillingInfo: async (updatedFields: Partial<IBillingAccount>) => {
    const {data} = await apiService.put<IBillingAccount>(urls.billingAccount, updatedFields);
    return data;
  }
}