import { useContext } from 'react'
import {
	ModalBodyWrapper,
	UploadWrapper,
	UploadIcon,
	ModalSubText,
	BodyTextStep1,
	BodySubTextError,
} from '../../../allModals.style'
import { useModal } from '../../../../../hook/useModal'
import { ModalContext } from '../../../../../context'
import { HiddenInput } from '../../input/HiddenInput'
import { getThemeReducer, useAppSelector } from '../../../../../store'

const Step1 = () => {
	const {
		errorMessage,
		isDragOver,
		handleDrop,
		dragLeaveHandler,
		dragOverHandler,
	} = useModal()
	const { selectedFile } = useContext(ModalContext)
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ModalBodyWrapper
			paddingBlock={'20px 0'}
			onDragLeave={dragLeaveHandler}
			onDrop={handleDrop}
			isDragOver={isDragOver}
			header={true}
			sx={{ themeMode }}
			onDragOver={dragOverHandler}
		>
			<UploadWrapper>
				<UploadIcon src='/images/svgs/upload.svg' />
			</UploadWrapper>
			{!selectedFile ? (
				<>
					<BodyTextStep1>Drop your PDF File here</BodyTextStep1>
					<ModalSubText>Or</ModalSubText>
				</>
			) : (
				<ModalSubText>{selectedFile.name}</ModalSubText>
			)}
			{errorMessage && <BodySubTextError>{errorMessage}</BodySubTextError>}
			<HiddenInput type='file' accept='application/pdf' />
		</ModalBodyWrapper>
	)
}
export { Step1 }
