import {
	Drawer,
	Box,
	Typography,
	Popover,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import {
	SideNavComponent,
	DrawerMenuIcon,
	SuperAdmin,
} from './sidebar.style'
import {
	OuterBox,
	UsernameBox,
	Username,
	ArrowIconWrapper,
	DownArrowIcon,
	HeaderRightIcon,
} from '../header/header.style'
import { useAuth0 } from '@auth0/auth0-react'
import { useLogout } from '../../hook/useLogout'
import { useUsers } from '../../hook/useUsers'
import { useHeader } from '../../hook/useHeader'
import { navItems } from './navItems'
import { NavItemMobile } from './NavItemMobile'
import { drawerActions, getDrawerReducer, getThemeReducer, useAppDispatch, useAppSelector } from '../../store'

const SideBarMobile = () => {
	const { isAuthenticated } = useAuth0()
	const { user, isUserPending } = useUsers()
	const {
		toggleDropDown,
		handleClose,
		usernameBoxRef,
		anchorEl,
		navigateToAndCloseDropDown,
	} = useHeader()
	const { logoutHandler } = useLogout()
	const {isMobileDrawerOpen} = useAppSelector(getDrawerReducer)
	const {themeMode} = useAppSelector(getThemeReducer)
	const dispatch = useAppDispatch();
	const {toggleIsMobileDrawerOpen} = drawerActions;

	return (
		<Drawer
			variant='temporary'
			anchor='left'
			open={isMobileDrawerOpen}
			onClose={() => dispatch(toggleIsMobileDrawerOpen())}
			sx={{ width: 355 }}
		>
			<SideNavComponent>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '15px 24px',
						height: 'calc(100% -15px)',
					}}
				>
					<Box>
						<Typography
							sx={{
								fontSize: '24px',
								fontWeight: 500,
								lineHeight: '33.6px',
								fontFamily: 'Roboto Slab',
							}}
						>
							Menu
						</Typography>
					</Box>
					<Box>
						<DrawerMenuIcon
							src={`${
								themeMode === 'light'
									? '/images/svgs/drawer-menu.svg'
									: '/images/svgs/drawer-menu-dark.svg'
							}`}
							onClick={() => dispatch(toggleIsMobileDrawerOpen())}
						/>
					</Box>
				</Box>
				{!isUserPending && (
					<OuterBox>
						<UsernameBox ref={usernameBoxRef} onClick={toggleDropDown}>
							<Username>
								{user.first_name} {user.last_name}
							</Username>
							<SuperAdmin>{user.company_display}</SuperAdmin>
						</UsernameBox>
						<ArrowIconWrapper onClick={toggleDropDown}>
							<DownArrowIcon
								active={!!anchorEl}
								src={`${
									themeMode === 'light'
										? '/images/svgs/DownArrow.svg'
										: '/images/svgs/DownArrow-dark.svg'
								}`}
							/>
						</ArrowIconWrapper>
					</OuterBox>
				)}
				{navItems &&
					navItems
						.slice(0, -1)
						.map(
							(item, index) =>
								item.label !== 'Menu' && (
									<NavItemMobile
										key={index}
										label={item?.label || item?.labelObj[themeMode]}
										src={item.src[themeMode]}
										link={item?.link}
										needDivider={item.needDivider}
									/>
								)
						)}
			</SideNavComponent>
			<Popover
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<List>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => navigateToAndCloseDropDown('settings')}
						>
							<ListItemIcon>
								<HeaderRightIcon
									src={`${
										themeMode === 'light'
											? '/images/svgs/edit.svg'
											: '/images/svgs/edit-dark.svg'
									}`}
									themeMode={themeMode}
								/>
							</ListItemIcon>
							<ListItemText
								primary='Edit Profile'
								sx={{
									'& .MuiTypography-root': {
										fontFamily: 'Bai Jamjuree',
										fontWeight: 500,
										fontSize: '16px',
									},
								}}
							/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<HeaderRightIcon
									src={`${
										themeMode === 'light'
											? '/images/svgs/logout.svg'
											: '/images/svgs/logout-dark.svg'
									}`}
									themeMode={themeMode}
								/>
							</ListItemIcon>
							{isAuthenticated && (
								<ListItemText
									primary='Log Out'
									sx={{
										'& .MuiTypography-root': {
											fontFamily: 'Bai Jamjuree',
											fontWeight: 500,
											fontSize: '16px',
										},
									}}
									onClick={logoutHandler}
								/>
							)}
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
		</Drawer>
	)
}

export default SideBarMobile
