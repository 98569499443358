import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { IStyle } from '../../../interfaces'

export const ActionWrapper = styled(Box)(({ theme, sx: { themeMode } }: IStyle) => ({
	marginInline: '75px 95px',
	fontFamily: 'DM Sans',
	color: `${themeMode === 'light' ? '#03363D' : '#EFF3F4'}`,
	fontStyle: 'italic',
	lineHeight: '16.8px',
	fontSize: '14px',
	[theme.breakpoints.down('lg')]: {
		fontSize: '12px',
	},
	[theme.breakpoints.down('md')]: {
		fontSize: '10px',
		marginInline: '75px 25px',
	},
}))

export const ActionText = styled(Typography)(() => ({
	color: 'inherit',
	textDecoration: 'underline',
	cursor: 'pointer',
	fontSize: 'inherit',
	fontFamily: 'inherit',
	fontStyle: 'inherit',
	display: 'inline',
}))

export const InfinitiveScrollWrapper = styled(Box)(() => ({
	flex: '1 1 100%',
}))
