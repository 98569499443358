import { Box, ListItem, Typography, List, ListItemText } from '@mui/material'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/system'
import { IStyle } from '../../interfaces'

export const ArchivePageWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
}))

export const ArchivePageInnerNav = styled(Box)(({ theme }) => ({
	minWidth: 331,
	[theme.breakpoints.down('lg')]: {
		minWidth: 178,
	},
	[theme.breakpoints.down('md')]: {
		minWidth: 150,
	},
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}))

export const SettingsPageBg = styled(Box)<any>(
	({ theme, sx: { themeMode }, collapsed }: IStyle) => ({
		paddingTop: '30px',
		minWidth: `${collapsed ? 'calc(100vw - 380px)' : 'calc(100vw - 492px)'}`,
		flex: '1 1 auto',
		overflowY: 'hidden',
		backgroundImage:
			themeMode === 'light'
				? `url(${'/images/svgs/archive-page-bg.svg'})`
				: `url(${'/images/svgs/Archive-bg-dark.svg'})`,
		[theme.breakpoints.down('lg')]: {
			minWidth: `${'calc(100vw - 395px)'}`,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '100vw',
		},
	})
)

export const ArchivePageBgMobile = styled(Box)<any>(
	({ theme, themeMode }: any) => ({
		minWidth: 'calc(100vw - 492px)',
		height: 'calc(100vh - 100px)',
		overflowY: 'hidden',
		background: theme.palette.background.default,
		[theme.breakpoints.down('lg')]: {
			minWidth: 'calc(100vw - 320px)',
		},
		[theme.breakpoints.down('md')]: {
			minWidth: 'calc(100vw - 200px)',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 'calc(100vw - 0px)',
			overflowY: 'auto',
			height: 'calc(100vh - 84px)',
		},
	})
)

export const ListItemWrapper: any = styled(ListItem)(
	({ theme, sx: { themeMode } }: IStyle) => ({
		background: `${
			themeMode === 'light' ? theme.palette.background.paper : '#465359'
		}`,
		borderRadius: '16px',
		marginBottom: '16px',
		minHeight: '68px',
		alignItems: 'start',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			margin: '16px',
			minHeight: '60px',
			borderRadius: '10px',
		},
	})
)

export const MainDiv = styled(Box)(({ theme, sx: { themeMode } }: IStyle) => ({
	padding: '30px 28px',
}))

export const Heading = styled(Box)(({ theme }) => ({
	fontSize: '36px',
	fontWeight: 600,
	lineHeight: '50.4px',
	color: theme.palette.text.primary,
}))
export const ListItemTextStyle = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 600,
	lineHeight: '22.4px',
}))

export const MainListItem = styled(List)(({ theme }) => ({
	marginTop: '24px',
	overflowY: 'auto',
	fontFamily: 'Bai Jamjuree',
}))

export const ListText = styled(ListItemText)(({ theme }) => ({
	fontSize: '20px',
	fontWeight: 600,
	lineHeight: '28px',
}))

export const ListItemMonths = styled(ListItem)(({ theme }) => ({
	cursor: 'pointer',
	fontSize: '16px',
	fontWeight: 600,
	lineHeight: '22.4',
	display: 'flex',
	justifyContent: 'flex-start',
}))

export const ListItemTitle = styled(Typography)(({ theme }) => ({
	fontSize: '20px',
	lineHeight: '22.4px',
	fontWeight: 600,
}))

export const MonthListWrapper = styled(List)(({ theme }) => ({
	marginLeft: '64px',
	boxSizing: 'border-box',
}))
export const MonthOfYearWrapper = styled(List)(({ theme }) => ({
	marginLeft: '12px',
	paddingTop: '0',
	maxHeight: '200px',
	overflowY: 'auto',
}))

export const MonthOfYearDivider = styled(Divider)<any>(
	({ theme, sx: { themeMode } }: IStyle) => ({
		background:
			themeMode === 'light'
				? theme.palette.primary.dark
				: theme.palette.text.primary,
	})
)

export const ListId = styled(ListItemText)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	lineHeight: '19.6px',
}))

export const ReloadIcon = styled(ListItemText)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	lineHeight: '19.6px',
}))

export const EndMessage = styled(Typography)(
	({ theme, sx: { themeMode } }: IStyle) => ({
		color:
			themeMode === 'light'
				? theme.palette.primary.dark
				: theme.palette.text.primary,
		marginLeft: '16px',
		fontWeight: '400',
		fontFamily: 'inherit',
	})
)
