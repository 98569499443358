import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormField } from '../FormField/FormField'
import { Box } from '@mui/material'
import { TextFieldTitle } from '../../../../pages/account/settings.style'
import { FormConfigData } from '../../../../pages/account/formConfigs'
import { OptionalEntity } from '../../../../hook/useConfig'

interface IFormFieldWrapperProps {
	control: Control<Partial<OptionalEntity>>
	inputData: FormConfigData
}

const FormFieldWrapper: FC<IFormFieldWrapperProps> = ({
	control,
	inputData,
}) => {
	const { label, inputName, currentValue } = inputData

	return (
		<Box sx={{ marginBottom: '20px' }}>
			<TextFieldTitle>{label}: </TextFieldTitle>
			<Controller
				name={inputName}
				control={control}
				defaultValue={currentValue}
				render={({ field }) => (
					<FormField
						field={field}
						inputData={inputData}
						type={inputData.type}
					/>
				)}
			/>
		</Box>
	)
}

export { FormFieldWrapper }
