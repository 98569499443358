import { Box, Avatar, Typography, Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import FormGroup from '@mui/material/FormGroup'
import { IStyle } from '../../interfaces'

export const MainWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	[theme.breakpoints.down('sm')]: {
		display: 'block',
	},
}))

export const InnerSideBarWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('lg')]: {
		height: 'calc(100vh - 80px)',
	},
}))

export const HomePageBg = styled(Box)<any>(({ theme, themeMode, collapsed }: any) => ({
	height: 'calc(100vh - 120px)',
	display: 'flex',
	flexDirection: 'column',
	overflowX: 'hidden',
	minWidth: `${collapsed ? 'calc(100vw - 780px)' : 'calc(100vw - 912px)'}`,
	flex: '1 1 auto',
	padding: '20px 0px 0px 0px',
	backgroundImage:
		themeMode === 'light'
			? `url(${'/images/svgs/home-page-bg.svg'})`
			: `url(${'/images/svgs/Homebg-dark.svg'})`,
	backgroundRepeat: 'repeat',
	backgroundAttachment: 'fixed',
	backgroundPosition: '-60px 0',
	[theme.breakpoints.down('lg')]: {
		height: 'calc(100vh - 100px)',
	},
	[theme.breakpoints.down('sm')]: {
		padding: '0px',
		height: 'calc(100vh - 120px)',
	},
}))

export const HomePageRightNav = styled(Box)<any>(({ theme }: any) => ({
	minWidth: 350,
	[theme.breakpoints.down('lg1400')]: {
		display: 'none',
	},
}))

export const LikeIcon = styled('img')(({ theme }) => ({
	height: '18.67px',
	width: '18.67px',
	cursor: 'pointer',
}))

export const DisLikeIcon = styled('img')(({ theme }) => ({
	height: '18.67px',
	width: '18.67px',
	cursor: 'pointer',
}))

export const EditIcon = styled('img')(({ theme }) => ({
	height: '14.39px',
	width: '14.39px',
}))

export const AvatarIcon = styled(Avatar)(({ theme }) => ({
	height: '40px',
	width: '40px',
	objectFit: 'none',
	background: theme.palette.primary.light,
	[theme.breakpoints.down('sm')]: {
		height: '24px',
		width: '24px',
	},
}))

export const MsgWrapper = styled(Box)(({ theme }) => ({
	width: 'calc(100% - 76px)',
	padding: '8px 0px',
	margin: '0 auto',
	display: 'flex',
	[theme.breakpoints.down('sm')]: {
		width: 'calc(100% - 10px)',
		padding: '0px',
	},
}))

export const MessagesWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '20px',
	padding: '20px 0',
}))

export const AvatarWrapper = styled(Box)(({ theme }) => ({
	width: '40px',
	[theme.breakpoints.down('sm')]: {
		width: '24px',
	},
}))

export const AvatarMain = styled(Box)(({ theme }) => ({
	margin: '0 auto',
}))

export const QuestionWrapper = styled(Box)(({ theme }) => ({
	width: 'calc(100% - 140px)',
	[theme.breakpoints.down('md')]: {
		width: 'calc(100% - 70px)',
	},
	[theme.breakpoints.down('sm')]: {
		marginRight: '0px',
		minWidth: 'calc(100% - 40px)',
	},
}))

export const QuestionInnerWrapper = styled(Box)(
	({ theme, sx: { themeMode } }: IStyle) => ({
		minHeight: '46.8px',
		width: 'calc(100% - 30px)',
		background: `${
			themeMode === 'light' ? theme.palette.background.paper : '#2E3D43'
		}`,
		padding: '0px 16px',
		borderRadius: '10px',
		[theme.breakpoints.down('lg')]: {
			minHeight: '43.2px',
		}
	})
)

export const QuestionBody = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	fontFamily: 'Bai Jamjuree',
	lineHeight: '28.8px',
	display: 'flex',
	alignItems: 'center',
	[theme.breakpoints.down('lg')]: {
		fontSize: '14px',
		lineHeight: '25.2px',
	},
	[theme.breakpoints.down('md')]: {
		fontSize: '12px',
	},
	[theme.breakpoints.down('sm')]: {
		fontSize: '14px',
	},
}))

export const AnswerWrapper = styled(Box)(({ theme }) => ({
	width: '70px',
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}))

export const AnswerInnerWrapper = styled(Box)(({ theme }) => ({
	paddingTop: '5px',
}))

export const LikeIconWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '28px',
	width: '28px',
	background: theme.palette.background.paper,
	borderRadius: '6px',
	marginRight: '15px',
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}))

export const FeedbackIconWrapperComponent = styled(Box)(
	({
		theme,
		sx: { themeMode, width = '28px', height = '28px', borderRadius = '6px' },
	}: IStyle) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height,
		width,
		background: `${
			themeMode === 'light' ? theme.palette.background.paper : '#49565D'
		}`,
		borderRadius,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	})
)

export const ModalBodyWrapper = styled(Box)(({ theme }) => ({
	padding: '24px 28px',
	background: theme.palette.background.paper,
}))

export const BodyText = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 600,
	lineHeight: '25.2px',
}))

export const BodySubText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 400,
	lineHeight: '22.4px',
	marginTop: '16px',
}))

export const MsgBoxWrapper = styled(Box)(({ theme, sx: { themeMode } }: IStyle) => ({
	height: '100%',
	overflowY: 'auto',
	paddingInline: '10px',
	margin: '0',
	display: 'flex',
	flexDirection: 'column-reverse',
	position: 'relative',
	width: 'calc(100% - 63px)',
	[theme.breakpoints.down('lg1600')]: {
		margin: '0 auto',
		width: 'calc(100% - 20px)'
	},
}))

export const PaperWrapper = styled(Paper)(({ theme, sx: { themeMode } }: IStyle) => ({
	flexShrink: 1,
	width: 'calc(100% - 80px)',
	display: 'flex',
	alignItems: 'center',
	borderRadius: '16px',
	boxShadow: 'none',
	paddingLeft: '8px',
	background: `${themeMode === 'light' ? '#FFFFFF' : '#37454c'}`,
	marginTop: '10px',
	[theme.breakpoints.down('sm')]: {
		margin: '10px auto 0',
		width: 'calc(100% - 40px)',
	},
}))

export const IconButtonWrapper = styled(IconButton)<any>(
	({ theme, themeMode }: any) => ({
		backgroundColor: '#F7F6F4',
		height: '36px',
		width: '36px',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#F7F6F4',
		},
		[theme.breakpoints.down('sm')]: {
			height: '28px',
			width: '28px',
		},
	})
)

export const StopAudio = styled(Box)(() => ({
	height: '20px',
	width: '20px',
	background: 'rgb(254, 106, 87)'
}))

export const InputBaseWrapper = styled(TextareaAutosize)(({ theme }) => ({
	fontFamily: 'Bai Jamjuree',
	marginLeft: '15px',
	flex: 1,
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '28.8px',
	border: 'none',
	outline: 'none',
	background: 'transparent',
	color: theme.palette.text.primary,
	resize: 'vertical',
	[theme.breakpoints.down('lg')]: {
		fontSize: '14px',
		'&::placeholder': {
			fontSize: '14px',
		},
	},
	[theme.breakpoints.down('md')]: {
		fontSize: '12px',
		'&::placeholder': {
			fontSize: '12px',
		},
	},
	[theme.breakpoints.down('sm')]: {
		fontSize: '14px',
		'&::placeholder': {
			fontSize: '14px',
		},
	},
}))

export const VersionBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	margin: '10px 0',
	[theme.breakpoints.down('md')]: {
		margin: '10px 0 0'
	},
	[theme.breakpoints.down('sm')]: {
		margin: '10px 20px',
	},
}))

export const VersionText = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontSize: '14px',
	fontWeight: 400,
	lineHeight: '18.8px',
	color: theme.palette.text.primary,
	opacity: 0.6,
	margin: '4px auto 14px',
	[theme.breakpoints.down('lg1660')]: {
		fontSize: '12px',
	},
	[theme.breakpoints.down('lg')]: {
		fontSize: '10px',
	},
	[theme.breakpoints.down('sm')]: {
		textAlign: 'center',
		fontSize: '10px'
	},
}))

export const MsgDownArrowBox = styled(Box)(
	({ theme, sx: { themeMode } }: IStyle) => ({
		height: '40px',
		width: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#465359',
		borderRadius: '50%',
	})
)

export const SendBtn = styled(Button)(
	({ theme, sx: { themeMode }, is_sending_message_pending }: IStyle) => ({
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.text.white,
		fontSize: '16px',
		fontWeight: 500,
		lineHeight: '16px',
		height: '36px',
		width: `${is_sending_message_pending ? '108px' : '78px'}`,
		textTransform: 'capitalize',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		'&:disabled': {
			backgroundColor: `${themeMode === 'dark' ? '#465359' : '#7a7d7e'}`,
			color: 'white',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '14px',
			width: `${is_sending_message_pending ? '90px' : '78px'}`,
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '12px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '28px',
		},
	})
)

export const FormBtnWrapper = styled(Box)(() => ({
	display: 'flex',
	gap: '20px',
	marginTop: '50px',
}));

export const TextFieldWrapper = styled(TextField)(({ theme }) => ({
	width: '100%',
	margin: '10px 0px',
}))

export const ModalTextField = styled(TextField)(({ theme }) => ({
	width: '512px',
}))

export const FormGroupWrapper = styled(FormGroup)(({ theme }) => ({
	marginTop: '21px',
}))

export const TrashIconModalWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	background: '#F7F6F4',
	height: '80px',
	width: '80px',
	margin: '0 auto',
	borderRadius: '50%',
}))

export const TrashIconModal = styled('img')(({ theme }) => ({
	height: '36px',
	width: '40px',
	margin: 'auto 0',
}))

export const DeleteModalBody = styled(Box)(({ theme }) => ({
	marginTop: '28px',
	display: 'flex',
	justifyContent: 'center',
}))

export const DeleteModalHeading = styled(Typography)(({ theme }) => ({
	fontSize: '24px',
	fontWeight: 600,
	lineHeight: '33.6px',
}))

export const DeleteModalSubHeading = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '25.6px',
}))

export const SubheadingWrapper = styled(Box)(({ theme }) => ({
	padding: '0px  50px',
}))

export const SubheadingInner = styled(Box)(({ theme }) => ({
	marginTop: '16px',
	display: 'flex',
	justifyContent: 'center',
}))

export const FormGroupWrapperPrice = styled(FormGroup)(({ theme }) => ({
	padding: '0px 20px',
	marginTop: '24px',
}))

export const PlanTextWrapper = styled(Box)(({ theme }) => ({
	padding: '0px 125px',
	display: 'flex',
	justifyContent: 'center',
}))

export const PlanText = styled(Typography)(({ theme }) => ({
	fontSize: '30px',
	fontWeight: 600,
	lineHeigth: '42px',
}))

export const OffersBox = styled(Box)(({ theme }) => ({
	padding: '0px 125px',
	display: 'flex',
	justifyContent: 'center',
}))

export const OffersText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	lineHeigth: '19.6px',
}))

export const PricingBoxWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '730px',
}))

export const PricingBoxMain = styled(Box)(({ theme }) => ({
	border: '1px solid black',
	borderRadius: '10px',
	width: '348px',
	marginTop: '40px',
}))

export const PackageText = styled(Typography)(({ theme }) => ({
	fontSize: '28px',
	lineHeight: '33.6px',
	fontWeight: 400,
	padding: '24px 20px',
}))

export const PackagePrice = styled(Typography)(({ theme }) => ({
	fontSize: '40px',
	lineHeight: '56px',
	fontWeight: 400,
	padding: '0px 20px',
}))

export const PackageBtn = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
}))

export const SubscribeBtn = styled(Button)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	lineHeight: '25.2px',
	height: '56px',
	width: '308px',
	margin: '20px 0px',
	background: theme.palette.background.default,
	color: theme.palette.text.primary,
	'&:hover': {
		backgroundColor: theme.palette.background.default,
	},
}))

export const SubscribeAdvanceBtn = styled(Button)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	lineHeight: '25.2px',
	height: '56px',
	width: '308px',
	margin: '20px 0px',
	background: theme.palette.primary.light,
	color: theme.palette.background.paper,
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
	},
}))

export const UploadWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	alignItems: 'center',
}))

export const TimeIcon = styled('img')(({ theme }) => ({
	height: '132px',
	width: '132px',
}))
export const UplaodText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	lineHeight: '25.6px',
	fontWeight: 500,
	textAlign: 'center',
}))

export const UplaodTextComplete = styled(Typography)(({ theme }) => ({
	fontSize: '24px',
	lineHeight: '33.6px',
	fontWeight: 600,
	textAlign: 'center',
	marginTop: '18px',
}))

export const BackArrow = styled('img')(({ theme }) => ({
	height: '24px',
	width: '24px',
	cursor: 'pointer',
}))

export const MobileHeader = styled(Box)(({ theme }) => ({
	marginTop: '-84px',
	width: '100%',
	position: 'absolute',
	zIndex: 99,
	background: theme.palette.background.paper,
}))

export const BackArrowWrapper = styled(Box)(({ theme }) => ({
	height: '84px',
	background: theme.palette.background.paper,
	display: 'flex',
	alignItems: 'center',
	marginLeft: '12px',
}))

export const ButtonWrapperMobile = styled(Box)(({ theme }) => ({
	background: theme.palette.background.paper,
	display: 'flex',
	justifyContent: 'center',
}))

export const VersionContainer = styled(Box)(
	({ theme, is_messages_exist }: IStyle) => ({
		width: `${is_messages_exist ? 'calc(90% - 50px)' : '89.65%'}`,
		margin: '0 auto',
		transform: `${is_messages_exist ? 'translateX(-20px)' : 'translateX(5px)'}`,
		[theme.breakpoints.down('lg1800')]: {
			width: `${is_messages_exist ? 'calc(90% - 50px)' : '89.5%'}`,
		},
		[theme.breakpoints.down('lg1600')]: {
			width: 'calc(90% - 10px)',
			transform: 'translateX(0px)',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px',
		},
	})
)

export const DownArrow = styled('img')(({ theme }) => ({
	height: '16px',
	width: '16px',
	cursor: 'pointer',
}))

export const Container = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	gap: '20px',
}))

export const SuggestionContainer = styled(Container)(({ theme }) => ({
	// width: '80%',
	// transform: 'translateX(-10px)',
	[theme.breakpoints.down('lg')]: {
		display: 'none',
	},
}))

export const SuggestionInnerWrapper = styled(Box)(({ theme }) => ({
	width: '85%',
	margin: 'auto 40px',
	maxWidth: '695px',
	overflowX: 'auto',
}))

export const Text = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 500,
	lineHeight: '19.2px',
	marginBottom: '15px',
}))

export const SuggestionBtnWrapper = styled(Box)(
	({ theme, sx: { themeMode } }: IStyle) => ({
		flex: '1 1 auto',
		display: 'flex',
		marginBlock: '10px',
		gap: '8px',
		overflowX: 'auto',
		paddingBottom: '5px',
	})
)

export const SummaryBtn = styled(Button)(
	({ theme, index, sx: { themeMode } }: IStyle) => ({
		textTransform: 'initial',
		whiteSpace: 'nowrap',
		padding: '8px 14px',
		background: `${
			themeMode === 'dark'
				? '#5D686D'
				: index === 0
				? '#EFF3F4'
				: index === 1
				? '#fcebea'
				: '#E3F4F2'
		}`,
		color: `${
			themeMode === 'dark'
				? '#FFFFFF'
				: index === 0
				? '#03363D'
				: index === 1
				? '#D73530'
				: '#248E9C'
		}`,
		fontSize: '12px',
		fontWeight: 400,
		fontFamily: 'DM Sans',
		borderRadius: '15px',
		transition: '0.5s',
		minWidth: 'fit-content',
		'&:hover': {
			backgroundColor: `${themeMode === 'light' ? '#e5d5d4' : '#4a5458'}`,
		},
		[theme.breakpoints.down('lg')]: {
			minWidth: '200px',
		},
	})
)

export const LoadingLoansWrapper = styled(Box)(
	({ sx: { themeMode } }: IStyle) => ({
		color: `${themeMode === 'light' ? '#03363D' : '#fff'}`,
		fontFamily: 'DM Sans',
		marginTop: '10px',
	})
)

export const LoadingMessagesWrapper = styled(Box)(
	({ sx: { themeMode } }: IStyle) => ({
		color: `${themeMode === 'light' ? '#03363D' : '#fff'}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'DM Sans',
	})
)

export const LoadingMessagesWrapperMobile = styled(Box)(
	({ sx: { themeMode, marginBlock = '20px' } }: IStyle) => ({
		color: `${themeMode === 'light' ? '#03363D' : '#fff'}`,
		textAlign: 'center',
		marginBlock,
		fontSize: '14px',
		fontFamily: 'DM Sans',
	})
)

export const LoadingLoansWrapperMobile = styled(Box)(
	({ sx: { themeMode } }: IStyle) => ({
		color: `${themeMode === 'light' ? '#03363D' : '#fff'}`,
		height: '100%',
		display: 'flex',
		fontFamily: 'DM Sans',
		alignItems: 'center',
		fontSize: '14px',
	})
)

export const MainMessagesWrapper = styled(Box)(() => ({
	flex: '1 1 auto',
	overflow: 'auto',
}))

export const ChatWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: '100%',
}))
