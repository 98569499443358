import { useLogout } from "../../../../hook/useLogout";
import { FeedbackIconWrapperComponent } from "../../../../pages/home/home.style";
import { getThemeReducer, useAppSelector } from "../../../../store";
import {
    ModalHeaderWrapper, HeaderText, FeedbackIcon,
    LikeIconWrapper, CrossIcon, CrossIconBox,
} from "./modal.header.style";

const ModalHeader = (props: any) => {
 		const { themeMode } = useAppSelector(getThemeReducer)
    const { showLikeIcon, crossIcon, headerText , handleClose} = props;
		const {logoutHandler} = useLogout();

    return (
			<>
				<ModalHeaderWrapper>
					<LikeIconWrapper>
						{showLikeIcon && (
							<FeedbackIconWrapperComponent sx={{ themeMode }}>
								<FeedbackIcon src={`${showLikeIcon}`} />
							</FeedbackIconWrapperComponent>
						)}
						{headerText && <HeaderText>{headerText}</HeaderText>}
					</LikeIconWrapper>
					{crossIcon && (
						// @ts-ignore
						<CrossIconBox themeMode={themeMode}>
							<CrossIcon
								src={`${
									themeMode === 'light'
										? '/images/svgs/crossIcon.svg'
										: '/images/svgs/cross-dark.svg'
								}`}
								onClick={() => handleClose()}
							/>
						</CrossIconBox>
					)}
					{props.headerRightCornerIcon && <CrossIcon onClick={logoutHandler} src={props.headerRightCornerIcon} />}
				</ModalHeaderWrapper>
			</>
		)
}

export default ModalHeader;