import { FC, PropsWithChildren } from "react";
import ModalHeader from "./header";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalFooter from "./footer/inder";

interface IModalBoxProps extends PropsWithChildren {
	isDisabled?: boolean
	height?: string
	width?: string
	cancelButtonText?: string
	actionButtonText?: JSX.Element | string
	showLikeIcon?: string
	crossIcon?: string
	headerText?: string
	handleClose?: () => void
	open?: boolean
	onClick?: () => void
	headerRightCornerIcon?: string
	headerIconTooltipText?: string;
}

const ModalBox: FC<IModalBoxProps> = ({
		isDisabled,
		height = '100vh',
    width,
    cancelButtonText = '',
    actionButtonText = '',
    showLikeIcon= '',
    crossIcon= '',
    headerText= '',
    handleClose,
    open,
    children,
		headerRightCornerIcon,
		headerIconTooltipText,
    onClick}) => {
    return (
			<>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					sx={{
						height: `${height}`,
						width: `${width}`,
						display: 'flex',
						alignItems: 'center',
						margin: '0 auto',
					}}
				>
					<Box sx={{ minWidth: width, outline: 0 }}>
						{headerText && (
							<ModalHeader
								showLikeIcon={showLikeIcon}
								crossIcon={crossIcon}
								headerText={headerText}
								handleClose={handleClose}
								headerRightCornerIcon={headerRightCornerIcon}
								headerIconTooltipText={headerIconTooltipText}
							/>
						)}
						{children}
						{(cancelButtonText || actionButtonText) && (
							<ModalFooter
								isDisabled={isDisabled}
								cancelButtonText={cancelButtonText}
								actionButtonText={actionButtonText}
								handleClose={handleClose}
								onActiveClick={onClick}
							/>
						)}
					</Box>
				</Modal>
			</>
		)
}

export default ModalBox;