import { ClickAwayListener, Tooltip, Typography } from '@mui/material'
import { Fragment, ReactNode, useCallback, useState } from 'react'
import { ActionText } from '../components/common/Messages/messages.style'
import ReactMarkdown from 'react-markdown'
import { useTooltip } from './useTooltip'

export const useEdit = () => {
	const { parseDetailsTooltip } = useTooltip()
	const [isTooltipOpen, setIsTooltipOpen] = useState<{
		[key: string]: boolean
	}>({})

	const closeTooltip = (
		id: string,
		event: any
	): void => {
		const tooltipElement = event.currentTarget.querySelector(
			`[data-tooltip-id="${id}"]`
		)
		if (!tooltipElement || !tooltipElement.contains(event.target as Node)) {
			setIsTooltipOpen(prev => ({ ...prev, [id]: false }))
		}
	}

	const openTooltip = (id: string): void => {
		setIsTooltipOpen(prev => ({ ...prev, [id]: true }))
	}

	const insertLink = useCallback(
		(str: string, details: string, id: string): (string | ReactNode)[] => {
			return str.split(' ').map((word, index) =>
				/^\[.+\]$/.test(word) ? (
					<Fragment key={`${word}_${index}`}>
						<ClickAwayListener onClickAway={event => closeTooltip(id, event)}>
							<Tooltip
								PopperProps={{ disablePortal: true }}
								arrow
								placement='top'
								disableFocusListener
								disableHoverListener
								disableTouchListener
								open={isTooltipOpen[id] || false}
								onClose={() =>
									closeTooltip(id, {
										currentTarget: document.body,
									} as React.MouseEvent<HTMLElement, MouseEvent>)
								}
								title={
									<ReactMarkdown>{parseDetailsTooltip(details)}</ReactMarkdown>
								}
							>
								<ActionText
									data-tooltip-id={id}
									onClick={() => openTooltip(id)}
								>
									{word.slice(1, -1)}
								</ActionText>
							</Tooltip>
						</ClickAwayListener>
						<Typography style={{ display: 'inline' }}> </Typography>
					</Fragment>
				) : (
					`${word} `
				)
			)
		}, [isTooltipOpen]
	)

	return { insertLink }
}
