import { createContext, PropsWithChildren, SetStateAction, useState, Dispatch } from 'react'
import { ToolTip } from '../enum';

type ActiveListAndIcon = {
	entity: any;
	tooltipTitle: ToolTip;
}

interface IActiveListContext {
	activeList: string
	setActiveList: Dispatch<SetStateAction<string>>
	activeEntity: ActiveListAndIcon
	setActiveEntity: Dispatch<SetStateAction<ActiveListAndIcon>>
}

export const ActiveListContext = createContext<IActiveListContext>(null);

export const ActiveListProvider = ({ children }: PropsWithChildren) => {
	const [activeList, setActiveList] = useState<string>('');
	const [activeEntity, setActiveEntity] = useState<ActiveListAndIcon>(null)

	const value = {
		activeList,
		setActiveList,
		activeEntity,
		setActiveEntity,
	}

	return <ActiveListContext.Provider value={value}>{children}</ActiveListContext.Provider>
}
