import React from 'react'

const CheckRight = () => {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill-rule='evenodd'
				clipRule='evenodd'
				d='M2.2915 10.0003C2.2915 15.7812 4.219 17.7087 9.99984 17.7087C15.7807 17.7087 17.7082 15.7812 17.7082 10.0003C17.7082 4.21949 15.7807 2.29199 9.99984 2.29199C4.219 2.29199 2.2915 4.21949 2.2915 10.0003Z'
				fill='#FE6A57'
			/>
			<path
				fill-rule='evenodd'
				clipRule='evenodd'
				d='M7.03369 10.0004L9.01202 11.9779L12.967 8.02295'
				fill='#FE6A57'
			/>
			<path
				d='M7.03369 10.0004L9.01202 11.9779L12.967 8.02295'
				stroke='white'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default CheckRight
