import { BlobProvider } from "@react-pdf/renderer";
import { MyDocument } from "./MyDocument";
import { useEffect } from "react";
import { getThemeReducer, useAppSelector } from "../../store";
import { useTheme } from "../../hook/useTheme";

const PdfPage = () => {
	const {themeMode} = useAppSelector(getThemeReducer);
	const {changeFavicon} = useTheme();

	useEffect(() => {
		changeFavicon(localStorage.getItem('themeMode') as 'light' | 'dark');
	}, []);

  return (
		<BlobProvider document={<MyDocument />}>
			{({ url }) => (
				<div
					style={{
						width: '100vw',
						height: '100vh',
						overflow: 'hidden',
					}}
				>
					<iframe
						title='pdf'
						src={localStorage.getItem('documentUrl')}
						height='100%'
						width='100%'
						style={{ border: 'none', padding: '0', margin: '0' }}
					/>
				</div>
			)}
		</BlobProvider>
	)
};

export { PdfPage };