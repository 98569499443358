import {useState } from "react";
/* @ts-ignore */
import MicRecorder from 'mic-recorder-to-mp3';
import { useMessages } from "./useMessages";

const recorder = new MicRecorder({ bitRate: 128 })

const useRecording = () => {
	const [isRecording, setIsRecording] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const {speechToTextMutation} = useMessages();

	const start = async () => {
		try {
			await navigator.mediaDevices.getUserMedia({ audio: true });
			setIsBlocked(false);
		} catch (err) {
			console.log('Permission Denied:', err);
			setIsBlocked(true);
		}

		if (!isBlocked) {
			recorder
				.start()
				.then(() => {
					setIsRecording(true)
				})
				.catch((e: Error) => console.log(e))
		}
	}

	const stopAndSendAudio = () => {
		recorder
			.stop()
			.getMp3()
			.then(([, blob]: [Buffer, Blob]) => {
				setIsRecording(false);
				const formData = new FormData();
				formData.append('file', blob);
				formData.append('type', 'audio/mpeg');
				speechToTextMutation.mutate(formData);
			})
			.catch((e: Error) => console.log(e))
	}

	return { start, stopAndSendAudio, isRecording }
}

export { useRecording }
