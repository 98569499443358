import { useCallback } from "react"
import { useMessages } from "./useMessages"
import { useSuggestions } from "./useSuggestions";
import { useQueryClientInteraction } from "./useQueryClientInteraction";
import { QueryKey } from "../enum";
import { useActiveList } from "./useActiveList";
import { IChatTimeline } from "../interfaces";
import { useText } from "./useText";

export const useTypewriter = () => {
  const {refetchMessages} = useMessages();
  const {refetchSuggestions} = useSuggestions();
  const {updateLastItemInInfinitiveQueryCache} = useQueryClientInteraction();
  const {getActiveLoan} = useActiveList();
  const {parseMarkdown} = useText();

  const refetchEntities = useCallback(() => {
		refetchMessages()
		refetchSuggestions()
	}, [])

	const updateQueryCache = useCallback(() => {
		updateLastItemInInfinitiveQueryCache<IChatTimeline[]>(
			[QueryKey.MESSAGES, getActiveLoan().id],
			'isAnimationFinished',
			true
		)
	}, [])

	const parseMarkdownHandler = useCallback((text: string) => {
		return parseMarkdown(text)
	}, [])

  return {
    refetchEntities,
    updateQueryCache,
    parseMarkdownHandler,
  }
}