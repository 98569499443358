import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { collapseReducer, drawerReducer, mobileReducer, themeReducer } from './slices';

const rootReducer = combineReducers({
  drawerReducer,
  collapseReducer,
  themeReducer,
  mobileReducer,
});

const store = configureStore({
	reducer: rootReducer,
})

type AppRootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export type { AppDispatch, AppRootState }

export { store }
