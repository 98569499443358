import { UseMutationResult } from '@tanstack/react-query'
import { useUsers } from './useUsers'
import { useCompany } from './useCompany'
import {
	accountConfig,
	billingConfig,
	companyConfig,
	FormConfigData,
} from '../pages/account/formConfigs'
import { IBillingAccount, ICompany, IUser } from '../interfaces'
import { useBilling } from './useBilling'

export type IFormConfig<T> = {
	entity: T
	getConfig: (entityKey: IFormConfigKey) => FormConfigData[]
	mutation: UseMutationResult<T, Error, Partial<T>, unknown>
	handler: (formData: Partial<T>) => void
	isPending: boolean
}

export type IMainFormConfig = {
	user: IFormConfig<IUser>
	company: IFormConfig<ICompany>
	billing: IFormConfig<IBillingAccount>
}

export type IFormConfigKey = keyof IMainFormConfig

export type OptionalEntity = IUser | ICompany | IBillingAccount

export const useConfig = () => {
	const { user, updateMeMutation, updateUserHandler, isUserPending } =
		useUsers()
	const {
		company,
		updateCompanyMutation,
		updateCompanyHandler,
		isCompanyPending,
	} = useCompany()
	const {
		billing,
		updateBillingHandler,
		updateBillingMutation,
		isBillingPending,
	} = useBilling()

	const formConfig: IMainFormConfig = {
		user: {
			entity: user,
			getConfig: (entityKey: IFormConfigKey) =>
				entityKey === 'user' ? accountConfig(user) : [],
			mutation: updateMeMutation,
			handler: updateUserHandler,
			isPending: isUserPending,
		},
		company: {
			entity: company,
			getConfig: (entityKey: IFormConfigKey) =>
				entityKey === 'company' ? companyConfig(company as ICompany) : [],
			mutation: updateCompanyMutation,
			handler: updateCompanyHandler,
			isPending: isCompanyPending,
		},
		billing: {
			entity: billing as IBillingAccount,
			getConfig: (entityKey: IFormConfigKey) =>
				entityKey === 'billing'
					? billingConfig(billing as IBillingAccount)
					: [],
			mutation: updateBillingMutation,
			handler: updateBillingHandler,
			isPending: isBillingPending,
		},
	}

	return { formConfig }
}
