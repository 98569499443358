export const sortEntity = <T extends { [key: string]: any }>(
	entity: T[] | null,
	sortKey: keyof T,
	sortOrder: 'asc' | 'desc' = 'desc'
) => {
	if (!entity) return null
  if (!entity.length) return [];
	return [...entity].sort((a, b) => {
		if (sortOrder === 'asc') {
			return String(a[sortKey]).localeCompare(String(b[sortKey]))
		} else {
			return String(b[sortKey]).localeCompare(String(a[sortKey]))
		}
	})
}
