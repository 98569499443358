import { useMutation, useQuery } from "@tanstack/react-query"
import { QueryKey } from "../enum"
import { companyService } from "../services"
import { ICompany } from "../interfaces"
import { useLocation } from "react-router-dom"

export const useCompany = () => {
	const {pathname} = useLocation();

  const {
		data: company,
		refetch: refetchCompany,
		isPending: isCompanyPending,
		...options
	} = useQuery({
		queryKey: [QueryKey.COMPANY],
		queryFn: ({signal}) => companyService.getInfo(signal),
		enabled: pathname.indexOf(QueryKey.COMPANY) !== -1,
		staleTime: Infinity,
	})

	const updateCompanyMutation = useMutation({
		mutationFn: (updatedFields: Partial<ICompany>) =>
			companyService.updateCompany(updatedFields),
		onSuccess: () => {
			refetchCompany()
		},
		onError: e => {
			console.error(e)
		},
	})

	const updateCompanyHandler = (updatedFields: Partial<ICompany>): void => {
		updateCompanyMutation.mutate(updatedFields)
	}

  return {
		updateCompanyHandler,
    updateCompanyMutation,
    options,
    company,
		isCompanyPending
	}
}