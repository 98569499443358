import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../enum"
import { useActiveList } from "./useActiveList";
import { useLoans } from "./useLoans";
import { suggestionsService } from "../services/suggestions.service";
import { getMobileReducer, useAppSelector } from "../store";

export const useSuggestions = () => {
  const { getActiveLoan } = useActiveList();
	const activeLoanId = getActiveLoan()?.id;
	const { isPathnameIsLoans } = useLoans();
	const { isMobile } = useAppSelector(getMobileReducer)
  	
  const {
		data: suggestions,
		refetch: refetchSuggestions,
		isLoading: isSuggestionsLoading,
		isPending: isSuggestionsPending,
		isFetching: isSuggestionFetching,
	} = useQuery({
		queryKey: [QueryKey.SUGGESTIONS, activeLoanId],
		queryFn: ({signal}) => suggestionsService.getByLoanId(activeLoanId, signal),
		enabled: !isMobile && isPathnameIsLoans && getActiveLoan() != null,
		staleTime: Infinity,
	});

  return {
    suggestions,
    refetchSuggestions,
    isSuggestionsLoading,
		isSuggestionsPending,
		isSuggestionFetching
  }
}