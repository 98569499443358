import { FC, useEffect } from 'react'
import { ILoan } from '../../../../interfaces'
import { Box, ListItemIcon, Tooltip } from '@mui/material'
import { ListId, ListItemMonths, MonthOfYearDivider } from '../../archive.style'
import Reload from '../../../../assets/reload'
import { useLoans } from '../../../../hook/useLoans'
import {
	InfiniteData,
	QueryObserverResult,
	RefetchOptions,
} from '@tanstack/react-query'
import { keyframes } from '@mui/system'
import { getThemeReducer, useAppSelector } from '../../../../store'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

interface IActiveMonthItemProps {
	loan: ILoan
	refetchLoans: (
		options?: RefetchOptions
	) => Promise<QueryObserverResult<InfiniteData<ILoan[]>>>
}

const ActiveMonthItem: FC<IActiveMonthItemProps> = ({ loan, refetchLoans }) => {
		const { themeMode } = useAppSelector(getThemeReducer)
	const { updateLoanStatusMutation } = useLoans()

	const restoreClickHandler = (): void => {
		updateLoanStatusMutation.mutate({
			updatingFields: { archive: false },
			loanIdFromParams: loan.id,
		})
	}

	useEffect(() => {
		updateLoanStatusMutation.status === 'success' && refetchLoans()
	}, [updateLoanStatusMutation.status])

	return (
		<ListItemMonths>
			<Box>
				<ListId>
					{loan.loan_number} - {loan.file_name}
				</ListId>
				<MonthOfYearDivider sx={{ themeMode }} />
			</Box>
			<Tooltip title='Restore' placement='top' arrow={true}>
				<ListItemIcon
					onClick={restoreClickHandler}
					sx={{
						marginLeft: '20px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						animation: `${
							updateLoanStatusMutation.status === 'pending'
								? `${rotate} 0.6s linear infinite`
								: ''
						}`,
					}}
				>
					<Reload />
				</ListItemIcon>
			</Tooltip>
		</ListItemMonths>
	)
}

export { ActiveMonthItem }
