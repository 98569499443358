import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { MainWrapper } from '../account/settings.style'

const MiddlePage = () => {
	const { isAuthenticated } = useAuth0()
	const navigate = useNavigate()

	useEffect(() => {
		if (isAuthenticated) {
			try {
				const redirectPath = window.location.pathname || '/loans'
				navigate(redirectPath)
			} catch (error) {
				console.error('Error fetching data:', error)
			}
		}
	}, [isAuthenticated, navigate])

	return <MainWrapper></MainWrapper>
}

export default MiddlePage
