import { FC } from 'react'
import {
	ModalBodyWrapper,
	UploadIcon,
	UploadTextComplete,
	UploadWrapper,
} from '../allModals.style'
import { IModalWithProps } from '../../../interfaces'
import { getThemeReducer, useAppSelector } from '../../../store'

const OperationFailed: FC<IModalWithProps> = ({ failureStateText }) => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
			<ModalBodyWrapper sx={{ themeMode }}>
				<UploadWrapper>
					<UploadIcon src='/images/svgs/uploadError.svg' />
				</UploadWrapper>
				<UploadTextComplete width='80%' marginTop='0px'>
					{failureStateText}
				</UploadTextComplete>
			</ModalBodyWrapper>
	)
}
export { OperationFailed }
