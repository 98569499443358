import { ToolTip } from "../enum/tooltip.enum";
import { ITooltip } from "../interfaces";

export const tooltips: ITooltip[] = [
	{
		title: ToolTip.ARCHIVE,
		placement: 'top',
		arrow: true,
		src: {
			light: '/images/svgs/innerArchive.svg',
			dark: '/images/svgs/archive-dark.svg',
		},
	},
	{
		title: ToolTip.REFRESH,
		placement: 'top',
		arrow: true,
		src: {
			light: '/images/svgs/Sync.svg',
			dark: '/images/svgs/Sync-dark.svg',
		},
	},
	{
		title: ToolTip.DELETE,
		placement: 'top',
		arrow: true,
		src: {
			light: '/images/svgs/trash.svg',
			dark: '/images/svgs/trash-dark.svg',
		},
	},
]