import { memo } from 'react'
import { NewLoanWrapper } from '../../components/common/NewLoanButton/NewLoanWrapper'
import { InnerSideBar } from '../../components/common/InnerSideBar/InnerSideBar'

const MobileNav = memo(
	() => {
		return (
			<>
					<InnerSideBar />
					<NewLoanWrapper src='/images/svgs/add.svg' />
			</>
		)
	}
)

export default MobileNav
