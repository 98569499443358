import { AxiosError } from "axios";
import { apiService } from "./api.service"

class AuthInterceptor {
	private getToken: (() => Promise<string>) | null = null
	private token: string;
	public logoutUser: () => void;

	public setAuthGetter(getToken: () => Promise<string>): void {
		this.getToken = getToken
	}

	public setLogoutUserMethod(logoutUser: () => void): void {
		this.logoutUser = logoutUser;
	}

	public resetToken(): void {
		this.token = null; 
	}

	public async intercept(config: any): Promise<any> {
		if (!this.getToken) {
			return config
		}

		if (!this.token) {
			try{
				this.token = await this.getToken();
			} catch(e) {
				this.logoutUser();
			}
		}

		config.headers['Authorization'] = `Bearer ${this.token}`
		return config
	}
}

export const authInterceptor = new AuthInterceptor();

apiService.interceptors.request.use(
	authInterceptor.intercept.bind(authInterceptor)
)

let isRefreshing = false;

apiService.interceptors.response.use(
	res => res,
	async (error: AxiosError) => {
		if ([401, 403].includes(error?.response?.status) && !isRefreshing) {
			isRefreshing = true
			try {
				authInterceptor.resetToken();
				isRefreshing = false;
			} catch (e) {
				isRefreshing = false;
			}
		}
		return Promise.reject(error);
	}
)