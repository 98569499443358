import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import { IDocument } from "../interfaces";


interface IRightNavBarContext {
	changedDocuments: IDocument[]
	setChangedDocuments: Dispatch<SetStateAction<IDocument[]>>
	responseMessage: string
	setResponseMessage: Dispatch<SetStateAction<string>>
}

export const RightNavbarContext = createContext<IRightNavBarContext>(null);

export const RightNavBarProvider = ({children}: PropsWithChildren) => {
  const [changedDocuments, setChangedDocuments] = useState<IDocument[]>([]);
  const [responseMessage, setResponseMessage] = useState<string>('');

  const value = {
		changedDocuments,
		setChangedDocuments,
		responseMessage,
		setResponseMessage,
	}

  return (
    <RightNavbarContext.Provider value={value}>
      {children}
    </RightNavbarContext.Provider>
  )
}