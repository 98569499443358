import { FC } from 'react'
import { FontComponent, HoveredElement, SideNavIconComponent, SideNavIconImageComponent, SimpleDivider } from './sidebar.style'
import { Tooltip } from '@mui/material'
import { useHeader } from '../../hook/useHeader'
import { getThemeReducer, useAppSelector } from '../../store'

interface NavItemProps {
	label: string;
	link: string;
	src: string;
	needDivider: boolean;
	collapsed: boolean;
}

const NavItem: FC<NavItemProps> = ({label, src, link, needDivider, collapsed}) => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const {onNavItemClick} = useHeader();

	return (
		<>
			<SideNavIconComponent
				clicked={collapsed}
				onClick={() => onNavItemClick(label, link)}
				collapsed={collapsed}
				sx={{ marginTop: '12px'}}
			>
				{collapsed ? (
					<Tooltip title={label} arrow placement='right'>
						<HoveredElement themeMode={themeMode} collapsed={collapsed}>
							<SideNavIconImageComponent src={src} />
						</HoveredElement>
					</Tooltip>
				) : (
					<HoveredElement themeMode={themeMode} collapsed={collapsed}>
						<SideNavIconImageComponent src={src} />
						<FontComponent collapsed={collapsed}>{label}</FontComponent>
					</HoveredElement>
				)}
			</SideNavIconComponent>
			{needDivider && <SimpleDivider collapsed={collapsed} />}
		</>
	)
}

export { NavItem }
