import {
	DeleteModalBody,
	ModalBodyWrapper,
	TrashIconModal,
	TrashIconModalWrapper,
	DeleteModalHeading,
	SubheadingWrapper,
	SubheadingInner,
	DeleteModalSubHeading,
} from '../allModals.style'
import { getThemeReducer, useAppSelector } from '../../../store'

const DeleteModal = () => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ModalBodyWrapper sx={{ themeMode }}>
			{/* @ts-ignore */}
			<TrashIconModalWrapper sx={{themeMode}}>
				<TrashIconModal alt='Modal Trash' src='/images/svgs/trash-modal.svg' />
			</TrashIconModalWrapper>
			<DeleteModalBody>
				<DeleteModalHeading>
					Sure you want to delete this item?
				</DeleteModalHeading>
			</DeleteModalBody>
			<SubheadingWrapper>
				<SubheadingInner>
					<DeleteModalSubHeading>
						This action will move the item to the archive, and it can be
						retrieved later if needed.
					</DeleteModalSubHeading>
				</SubheadingInner>
			</SubheadingWrapper>
		</ModalBodyWrapper>
	)
}

export { DeleteModal }
