import { Box, Typography } from "@mui/material";
import { HeaderMobileWrapper, MenuIcon } from "./header.style";
import { useLocation } from "react-router-dom";
import { drawerActions, getThemeReducer, useAppDispatch, useAppSelector } from "../../store";

const HeaderMobile = (props :any) => {
    const { themeMode } = useAppSelector(getThemeReducer)
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    const {toggleIsMobileDrawerOpen} = drawerActions;

    return(
        <>
         <HeaderMobileWrapper>
            <Box>
              <MenuIcon src={`${themeMode === 'light' ? '/images/svgs/menu-mobile.svg' : '/images/svgs/menu-dark.svg'}`} onClick={() => dispatch(toggleIsMobileDrawerOpen())}/>
            </Box>
            <Box>
                <Typography sx={{fontSize: '20px', fontWeight: 600, lineHeight: '28px', fontFamily: 'Bai Jamjuree'}}>
                    {pathname === '/loans' ? 'Home' : pathname === '/settings' ? 'Setting' : pathname === '/archive' ? 'Archive' : 'Account'}
                    </Typography> 
            </Box>
        </HeaderMobileWrapper>
        </>
    );
}

export default HeaderMobile;