import { createContext, Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, useState } from "react"
import { IChatTimeline } from "../interfaces";

interface IChatContext {
	triggerNewMessage: boolean
	setTriggerNewMessage: Dispatch<SetStateAction<boolean>>
	sortedMessages: IChatTimeline[]
	setSortedMessages: Dispatch<SetStateAction<IChatTimeline[]>>
	msgWrapper: MutableRefObject<HTMLDivElement>
	setMsgWrapper: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>
	msgWrapperMobile: MutableRefObject<HTMLDivElement>
	setMsgWrapperMobile: Dispatch<
		SetStateAction<MutableRefObject<HTMLDivElement>>
	>
	messageId: number
	setMessageId: Dispatch<SetStateAction<number>>
	speechTranslation: string
	setSpeechTranslation: Dispatch<SetStateAction<string>>
	isTypingEffectInProgress: boolean
	setIsTypingEffectInProgress: Dispatch<SetStateAction<boolean>>
}

export const ChatContext = createContext<IChatContext>(null);

export const ChatProvider = ({children}: PropsWithChildren) => {
  const [triggerNewMessage, setTriggerNewMessage] = useState<boolean>(false);
  const [sortedMessages, setSortedMessages] = useState<IChatTimeline[]>([]); 
	const [msgWrapper, setMsgWrapper] =
		useState<MutableRefObject<HTMLDivElement>>(null);
	const [msgWrapperMobile, setMsgWrapperMobile] =
		useState<MutableRefObject<HTMLDivElement>>(null)
	const [messageId, setMessageId] = useState<number>(null);
	const [speechTranslation, setSpeechTranslation] = useState<string>('');
	const [isTypingEffectInProgress, setIsTypingEffectInProgress] =
		useState<boolean>(false)


  const value = {
		triggerNewMessage,
		setTriggerNewMessage,
		sortedMessages,
		setSortedMessages,
		msgWrapper,
		setMsgWrapper,
		msgWrapperMobile,
		setMsgWrapperMobile,
		messageId,
		setMessageId,
		speechTranslation,
		setSpeechTranslation,
		isTypingEffectInProgress, 
		setIsTypingEffectInProgress
	}

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  )
}