import { chatConstants, urls } from '../constants'
import { IMessage, ICommonParams, IChatTimeline, ICreateMessage, ISpeechToText } from '../interfaces'
import { IFeedbackRequest, IFeedbackResponse } from '../interfaces/feedback.interface';
import { sortEntity } from '../utils/sortEntity';
import { apiService } from './api.service'

export const messageService = {
	getByLoanId: async (loanId: number, params?: ICommonParams) => {
		const { data } = await apiService.get<IMessage[]>(urls.messages(loanId), {
			params,
		})
		return sortEntity<IMessage>(data, 'timestamp', 'asc')
	},

	getChatTimeline: async (
		loanId: number,
		pageParam: number,
		signal: AbortSignal
	) => {
		const params = {
			skip: pageParam * chatConstants.skip,
			limit: chatConstants.limit,
		}
		const { data } = await apiService.get<IChatTimeline[]>(
			urls.chatTimeline(loanId),
			{ params, signal }
		)
		return data.reverse()
	},

	sendMessage: async (loanId: number, message: ICreateMessage) => {
		const { data } = await apiService.post<string>(
			urls.messages(loanId),
			message
		)
		return data
	},
	sendFeedback: async (
		loanId: number,
		messageId: number,
		feedback: IFeedbackRequest
	) => {
		const { data } = await apiService.post<IFeedbackResponse>(
			urls.feedback(loanId, messageId),
			feedback
		)
		return data
	},
	speechToText: async (file: FormData) => {
		const { data } = await apiService.post<ISpeechToText>(
			urls.speechToText,
			file,
			{ headers: { 'Content-Type': 'multipart/form-data' } }
		)
		return data
	},
}
