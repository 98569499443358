import { FC } from "react";
import { ModalBodyWrapper, ModalTextField } from "../../allModals.style";
import { IModalWithProps } from "../../../../interfaces";
import { getThemeReducer, useAppSelector } from "../../../../store";

const LikeModal: FC<IModalWithProps> = ({
	onChangefeedback_text,
}) => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ModalBodyWrapper header={true} sx={{ themeMode }}>
			<ModalTextField
				multiline
				placeholder='What do you like about the response?'
				minRows={10}
				onChange={onChangefeedback_text}
			/>
		</ModalBodyWrapper>
	)
}

export {LikeModal};