import {
	Box,
	Checkbox,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material'
import { FC, InputHTMLAttributes, useEffect, useState } from 'react'
import { StyledTextField } from '../../../../pages/account/settings.style'
import UncheckIcon from '../../../../assets/uncheck'
import CheckIcon from '../../../../assets/check'
import { ControllerRenderProps } from 'react-hook-form'
import { OptionalEntity } from '../../../../hook/useConfig'
import { FormConfigData } from '../../../../pages/account/formConfigs'
import { getThemeReducer, useAppSelector } from '../../../../store'

interface IFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	field: ControllerRenderProps<Partial<OptionalEntity>>
	inputData: FormConfigData
}

const FormField: FC<IFormFieldProps> = ({ type, field, inputData }) => {
	const [isChangeFieldActive, setIsChangeFieldActive] = useState<boolean>(false)
	const [color, setColor] = useState<string>('')
	const { themeMode } = useAppSelector(getThemeReducer)
	const { inputName, currentValue, values } = inputData

	useEffect(() => {
		const currentColor =
			isChangeFieldActive && themeMode === 'light'
				? '#f0eeee'
				: isChangeFieldActive && themeMode === 'dark'
				? '#6a6767'
				: themeMode === 'light'
				? '#E0E0E0'
				: '#28363A'
		setColor(currentColor)
	}, [themeMode, isChangeFieldActive])

	const switchOnChangeState = (): void => {
		setIsChangeFieldActive(true)
	}

	const switchOffChangeState = (): void => {
		setIsChangeFieldActive(false)
	}

	if (type === 'checkbox') {
		return (
			<FormControlLabel
				control={
					<Checkbox
						{...field}
						defaultChecked={field.value === 'true'}
						icon={<UncheckIcon />}
						checkedIcon={<CheckIcon />}
						name={inputData.inputName}
					/>
				}
				label=''
			/>
		)
	}

	if (type === 'radio') {
		const parseLabel = (label: string): string => {
			const parsedValueObject = {
				true: 'Yes',
				false: 'No',
			}
			const key = label as keyof typeof parsedValueObject
			return parsedValueObject[key] ? parsedValueObject[key] : label
		}

		return (
			<RadioGroup
				{...field}
				aria-labelledby='demo-radio-buttons-group-label'
				defaultValue={currentValue}
				name={inputName}
			>
				<Box sx={{ display: 'flex', gap: '20px', textTransform: 'capitalize' }}>
					{values.map((value, index: number) => {
						return (
							<FormControlLabel
								key={index}
								sx={{
									'& .Mui-disabled': {
										color: themeMode === 'dark' ? '#c9c6c6' : '#767575',
									},
								}}
								disabled={inputData.disabled}
								value={value}
								control={<Radio />}
								label={
									<Typography
										sx={{ color: themeMode === 'light' ? '#03363D' : 'white' }}
									>
										{parseLabel(value.replace('_', ' '))}
									</Typography>
								}
							/>
						)
					})}
				</Box>
			</RadioGroup>
		)
	}

	return (
		<StyledTextField
			sx={{themeMode}}
			{...field}
			type={type}
			id='filled-basic'
			variant='filled'
			name={inputName}
			onFocus={switchOnChangeState}
			onBlur={switchOffChangeState}
			disabled={inputData.disabled}
			InputProps={{
				disableUnderline: true,
				sx: {
					'& .MuiInputBase-input': {
						cursor: 'pointer',
						backgroundColor: color,
						padding: '15px 20px',
						borderRadius: '10px',
					'&.Mui-disabled': {
						WebkitTextFillColor: `${themeMode === 'light' ? '#03363D' : 'white'}`,
						},
					},
				},
			}}
		/>
	)
}

export { FormField }
