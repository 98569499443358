import {
	ButtonWrapperComponent,
	NewDocButton,
	ReSyncLoanButton,
	ResyncLoanText,
} from './topButton.style'
import { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../../../context'
import { useAnalyses } from '../../../../hook/useAnalyses'
import { ModalWrapper } from '../../../../components/allModals/upload/ModalWrapper'
import { Loader } from '../../../../components/common/Loader/Loader'
import { getThemeReducer, useAppSelector } from '../../../../store'

const TopButton = () => {
	const {themeMode} = useAppSelector(getThemeReducer);
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext)
	const [isProcessing, setIsProcessing] = useState<boolean>(false)
	const { askAnalysesMutation: { mutate, isPending }} = useAnalyses()

	const reanalyzeHandler = (): void => {
		mutate()
	}

	useEffect(() => {
		!isPending ? setIsProcessing(false) : setIsProcessing(true)
	}, [isPending])

	return (
		<>
			<ButtonWrapperComponent>
				<NewDocButton
					className='button'
					onClick={() => openModal('document')}
					sx={{ themeMode }}
				>
					<ResyncLoanText sx={{ textTransform: 'capitalize' }}>
						New Document
					</ResyncLoanText>
				</NewDocButton>
				<ReSyncLoanButton className='button' onClick={reanalyzeHandler}>
					<ResyncLoanText sx={{ textTransform: 'capitalize' }}>
						{isProcessing ? (
							<Loader color='inherit' animatedTextWidth='90px' loadingText='Processing' />
						) : (
							'Reanalyze'
						)}
					</ResyncLoanText>
				</ReSyncLoanButton>
			</ButtonWrapperComponent>
			<ModalWrapper open={isModalOpen && modalKey === 'document'} />
		</>
	)
}
export default TopButton
