import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import { IStyle } from '../../../../interfaces';

export const ButtonWrapperComponent = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const NewDocButton = styled(Button)(({ theme, sx: { themeMode } }: IStyle) => ({
	background: themeMode === 'light' ? '#F7F6F4' : '#465359',
	color: theme.palette.text.primary,
	flex: '1.2',
	padding: '15px',
	borderRadius: '10px',
	'&:hover': {
		background: themeMode === 'light' ? '#dbdad8' : '#677a82',
		boxShadow: 'none',
		borderRadius: '10px',
	},
   [theme.breakpoints.down('lg1400')]: {
    flex: 1,
  }
}))

export const ResyncLoanText = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
  fontStyle: 'normal',
  lineHeight: '140%',
  fontFamily: "'Exo 2'"

}));

export const ReSyncLoanButton = styled(Button)(({ theme }: any) => ({
  background: theme.palette.background.paper,
	flex: '1',
  height: '56px',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.secondary}`,
  padding: '15px',
  borderRadius: '10px',
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    borderRadius: '10px'
  },
}));