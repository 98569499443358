import { useContext, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { useActiveList } from "./useActiveList";
import { ModalContext } from "../context";
import { useUsers } from "./useUsers";
import { breakpointConstants } from "../constants";
import { collapseActions, drawerActions, getMobileReducer, useAppDispatch, useAppSelector } from "../store";
import { useTheme } from "./useTheme";
import { useBilling } from "./useBilling";

export const useHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);
	const usernameBoxRef = useRef<HTMLDivElement>();
	const {pathname} = useLocation();
	const {setActiveList} = useActiveList();
	const { openModal } = useContext(ModalContext);
	const {user} = useUsers();
	const dispatch = useAppDispatch();
	const {toggleIsLeftDrawerOpen, toggleIsMobileDrawerOpen} = drawerActions;
	const {toggleCollapsed} = collapseActions;
	const {onToggleMode} = useTheme();
	const { isMobile } = useAppSelector(getMobileReducer)
	const { handleClickBillingNavItem } = useBilling()

  const toggleDropDown = () => {
		setAnchorEl(prev => prev ? null : usernameBoxRef.current);
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

  const navigateToAndCloseDropDown = (link: string) => {
    toggleDropDown();
    navigate(link);
    if (isMobile) {
			dispatch(toggleIsMobileDrawerOpen());
		}
  }

	const onNavItemClick = (label: string, link: string) => {
		isMobile && dispatch(toggleIsMobileDrawerOpen());
		if(label === 'Home') {
			navigate('/loans');
			return
		} else if (['Light Mode', 'Dark Mode'].includes(label)) {
			user && onToggleMode();
			return;
		} else if(label === 'Menu') {
			window.innerWidth <= breakpointConstants.autoCollapse.leftNavbar ? dispatch(toggleIsLeftDrawerOpen()) : dispatch(toggleCollapsed())
			return;
		} else if (label === 'Power Ups') {
			if(user) {
				openModal('powerUp');
			}
			return;
		} else if (label === 'Help Center') {
			window.open(`http://help.${window.location.host.replace('app.', '')}`, '_blank');
			return;
		}
		else if(label === 'Billing') {
			handleClickBillingNavItem();
			return;
		} else {
			(link && link !== pathname) && navigate(link)
		}
		setActiveList(link[0].toUpperCase() + link.slice(1));
	}

  return {
		toggleDropDown,
		handleClose,
		usernameBoxRef,
		anchorEl,
		navigateToAndCloseDropDown,
		onNavItemClick,
	}
}