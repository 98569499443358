import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { IStyle } from "../../../interfaces";

export const ActionMobileWrapper = styled(Box)(({sx: {themeMode}}: IStyle) => ({
	fontFamily: 'DM Sans',
	color: `${themeMode === 'light' ? '#03363D' : '#fff'}`,
	fontStyle: 'italic',
	marginBlock: '10px',
	paddingInline: '95px 50px',
	lineHeight: '16.8px',
	fontSize: '14px',
}))

export const UserMessageAvatarMobile = styled(Box)(
	({ themeMode }: { themeMode: 'light' | 'dark' }) => ({
		width: '24px',
		height: '24px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		background: `${themeMode === 'light' ? '#03363D' : '#E3F4F2'}`,
		opacity: 0.4,
		color: `${themeMode === 'light' ? '#E3F4F2' : '#03363D'}`,
		fontWeight: 600,
		fontSize: '14px',
	})
);

export const MainMessagesMobileWrapper = styled(Box)(({ theme }: IStyle) => ({
	paddingTop: '20px',
	flex: '1 1 auto',
	overflowY: 'auto',
}))