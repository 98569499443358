import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { colors } from './color';
import { ThemeProps } from './interface';
import { getThemeReducer, useAppSelector } from '../store';

export function ThemeProvider({ children }: ThemeProps) {
	const {themeMode} = useAppSelector(getThemeReducer);
  const isLight = themeMode === 'light';

  const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
				lg1800: 1800,
				lg1660: 1660,
				lg1600: 1600,
				lg1400: 1400,
				lg1300: 1300,
				lg1000: 1000,
				md760: 760,
			},
		},
		typography: {
			fontFamily: 'Roboto Slab',
			button: {
				fontFamily: 'Bai Jamjuree',
			},
		},
		palette: isLight ? colors.light.palette : colors.dark.palette,
		components: {
			MuiTypography: {
				styleOverrides: {
					root: {
						color: isLight
							? colors.light.palette.text.primary
							: colors.dark.palette.text.primary,
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: '14px',
						fontFamily: 'Bai Jamjuree',
						color: isLight
							? colors.light.palette.text.primary
							: colors.dark.palette.text.primary,
						padding: '10px 15px',
						backgroundColor: isLight
							? '#fff'
							: colors.dark.palette.background.default,
						'& .MuiTooltip-arrow:before': {
							backgroundColor: isLight
								? '#fff'
								: colors.dark.palette.background.default,
						},
					},
				},
			},
		},
	})

  return(
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
};