import { Checkbox, FormControlLabel } from '@mui/material';
import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import UncheckIcon from '../../../../assets/uncheck';
import CheckIcon from '../../../../assets/check';
import { Checkboxes } from '../../../../interfaces';
import { ICheckbox } from './checkboxLabels';

interface IDislikeCheckboxProps {
	checkboxData: ICheckbox;
	setCheckboxes: Dispatch<SetStateAction<Checkboxes>>
}

const DislikeCheckbox: FC<IDislikeCheckboxProps> = ({checkboxData, setCheckboxes}) => {
	const {label, name, checked} = checkboxData;
	const [isChecked, setIsChecked] = useState<boolean>(checked)

	const onChangeHandler = (): void => {
		setIsChecked(prev => !prev);
	}

	useEffect(() => {
		setCheckboxes(prev => ({ ...prev, [name]: checked }));
	}, []);

	useEffect(() => {
		setCheckboxes(prev => ({ ...prev, [name]: isChecked }));
	}, [isChecked]);

  
  return (
		<FormControlLabel
			control={
				<Checkbox
					onChange={onChangeHandler}
					icon={<UncheckIcon />}
					checkedIcon={<CheckIcon />}
				/>
			}
			label={label}
			checked={isChecked}
		/>
	)
};

export { DislikeCheckbox };