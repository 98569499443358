import { FC, memo, useEffect, useLayoutEffect, useRef } from 'react'
import Typewriter, { TypewriterClass } from 'typewriter-effect'
import { MessageMarginSetter } from '../Messages/Message/message.style'
import './styles.css'

interface ITypewriterProps {
	textToType: string
	interval: number
	handleIsTypingEffectInProgress: (val: boolean) => void
	parseMarkdownHandler: (text: string) => string | Promise<string>
	updateQueryCache: () => void
	refetchEntities: () => void
}

const TypewriterComponent: FC<ITypewriterProps> = memo(
	({
		textToType,
		interval,
		handleIsTypingEffectInProgress,
		parseMarkdownHandler,
		updateQueryCache,
		refetchEntities,
	}) => {
		const typewriterRef = useRef<TypewriterClass>(null)
		const charCount = useRef<string>('');

		const isAnimationEnds = (): boolean => {
			const textToTypePlainText =
				new DOMParser().parseFromString(
					parseMarkdownHandler(textToType) as string,
					'text/html'
				).body.textContent || ''

			return textToTypePlainText === charCount.current;
		}

		const handleEndAnimation = (): void => {
			handleIsTypingEffectInProgress(false)
			charCount.current = '';
			updateQueryCache()
			refetchEntities()
		}

		useLayoutEffect(() => {
			handleIsTypingEffectInProgress(true)
		}, [])

		useEffect(() => {
			return () => {
				!isAnimationEnds() && handleEndAnimation()
			}
		}, [])

		const onCreateTextNode = (character: string, textNode: Text) => {
			charCount.current += character
			isAnimationEnds() && handleEndAnimation();
			return textNode
		}

		return (
			<MessageMarginSetter>
				<Typewriter
					onInit={typewriter => {
						typewriterRef.current = typewriter
					}}
					options={{
						autoStart: true,
						strings: `<div class="myDiv">${
							parseMarkdownHandler(textToType) as string
						}</div>`,
						loop: false,
						delay: interval,
						cursor: charCount.current.length >= textToType.length ? '|' : '',
						onCreateTextNode,
					}}
				/>
			</MessageMarginSetter>
		)
	}
)

export { TypewriterComponent }
