import { useMemo } from 'react'
import { FormConfigData } from '../pages/account/formConfigs'
import { OptionalEntity } from './useConfig'
import { UnionToIntersection } from 'react-hook-form/dist/types/path/common'

interface IUseFormCustomParams {
	entity: OptionalEntity
	config: FormConfigData[]
}

export const useFormMethods = (params: IUseFormCustomParams) => {
	const { entity, config } = params

	const defaultValues = useMemo(() => {
		if (!entity) return {}
		return config.reduce((acc, field) => {
			acc[field.inputName] = field.currentValue
			return acc
		}, {} as Record<keyof UnionToIntersection<typeof entity>, any>)
	}, [entity])

	const getDirtyKeys = (
		data: Partial<OptionalEntity>,
		dirtyFields: Record<keyof OptionalEntity, boolean>
	): Partial<OptionalEntity> => {
		const formResult = {} as typeof data
		const dirtyFieldsKeys = Object.keys(dirtyFields)
		dirtyFieldsKeys.forEach(key => {
			const currentKey = key as keyof typeof data
			formResult[currentKey] = data[currentKey]
		})
		return formResult
	}

	return {
		getDirtyKeys,
		defaultValues,
	}
}
