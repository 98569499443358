import React, { useState, useEffect } from 'react'
import TopButton from './components/topButton/topButton'
import ActionButton from './components/actionButton/actionButton'
import {
	ButtonBoxWrapper,
	RightNavComponent,
	ListItemBoxWrapper,
	ActionBtnBoxWrapper,
	DoucmentBtn,
	DoucmentBtnIcon,
	DoucmentCrossIcon,
	DocumentHeader,
	DocumentIcon,
	RightNavBarListWrapper,
} from './rightNavBar.style'
import ListItems from './components/listItem/listItems'
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material'
import { breakpointConstants } from '../../constants'
import { drawerActions, getDrawerReducer, getThemeReducer, useAppDispatch, useAppSelector } from '../../store'

const RightNavBar = () => {
	const [isLargeScreen, setIsLargeScreen] = useState(false)

	const theme = useTheme()
	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(
				window.innerWidth >= breakpointConstants.autoCollapse.rightNavbar
			)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const downSm = useMediaQuery(theme.breakpoints.down('sm'))
	const {isRightNavBarDrawerOpen} = useAppSelector(getDrawerReducer);
	const {themeMode} = useAppSelector(getThemeReducer);
	const dispatch = useAppDispatch()
	const { toggleIsRightDrawerOpen: toggleIsDrawerOpen } = drawerActions

	return (
		<>
			{isLargeScreen ? (
				<RightNavComponent>
					<RightNavBarListWrapper sx={{ themeMode }}>
						<ButtonBoxWrapper>
							<TopButton />
						</ButtonBoxWrapper>
						<ListItemBoxWrapper>
							<ListItems />
						</ListItemBoxWrapper>
					</RightNavBarListWrapper>
					<ActionBtnBoxWrapper>
						<ActionButton />
					</ActionBtnBoxWrapper>
				</RightNavComponent>
			) : (
				<Drawer
					variant='temporary'
					open={isRightNavBarDrawerOpen}
					onClose={() => dispatch(toggleIsDrawerOpen())}
					anchor='right'
					PaperProps={{
						sx: {
							width: `${downSm ? '100vw' : '400px'}`,
						},
					}}
				>
					{/* Docuement Header */}
					<DocumentHeader>
						<Box>
							<DoucmentBtn
								startIcon={
									<DoucmentBtnIcon
										src={`${
											themeMode === 'light'
												? '/images/svgs/headerDoc.svg'
												: '/images/svgs/headerDoc-dark.svg'
										}`}
									/>
								}
							>
								{' '}
								Documents{' '}
							</DoucmentBtn>
						</Box>
						<DocumentIcon>
							<DoucmentCrossIcon
								src={`${
									themeMode === 'light'
										? '/images/svgs/cross.svg'
										: '/images/svgs/cross-dark.svg'
								}`}
								onClick={() => dispatch(toggleIsDrawerOpen())}
							/>
						</DocumentIcon>
					</DocumentHeader>
					<RightNavComponent>
						<Box
							sx={{
								height: 'calc(100vh - 254px)',
								overflow: 'auto',
							}}
						>
							<ButtonBoxWrapper>
								<TopButton />
							</ButtonBoxWrapper>
							<ListItemBoxWrapper>
								<ListItems />
							</ListItemBoxWrapper>
						</Box>
						<ActionBtnBoxWrapper>
							<ActionButton />
						</ActionBtnBoxWrapper>
					</RightNavComponent>
				</Drawer>
			)}
		</>
	)
}

export default RightNavBar
