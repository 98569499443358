import {FC, Fragment} from 'react';
import { ArrowIconWrapper, CustomListItem, CustomListItemBox, ListHeading, ListHeadingWrapper, ListSubHeading } from '../listItem/listItems.style';
import { useDocuments } from '../../../../hook/useDocuments';
import { MainListWrapper } from '../../rightNavBar.style';
import { IDocument } from '../../../../interfaces';
import { ListItem } from '../listItem/ListItem/ListItem';
import { ArrowIcon } from '../../../../pages/account/settings.style';
import { CustomDivider } from '../../../innerSideBar/innerSideBar.style';
import { getThemeReducer, useAppSelector } from '../../../../store';

interface ICategoryProps {
	isActiveCategory: boolean
	category: string
	toggleCategoryBox: (category: string) => void
}

const Category: FC<ICategoryProps> = ({isActiveCategory, category, toggleCategoryBox}) => {
  const { themeMode } = useAppSelector(getThemeReducer)
  const { sortedDocuments, getActiveDocsNumberInCategory } = useDocuments()
	
  return (
		<Fragment>
			<CustomListItem active={isActiveCategory} themeMode={themeMode}>
				<CustomListItemBox onClick={() => toggleCategoryBox(category)}>
					<ListHeadingWrapper>
						<ListHeading>{category?.toUpperCase()}</ListHeading>
						<ListSubHeading>
							{getActiveDocsNumberInCategory(sortedDocuments[category])} of{' '}
							{sortedDocuments[category].length} Documents
						</ListSubHeading>
					</ListHeadingWrapper>
					<ArrowIconWrapper>
						<ArrowIcon
							src={`/images/svgs/arrow-right-${themeMode}.svg`}
							width='24px'
							height='24px'
							sx={{
								rotate: isActiveCategory && 'rotate(90deg)',
							}}
						/>
					</ArrowIconWrapper>
				</CustomListItemBox>
				{isActiveCategory && (
					<MainListWrapper sx={{ themeMode }}>
						{sortedDocuments[category].map((document: IDocument) => (
							<ListItem key={document.id} document={document} />
						))}
					</MainListWrapper>
				)}
			</CustomListItem>
			<CustomDivider sx={{ width: '100%', margin: '0 auto' }} />
		</Fragment>
	)
};

export { Category };