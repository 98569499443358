import { ArrowIcon, CustomMobileList, MobileListText, MobileListWrapper } from './settings.style'
import { settingsDummyData } from '../../dummyData'
import { getThemeReducer, useAppSelector } from '../../store'
import { useActiveList } from '../../hook/useActiveList'


const InnerSideBarMobileForSettingsPage = () => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const {toggleActiveItemByClick} = useActiveList();

	return (
		<CustomMobileList>
			{settingsDummyData.settings.map((item: any, index: number) => (
				<>
					<MobileListWrapper
						key={index}
						onClick={() => toggleActiveItemByClick(item)}
					>
						<MobileListText>{item.title}</MobileListText>
						<ArrowIcon
							sx={{ rotate: 'rotate(0deg)' }}
							src={`/images/svgs/arrow-right-${themeMode}.svg`}
							width='24px'
							height='24px'
						/>
					</MobileListWrapper>
				</>
			))}
		</CustomMobileList>
	)
}

export default InnerSideBarMobileForSettingsPage
