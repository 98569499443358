import React from 'react'

const CheckIcon = () => {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill-rule='evenodd'
				clipRule='evenodd'
				d='M3.20801 14.0002C3.20801 22.0933 5.90651 24.7918 13.9997 24.7918C22.0928 24.7918 24.7913 22.0933 24.7913 14.0002C24.7913 5.907 22.0928 3.2085 13.9997 3.2085C5.90651 3.2085 3.20801 5.907 3.20801 14.0002Z'
				fill='#FE6A57'
			/>
			<path
				fill-rule='evenodd'
				clipRule='evenodd'
				d='M9.84668 13.9999L12.6163 16.7684L18.1533 11.2314'
				fill='#FE6A57'
			/>
			<path
				d='M9.84668 13.9999L12.6163 16.7684L18.1533 11.2314'
				stroke='white'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default CheckIcon
