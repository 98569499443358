import { styled } from '@mui/system';
import { Box, Typography, Button, alpha } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { IStyle } from '../../interfaces';

export const ButtonBoxWrapper = styled(Box)(({ theme }) => ({
  height: '60px',
  marginTop: '28px',
  paddingInline: '15px',
}));

export const ListItemBoxWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 346px)',
  [theme.breakpoints.down('lg')]: {
    height: 'calc(100vh - 320px)',
  },
}));

export const ActionBtnBoxWrapper = styled(Box)(({ theme }) => ({
  height: '154px',
}));

export const RightNavComponent = styled('div')(({ theme }) => ({
	minHeight: 'calc(100vh - 100px)',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
	background: theme.palette.background.paper,
	width: '400px',
	float: 'right',
	[theme.breakpoints.down('lg1600')]: {
		width: '350px',
	},
	[theme.breakpoints.down('lg1400')]: {
		width: '400px',
    position: 'relative',
    top: 15
	},
	[theme.breakpoints.down('sm')]: {
		width: '100%',
	},
}))

export const RightNavBarListWrapper = styled(Box)(({sx: {themeMode}}: IStyle) => ({
  height: 'calc(100vh - 254px)',
  overflow: 'auto',
  flex: '1 1 auto',
}));

export const Icon = styled('img')(({ theme, sx: {marginLeft = '0px'} }: any) => ({
  height: '18px',
  width: '18px',
  marginLeft,
  cursor: 'pointer',
}));

export const MainListWrapper = styled(List)(({ theme, sx: {themeMode} }: IStyle) => ({
	background: `${
		themeMode === 'light' ? theme.palette.background.default : '#2E3D43'
	}`,
	maxHeight: '375px',
	overflowY: 'auto',
  width: '100%',
}))
export const ListWrapper = styled(ListItem)(({ theme }) => ({
  padding: '15px 30px 15px',
}));

export const CheckBoxText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22.2px'
}));

export const SubHeading = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.80),
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16.8px',
  fontFamily: 'Bai Jamjuree'
}));

export const DoucmentBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22.4px',
  padding: '30px',
}));

export const DoucmentBtnIcon = styled('img')(({ theme }) => ({
  height: '25px',
  width: '22.5px',
  marginLeft: '5px',
}));

export const DoucmentCrossIcon = styled('img')(({ theme }) => ({
  height: '25px',
  width: '22.5px',
}));

export const DocumentHeader = styled(Box)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'space-between',
}));

export const DocumentIcon = styled(Box)(({ theme }) => ({
  paddingTop: '27px',
   marginRight: '20px'
}));

export const IconsBox = styled(Box)(() => ({
  width: '30%',
	display: 'flex',
  justifyContent: 'flex-end',
}))




