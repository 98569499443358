import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper'
import { FormButtons } from '../../FormButtons/FormButtons'
import { Box } from '@mui/material'
import { UpdateStatus } from '../UpdateStatus/UpdateStatus'
import { IFormConfigKey, IMainFormConfig } from '../../../../hook/useConfig'
import { useFormMethods } from '../../../../hook/useFormMethods'

interface IFormProps {
	configData: IMainFormConfig
	formConfigKey: IFormConfigKey
	title: string
	isFormFinished?: boolean
}
const Form: FC<IFormProps> = ({
	configData,
	formConfigKey,
	title,
	isFormFinished,
}) => {
	const { entity, handler, mutation, getConfig } = configData[formConfigKey]
	const [formData, setFormData] = useState<Partial<typeof entity>>({})
	const config = getConfig(formConfigKey)
	const { defaultValues, getDirtyKeys } = useFormMethods({ entity, config })

	const {
		handleSubmit,
		control,
		reset,
		formState: { isDirty, dirtyFields, touchedFields },
	} = useForm<Partial<typeof entity>>({
		defaultValues,
	})

	useEffect(() => {
		mutation.status === 'success' && reset(formData)
	}, [mutation.status])

	const cancel = (): void => {
		reset(defaultValues)
	}

	const onSubmit: SubmitHandler<Partial<typeof entity>> = (data): void => {
		setFormData(data)
		const formResult = getDirtyKeys(data, dirtyFields)
		handler(formResult)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{entity &&
				config.map(item => (
					<FormFieldWrapper
						key={item.label}
						inputData={item}
						control={control}
					/>
				))}
			{isFormFinished && (
				<Box sx={{ position: 'relative' }}>
					<UpdateStatus
						isTouched={Object.keys(touchedFields).length > 0}
						status={mutation.status}
						title={title}
					/>
					<FormButtons
						cancel={cancel}
						isDirty={isDirty}
						status={mutation.status}
					/>
				</Box>
			)}
		</form>
	)
}

export { Form }
