import { useLocation, useNavigate } from 'react-router-dom'
import { headerButtons } from '../../../constants/headerButtons'
import {
	ButtonComponent,
	ButtonInnerComponent,
	HeaderButton,
} from '../../../layouts/header/header.style'
import { ButtonWrapperMobile } from '../../../pages/home/home.style'
import { memo } from 'react'
import { getThemeReducer, useAppSelector } from '../../../store'

const MobileHeaderNav = memo(() => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ButtonWrapperMobile>
			<ButtonComponent>
				<ButtonInnerComponent>
					{headerButtons.map(button => {
						return (
							<HeaderButton
								key={button.title}
								variant={button.variant}
								themeMode={themeMode}
								active={pathname.includes(button.url)}
								onClick={() => navigate(button.url)}
							>
								{button.title}
							</HeaderButton>
						)
					})}
				</ButtonInnerComponent>
			</ButtonComponent>
		</ButtonWrapperMobile>
	)
})

export { MobileHeaderNav }
