import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppRootState } from "../store";

interface InitialState {
  collapsed: boolean;
}

const initialState: InitialState = {
  collapsed: false,
}

const collapseSlice = createSlice({
  name: 'collapseSlice',
  initialState,
  reducers: {
    toggleCollapsed: (state: InitialState) => {
      state.collapsed = !state.collapsed;
    },
    setCollapsed: (state: InitialState, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
    }
  }
})

const getCollapseReducer = (state: AppRootState) => state.collapseReducer
const {reducer: collapseReducer, actions: collapseActions} = collapseSlice;

export { collapseActions, collapseReducer, getCollapseReducer }