import MicroPhone from '../../../assets/microphone';
import { useRecording } from '../../../hook/useRecording';
import { IconButtonWrapper, StopAudio } from '../../../pages/home/home.style';


const AudioRecorder = () => {
  const { start, stopAndSendAudio, isRecording } = useRecording();

  return (
		<IconButtonWrapper
			onClick={!isRecording ? start : stopAndSendAudio}
			aria-label='microphone'
		>
			{!isRecording ? <MicroPhone /> : <StopAudio />}
		</IconButtonWrapper>
	)
};

export { AudioRecorder };