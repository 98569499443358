import {FC, useEffect} from 'react';
import { EndMessage, MonthOfYearWrapper } from '../../archive.style';
import { useArchive } from '../../../../hook/useArchive';
import { useLoans } from '../../../../hook/useLoans';
import { ActiveMonthItem } from '../ActiveMonthItem/ActiveMonthItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../../components/common/Loader/Loader';
import { getThemeReducer, useAppSelector } from '../../../../store';

interface IActiveMonthItemsProps {
  year: number;
  index: number;
}

const ActiveMonthItems: FC<IActiveMonthItemsProps> = ({year, index}) => {
	const { themeMode } = useAppSelector(getThemeReducer)
  const {getStartEndDates} = useArchive();
  const { start_date, end_date } = getStartEndDates(year, index);
	const { refetchLoans, sortedLoans, isLoansPending, hasLoansNextPage, fetchLoansNextPage } = useLoans({ archive: true, start_date, end_date })
  useEffect(() => {refetchLoans()}, []);

  return (
		<MonthOfYearWrapper id='month_of_year_wrapper'>
			{isLoansPending ? (
				<Loader justifyContent='flex-start' marginLeft='16px' />
			) : (
				<InfiniteScroll
					dataLength={sortedLoans.length}
					hasMore={hasLoansNextPage}
					next={fetchLoansNextPage}
					loader={<Loader justifyContent='flex-start' marginLeft='16px' />}
					scrollableTarget='month_of_year_wrapper'
					endMessage={<EndMessage sx={{ themeMode }}>No more loans</EndMessage>}
				>
					{sortedLoans.map(loan => (
						<ActiveMonthItem
							key={loan.id}
							loan={loan}
							refetchLoans={refetchLoans}
						/>
					))}
				</InfiniteScroll>
			)}
		</MonthOfYearWrapper>
	)
};

export { ActiveMonthItems };