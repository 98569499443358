import InnerSideNav from '../../layouts/innerSideBar'
import {
	MainWrapper,
	InnerSideWrapper,
	CardWrapper,
	CardWrapperMain,
} from '../account/settings.style'
import { FormWrapper } from '../../components/common/FormWrapper/FormWrapper'
import {
	getCollapseReducer,
	getThemeReducer,
	useAppSelector,
} from '../../store'
import { SettingsPageBg } from '../archive/archive.style'

const NotificationPage = () => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const { collapsed } = useAppSelector(getCollapseReducer)

	return (
		<MainWrapper>
			<InnerSideWrapper>
				<InnerSideNav />
			</InnerSideWrapper>
			<SettingsPageBg sx={{ themeMode }} collapsed={collapsed}>
				<CardWrapperMain>
					<CardWrapper sx={{ themeMode }}>
						<FormWrapper title='Notifications' />
					</CardWrapper>
				</CardWrapperMain>
			</SettingsPageBg>
		</MainWrapper>
	)
}

export default NotificationPage
