import { urls } from '../constants'
import { IUser } from '../interfaces'
import { apiService } from './api.service'

export const userService = {
	getMe: async () => {
		const { data } = await apiService.get<IUser>(urls.me)
		return data
	},
  updateMe: async (user: Partial<IUser>) => {
    const {data} = await apiService.put<IUser>(urls.me, user);
    return data; 
  }
}
