import { FC, useContext, useEffect, useState } from 'react'
import { useDocuments } from '../../../hook/useDocuments'
import { RightNavbarContext } from '../../../context'
import { useText } from '../../../hook/useText'
import { CustomActionWrapperStatus } from '../ActionWrapperStatus/CustomActionWrapperStatus'

interface ISaceStatusProps {
  isSuccess: boolean;
  isError: boolean;
}

const SaveStatus: FC<ISaceStatusProps> = ({isError, isSuccess}) => {
	const {
		checkIsTooManyDocumentsSelected,
	} = useDocuments()
	const { responseMessage } = useContext(RightNavbarContext)
	const { getActionWrapperText } = useText()
	const [message, setMessage] = useState('')

	useEffect(() => {
		if (responseMessage && (isSuccess || isError)) {
			setMessage(responseMessage)
		} else {
			setMessage(
				checkIsTooManyDocumentsSelected()
					? responseMessage
					: getActionWrapperText()
			)
		}
	}, [
		responseMessage,
		isSuccess,
		isError,
		checkIsTooManyDocumentsSelected,
		getActionWrapperText,
	])

	return (
		<CustomActionWrapperStatus
			src={
				(responseMessage && isSuccess && '/images/svgs/success-dark.svg') ||
				(responseMessage && isError && '/images/svgs/danger-circle-dark.svg') ||
				(checkIsTooManyDocumentsSelected() &&
					'/images/svgs/danger-circle-dark.svg')
			}
			isSuccess={responseMessage ? isSuccess : false}
			isError={responseMessage ? isError: false}
			message={message}
		/>
	)
}

export default SaveStatus
