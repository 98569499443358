import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { HeaderRightIcon } from "../../../layouts/header/header.style";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "../../../hook/useLogout";
import { FC } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IHeaderListProps {
	navigateToAndCloseDropDown: (link: string) => void;
}

const HeaderList: FC<IHeaderListProps> = ({navigateToAndCloseDropDown}) => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const { isAuthenticated } = useAuth0()
	const {logoutHandler} = useLogout();

	return (
		<List>
			<ListItem disablePadding>
				<ListItemButton onClick={() => navigateToAndCloseDropDown('settings')}>
					<ListItemIcon>
						<HeaderRightIcon
							src={`${
								themeMode === 'light'
									? '/images/svgs/edit.svg'
									: '/images/svgs/edit-dark.svg'
							}`}
							themeMode={themeMode}
						/>
					</ListItemIcon>
					<ListItemText
						primary='Edit Profile'
						sx={{
							'& .MuiTypography-root': {
								fontFamily: 'Bai Jamjuree',
								fontWeight: 500,
								fontSize: '16px',
							},
						}}
					/>
				</ListItemButton>
			</ListItem>
			<ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<HeaderRightIcon
							src={`${
								themeMode === 'light'
									? '/images/svgs/logout.svg'
									: '/images/svgs/logout-dark.svg'
							}`}
							themeMode={themeMode}
						/>
					</ListItemIcon>
					{isAuthenticated && (
						<ListItemText
							primary='Log Out'
							sx={{
								'& .MuiTypography-root': {
									fontFamily: 'Bai Jamjuree',
									fontWeight: 500,
									fontSize: '16px',
								},
							}}
							onClick={logoutHandler}
						/>
					)}
				</ListItemButton>
			</ListItem>
		</List>
	)
}

export { HeaderList };