import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query'

export const useQueryClientInteraction = () => {
	const queryClient = useQueryClient();

  const updateLastItemInInfinitiveQueryCache = <T extends any[]>(
    queryKey: QueryKey,
    field: keyof T[number],
    value: any,
  ) => {
    queryClient.setQueryData(
			queryKey,
			(cachedEntity: InfiniteData<T, unknown>): InfiniteData<T, unknown> => {
				cachedEntity.pages[0][0][field] = value
				return cachedEntity
			}
		)
  }

  return {
		updateLastItemInInfinitiveQueryCache,
	}
}
