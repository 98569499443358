import {FC} from 'react';
import { ListItemWrapper, MonthListWrapper } from '../../archive.style';
import { ListItemButton } from '@mui/material';
import { useArchive } from '../../../../hook/useArchive';
import { Month } from '../Month/Month';
import { ListItemIconComponent } from '../ListItemIconComponent/ListItemIconComponent';
import { getThemeReducer, useAppSelector } from '../../../../store';

interface IYearProps {
  year: number;
  index: number;
}

const Year: FC<IYearProps> = ({year, index}) => {
	const {isActiveDate, clickDateHandler, yearsAndMonths} = useArchive();
	const {themeMode} = useAppSelector(getThemeReducer)


  return (
		<>
			<ListItemWrapper sx={{ themeMode }}>
				<ListItemButton
					onClick={() => clickDateHandler(year)}
					sx={{ height: '55px' }}
				>
					<ListItemIconComponent year={year} isActiveDate={isActiveDate(year)} />
				</ListItemButton>
				{isActiveDate(year) && (
					<MonthListWrapper>
						{yearsAndMonths[index].months.map((month: string, index: number) => (
							<Month key={month} year={year} month={month} index={index} />
						))}
					</MonthListWrapper>
				)}
			</ListItemWrapper>
		</>
	)
};

export { Year };