import { FC } from "react";
import { FormGroupWrapper, ModalBodyWrapper, ModalTextField } from "../../allModals.style";
import { DislikeCheckbox } from "./DislikeCheckbox";
import { IModalWithProps } from "../../../../interfaces";
import { checkboxLabels, ICheckbox } from "./checkboxLabels";
import { getThemeReducer, useAppSelector } from "../../../../store";

const DisLikeModal: FC<IModalWithProps> = ({onChangefeedback_text, setCheckboxes}) => {
		const { themeMode } = useAppSelector(getThemeReducer)

		
		return (
			<ModalBodyWrapper sx={{ themeMode }} header={true}>
				<ModalTextField
					multiline
					placeholder='What do you like about the response?'
					minRows={10}
					onChange={onChangefeedback_text}
				/>
				<FormGroupWrapper>
					{checkboxLabels &&
						checkboxLabels.map((checkboxData: ICheckbox, index) => (
							<DislikeCheckbox
								key={index}
								checkboxData={checkboxData}
								setCheckboxes={setCheckboxes}
							/>
						))}
				</FormGroupWrapper>
			</ModalBodyWrapper>
		)
	}

export {DisLikeModal}