import {
	createContext,
	Dispatch,
	MutableRefObject,
	PropsWithChildren,
	SetStateAction,
	useRef,
	useState,
} from 'react'
import { CurrentStep, ModalKey } from '../interfaces'

interface IModalContext {
	selectedFile: File
	setSelectedFile: Dispatch<SetStateAction<File>>
	inputRef: MutableRefObject<HTMLInputElement>
	setInputRef: Dispatch<SetStateAction<MutableRefObject<HTMLInputElement>>>
	step: CurrentStep
	setStep: Dispatch<SetStateAction<CurrentStep>>
	isModalOpen: boolean
	setIsModalOpen: Dispatch<SetStateAction<boolean>>
	modalKey: ModalKey
	setModalKey: Dispatch<SetStateAction<ModalKey>>
	openModal: (modalKey: ModalKey) => void
	closeModal: () => void
	submitRef: MutableRefObject<HTMLButtonElement>
	document_id: number;
	setDocumentId: Dispatch<SetStateAction<number>>;
}

export const ModalContext = createContext<IModalContext>(null)

export const ModalProvider = ({ children }: PropsWithChildren) => {
	const [selectedFile, setSelectedFile] = useState<File>(null)
	const [inputRef, setInputRef] =
		useState<MutableRefObject<HTMLInputElement>>(null)
	const [step, setStep] = useState<CurrentStep>(CurrentStep.STEP1)
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [modalKey, setModalKey] = useState<ModalKey>(null)
	const submitRef = useRef<HTMLButtonElement>(null);
	const [document_id, setDocumentId] = useState<number>(null);

	const openModal = (modalKey: ModalKey): void => {
		setIsModalOpen(true)
		setModalKey(modalKey)
	}

	const closeModal = (): void => {
		setModalKey(null);
		setIsModalOpen(false)
		setSelectedFile(null)
		setDocumentId(null);
		setStep(CurrentStep.STEP1)
	}

	const value = {
		selectedFile,
		setSelectedFile,
		inputRef,
		setInputRef,
		step,
		setStep,
		isModalOpen,
		setIsModalOpen,
		modalKey,
		setModalKey,
		openModal,
		closeModal,
		submitRef,
		document_id,
		setDocumentId
	}

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
