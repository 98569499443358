import {FC} from 'react';
import { FontComponent, HoveredElement, SideNavIconComponent, SideNavIconImageComponent, SimpleDivider } from './sidebar.style';
import { useHeader } from '../../hook/useHeader';
import { getThemeReducer, useAppSelector } from '../../store';

interface INavItemProps {
  link: string;
  src: string;
  label: string;
  needDivider: boolean;
}

const NavItemMobile: FC<INavItemProps> = ({
	link,
	src,
	label,
	needDivider,
}) => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const { onNavItemClick } = useHeader()

	return (
		<>
			<SideNavIconComponent onClick={() => onNavItemClick(label, link)}>
				<HoveredElement themeMode={themeMode}>
					<SideNavIconImageComponent src={src} />
					<FontComponent>{label}</FontComponent>
				</HoveredElement>
			</SideNavIconComponent>
			{needDivider && <SimpleDivider />}
		</>
	)
}

export { NavItemMobile };