import { List } from '@mui/material'
import { MainBox } from './listItems.style'
import { useDocuments } from '../../../../hook/useDocuments'
import { Category } from '../Category/Category'
import { Loader } from '../../../../components/common/Loader/Loader'

const ListItems = () => {
	const {
		isActiveCategory,
		toggleCategoryBox,
		documentCategories,
		isDocumentsPending,
	} = useDocuments();
	return (
		
		<MainBox>
			<List>
				{isDocumentsPending ? (
					<Loader />
				) : (
					documentCategories &&
					documentCategories.map((category: string, index: number) => (
					<Category 
						key={category} 
						category={category} 
						isActiveCategory={isActiveCategory(category)}
						toggleCategoryBox={toggleCategoryBox}
						 /> 
					)
					))}
			</List>
		</MainBox>
	)
}

export default ListItems
