import { IHeaderButton } from "../interfaces/headerButtons.interface";

export const headerButtons: IHeaderButton[] = [
	{
		title: 'LOANS',
		variant: 'contained',
		url: '/loans',
		ref: true,
	},
	{
		title: 'FNMA',
		variant: 'contained',
		url: '/fnma',
		ref: false,
	},
	{
		title: 'FHLMC',
		variant: 'contained',
		url: '/fhlmc',
		ref: false,
	},
	{
		title: 'FHA',
		variant: 'contained',
		url: '/fha',
		ref: false,
	},
]