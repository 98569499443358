import {FC} from 'react';
import { BillingItem } from './billingConstants';
import { BillingModalActionBtn, BillingModalItem, BillingModalItemSubtile, BillingModalItemTitle } from './Billing.style';
import { BillingListItem } from './BillingListItem';
import { getThemeReducer, useAppSelector } from '../../../store';

interface IBillingModalItemComponentProps {
	billingItem: BillingItem;
}

const BillingModalItemComponent: FC<IBillingModalItemComponentProps> = ({
	billingItem,
}) => {
  const {title, subTitle, items, buttonText, buttonType} = billingItem;
  const {themeMode} = useAppSelector(getThemeReducer);
  
  const onClickHandler = (): void => {
    window.open('mailto:info@powerunderwriter.com', '_blank');
  }

	return (
		<BillingModalItem sx={{ themeMode }}>
			<BillingModalItemSubtile>{subTitle}</BillingModalItemSubtile>
			<BillingModalItemTitle>{title}</BillingModalItemTitle>
			{items &&
				items.map(item => <BillingListItem key={title} listItem={item} />)}
			<BillingModalActionBtn onClick={onClickHandler} sx={{themeMode}} buttonType={buttonType}>{buttonText}</BillingModalActionBtn>
		</BillingModalItem>
	)
}

export { BillingModalItemComponent };