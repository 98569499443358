import { Box, Typography } from "@mui/material";
import { useActiveList } from "../../../../hook/useActiveList";

const MobileHeaderLoanInfo = () => {
	const { getActiveLoan } = useActiveList();
  const activeLoan = getActiveLoan();
  
  return (
		<>
			{!activeLoan ? (
				''
			) : (
				<Box
					sx={{
						height: '84px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<Typography
						sx={{
							fontSize: '16px',
							fontFamily: 'Roboto Slab',
							fontWeight: 500,
							lineHeight: '22.4px',
						}}
					>
						{activeLoan.file_name}
					</Typography>
					<Typography
						sx={{
							fontSize: '12px',
							fontFamily: 'Bai Jamjuree',
							fontWeight: 700,
							lineHeight: '14.4px',
							opacity: '0.8',
						}}
					>
						{activeLoan.loan_number}
					</Typography>
				</Box>
			)}
		</>
	)
};

export { MobileHeaderLoanInfo };