import { marked } from "marked";
import { documentConstants } from "../constants";
import { useDocuments } from "./useDocuments"

export const useText = () => {
  const { documents, getAmountOfSelectedDocumentsInGeneral } = useDocuments();

	const getActionWrapperText = () => {
    const selectedDocuments = getAmountOfSelectedDocumentsInGeneral();
    if(!selectedDocuments) {
      return 'No documents are selected'
    }

    if(selectedDocuments > documentConstants.selectedHighWaterMark) {
      return 'Too many documents are selected. Please remove and try again'
    }

    return `Selected ${selectedDocuments} of ${documents.length} Documents`;
  }

  	const parseMarkdown = (text: string) => {
      return marked.parse(text);
		}


  return {
		getActionWrapperText,
		parseMarkdown,
	}
}