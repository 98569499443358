export enum feedback_type {
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down',
}
export interface IFeedbackRequest {
	created_at: string;
	feedback_type: feedback_type;
	feedback_text: string;
	not_true?: boolean; 
	harmful?: boolean;
	not_helpful?: boolean;
}

export interface IFeedbackResponse extends IFeedbackRequest {
  id: number;
  loan_id: number;
  message_id: number;
}