import { useState, useEffect, useContext } from 'react'
import SideBar from './sidebar'
import SideBarMobile from './sidebar/sidebarMobile'
import Header from './header'
import { Outlet } from 'react-router'
import {
	InnerHeaderWrapper,
	SideBarWrapper,
	OutletWrapper,
	MainWrapper,
} from './sidebar/sidebar.style'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { breakpointConstants } from '../constants'
import { useUsers } from '../hook/useUsers'
import { createGlobalStyle, DefaultTheme } from 'styled-components'
import { collapseActions, getCollapseReducer, getThemeReducer, useAppDispatch, useAppSelector } from '../store'
import { useTheme } from '../hook/useTheme'
import { useBilling } from '../hook/useBilling'
import { useLoans } from '../hook/useLoans'
import { ActiveListContext, ModalContext } from '../context'
import { ModalWrapper } from '../components/allModals/upload/ModalWrapper'
import {useLocation} from 'react-router-dom'
import { settingsDummyData } from '../dummyData'
import { useTitle } from '../hook/useTitle'


function DashboardLayout() {
	const [mobileScreen, setmobileScreen] = useState(false)
	const {changeThemeModeAndFavicon} = useTheme();
	const { user } = useUsers()
	const {collapsed} = useAppSelector(getCollapseReducer);
	const {themeMode} = useAppSelector(getThemeReducer);
	const dispatch = useAppDispatch();
	const {setCollapsed} = collapseActions;
	const { openBillingModalIfNeeded, billingOptions } = useBilling()
	const {isPathnameIsLoans} = useLoans();
	const {modalKey} = useContext(ModalContext);
	const {pathname} = useLocation();
	const {setActiveList} = useContext(ActiveListContext);
	useTitle();

	const {autoCollapse: {leftNavbar}} = breakpointConstants;

	useEffect(() => {
		if (user) {
			changeThemeModeAndFavicon(user.theme_preference)
		}
	}, [user])

	const collapseNavBar = (): void => {
		if (!collapsed && window.innerWidth <= leftNavbar) {
			dispatch(setCollapsed(true));
			window.removeEventListener('resize', collapseNavBar)
		}
	}

	const expandNavBar = (): void => {
		if (collapsed && window.innerWidth > leftNavbar) {
			dispatch(setCollapsed(false));
			window.removeEventListener('resize', expandNavBar)
		}
	}

	useEffect(() => {
		dispatch(setCollapsed(window.innerWidth < leftNavbar))
	}, [])

	useEffect(() => {
		if(!isPathnameIsLoans) {
			const activeList = settingsDummyData.settings.find(item => item.url === pathname);
			if(activeList) {
				setActiveList(activeList?.title)
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener('resize', collapseNavBar);
		window.addEventListener('resize', expandNavBar);
	}, [collapsed]);

	useEffect(() => {
		const handleResize = () => {
			setmobileScreen(window.innerWidth <= 600)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (!billingOptions.isLoading) {
			openBillingModalIfNeeded()
		}
	}, [billingOptions.isLoading])

	const theme: DefaultTheme = {
		mode: themeMode
	}

	const GlobalStyles = createGlobalStyle`
  &::-webkit-scrollbar-thumb {
    background-color: ${props =>
			props.theme.mode === 'dark' ? '#465359' : '#F7F6F4'};
  }
`

	return (
		<>
			<GlobalStyles theme={theme} />
			{mobileScreen ? (
				<MainWrapper sx={{ themeMode }}>
					<Header />
					<SideBarWrapper>
						{/* @ts-ignore */}
						<SideBarMobile />
					</SideBarWrapper>
					<OutletWrapper>
						<Outlet />
					</OutletWrapper>
				</MainWrapper>
			) : (
				<MainWrapper sx={{ themeMode }}>
					<InnerHeaderWrapper>
						<Header />
					</InnerHeaderWrapper>
					<SideBarWrapper>
						<SideBar />
						<OutletWrapper>
							<Outlet />
						</OutletWrapper>
					</SideBarWrapper>
				</MainWrapper>
			)}
			<ModalWrapper
				width='552px'
				open={modalKey === 'powerUp'}
				isActionButtonDisabled={true}
			/>
			<ModalWrapper width='780px' open={modalKey === 'billing'} />
		</>
	)
}

export default withAuthenticationRequired(DashboardLayout);
