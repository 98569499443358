import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { IStyle } from '../../../interfaces';

export const BillingModalTitle = styled(Typography)(() => ({
	fontSize: '30px',
	fontWeight: 600,
	fontFamily: 'Roboto Slab',
  marginBottom: '20px',
	width: '75%',
	marginInline: 'auto',
	textAlign: 'center',
}))

export const BillingModalSubTitle = styled(Typography)(() => ({
	fontSize: '14px',
	fontWeight: 500,
	fontFamily: 'Bai Jamjuree',
	textAlign: 'center',
	width: '75%',
	marginInline: 'auto',
}))

export const BillingModalFlexContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  marginTop: '40px',
	paddingInline: '10px',
}));

export const BillingModalItem = styled(Box)(({sx: {themeMode}}: IStyle) => ({
  padding: '25px 20px',
  border: `1px solid ${themeMode === 'dark' ? 'white' : '#03363D'}`,
  borderRadius: '10px',
}));

export const BillingModalItemSubtile = styled(Typography)(() => ({
	fontSize: '28px',
	fontWeight: 400,
	fontFamily: 'Bai Jamjuree',
}))

export const BillingModalItemTitle = styled(Typography)(() => ({
	fontSize: '30px',
	fontWeight: 600,
	fontFamily: 'Roboto Slab',
	marginBlock: '10px 20px',
}))

export const BillingModalListItem = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  marginBottom: '20px',
	alignItems: 'flex-start',
}))

export const BillingListItemIcon = styled('img')(() => ({
  width: '18px',
  height: '18px',
	marginTop: '3px',
}));

export const BillingListItemText = styled(Typography)(() => ({
	fontSize: '16px',
	fontWeight: 400,
	fontFamily: 'Bai Jamjuree',
}))

export const BillingModalActionBtn = styled(Button)(
	({ theme, buttonType, sx: { themeMode } }: IStyle) => ({
		textTransform: 'capitalize',
		color: `${
			buttonType === 'cancel'
				? theme.palette.text.primary
				: theme.palette.text.white
		}`,
		background: `${
			buttonType === 'action'
				? theme.palette.primary.light
				: themeMode === 'light'
				? theme.palette.background.default
				: '#465359'
		}`,
		fontSize: '20px',
		fontWeight: 500,
		lineHeight: '28px',
		height: '56px',
		width: '100%',
		marginTop: '10px',
		textAlign: 'center',
		borderRadius: '10px',
		transition: 'box-shadow 0.3s ease',
		'&:hover': {
			background: `${
				buttonType === 'action'
					? theme.palette.primary.light
					: themeMode === 'light'
					? theme.palette.background.default
					: '#465359'
			}`,
			boxShadow: `0px 8px 12px ${
				themeMode === 'light'
					? 'rgba(0, 0, 0, 0.2)'
					: 'rgba(255, 255, 255, 0.05)'
			}, 0px 2px 6px ${
				themeMode === 'light'
					? 'rgba(0, 0, 0, 0.2)'
					: 'rgba(255, 255, 255, 0.05)'
			}`,
		},
	})
)