import { FeedbackIconWrapperComponent } from "../../../pages/home/home.style";
import { getThemeReducer, useAppSelector } from "../../../store";
import { FeedbackSuccessIcon, ModalBodyWrapper, UploadTextComplete, UploadWrapper } from "../allModals.style";

const FeedbackSuccess = () => {
  const { themeMode } = useAppSelector(getThemeReducer)

  return (
		<ModalBodyWrapper sx={{ themeMode }}>
			<UploadWrapper>
				<FeedbackIconWrapperComponent
					sx={{
						themeMode,
						width: '80px',
						height: '80px',
						borderRadius: '16px',
					}}
				>
					<FeedbackSuccessIcon
						src={`${
							themeMode === 'dark'
								? '/images/svgs/like-dark.svg'
								: '/images/svgs/like-black.svg'
						}`}
					/>
				</FeedbackIconWrapperComponent>
			</UploadWrapper>
			<UploadTextComplete width='80%' marginTop='0px'>
				Thank you for your feedback
			</UploadTextComplete>
		</ModalBodyWrapper>
	)
};

export { FeedbackSuccess };