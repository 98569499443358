import { useMutation } from "@tanstack/react-query"
import { analysesService } from "../services"
import { useParams } from "react-router-dom"
import { useMessages } from "./useMessages";

export const useAnalyses = () => {
  const {loanId} = useParams();
  const {refetchMessages} = useMessages();

  const askAnalysesMutation = useMutation({
    mutationFn: () => analysesService.askAnalyses(+loanId),
    onSuccess: (data) => {
      refetchMessages();
    },
    onError: (e) => {
      console.error(e);
    }
  })

  return {askAnalysesMutation}
}