import { createSlice } from '@reduxjs/toolkit'
import { AppRootState } from '../store'

interface InitialState {
	isRightNavBarDrawerOpen: boolean
	isLeftNavBarDrawerOpen: boolean
	isMobileDrawerOpen: boolean
}

const initialState: InitialState = {
	isRightNavBarDrawerOpen: false,
	isLeftNavBarDrawerOpen: false,
  isMobileDrawerOpen: false,
}

const drawerSlice = createSlice({
	name: 'drawerSlice',
	initialState,
	reducers: {
		toggleIsRightDrawerOpen: (state: InitialState) => {
			state.isRightNavBarDrawerOpen = !state.isRightNavBarDrawerOpen
		},
		toggleIsLeftDrawerOpen: (state: InitialState) => {
			state.isLeftNavBarDrawerOpen = !state.isLeftNavBarDrawerOpen
		},
		toggleIsMobileDrawerOpen: (state: InitialState) => {
			state.isMobileDrawerOpen = !state.isMobileDrawerOpen
		},
	},
})

const getDrawerReducer = (state: AppRootState) => state.drawerReducer
const { reducer: drawerReducer, actions: drawerActions } = drawerSlice

export { drawerReducer, drawerActions, getDrawerReducer}
