import { FC } from "react";
import { ModalBodyWrapper, UploadIcon, UploadTextComplete, UploadWrapper } from "../allModals.style";
import { IModalWithProps } from "../../../interfaces";
import { getThemeReducer, useAppSelector } from "../../../store";

const OperationSuccess: FC<IModalWithProps> = ({successStateText}) => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
			<ModalBodyWrapper sx={{ themeMode }}>
				<UploadWrapper marginBottom='0px'>
					<UploadIcon
						src={
							themeMode === 'light'
								? '/images/svgs/success-light.svg'
								: '/images/svgs/success-dark.svg'
						}
					/>
				</UploadWrapper>
				<UploadTextComplete width='80%'>{successStateText}</UploadTextComplete>
			</ModalBodyWrapper>
	)
}
export { OperationSuccess }
