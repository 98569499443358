import { urls } from '../constants'
import { ILoan, ILoanParams } from '../interfaces'
import { apiService } from './api.service'

export const loanService = {
	getPage: async (params?: ILoanParams) => {
		const { data } = await apiService.get<ILoan[]>(urls.loans, { params })
		return data;
	},
	uploadNewLoan: async (file: FormData) => {
		const { data } = await apiService.post(urls.newLoan, file, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		return data
	},
	updateLoanStatus: async (loanId: number, updatingFields: Partial<ILoan>) => {
		const { data } = await apiService.put<ILoan>(
			urls.loanById(loanId),
			updatingFields
		)
		return data
	},
}
