import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../enum'
import { messageService } from '../services'
import { useActiveList } from './useActiveList'
import { CurrentStep, IChatTimeline, ICreateMessage } from '../interfaces'
import { useLoans } from './useLoans'
import { MutableRefObject, useCallback, useContext } from 'react'
import { ChatContext, ModalContext } from '../context'
import { useQueryClientInteraction } from './useQueryClientInteraction'
import { useWrapper } from './useWrapper'
import { chatConstants } from '../constants'
import { IFeedbackRequest } from '../interfaces/feedback.interface'

export const useMessages = () => {
	const { getActiveLoan } = useActiveList()
  const {isPathnameIsLoans} = useLoans();
	const queryClient = useQueryClient();
	const {setTriggerNewMessage, messageId, setSpeechTranslation, setIsTypingEffectInProgress} = useContext(ChatContext);
	const { updateLastItemInInfinitiveQueryCache } = useQueryClientInteraction()
	const { scrollDown } = useWrapper()
	const {setStep} = useContext(ModalContext);

	const handleIsTypingEffectInProgress = useCallback((val: boolean) => {setIsTypingEffectInProgress(val)}, []); 

	const activeLoanId = getActiveLoan()?.id;
	const {limit} = chatConstants;
	
	const {
		data: messagesData,
		hasNextPage: hasMessagesNextPage,
		fetchNextPage: fetchNextMessagesPage,
		isFetchingNextPage: isMessagesFetchingNextPage,
		isLoading: isMessagesLoading,
		isPending: isMessagesPending,
		isFetching: isMessagesFetching,
		refetch: refetchMessages,
	} = useInfiniteQuery<IChatTimeline[], Error>({
		queryKey: [QueryKey.MESSAGES, activeLoanId],
		queryFn: ({ pageParam, signal }) =>
			messageService.getChatTimeline(activeLoanId, pageParam as number, signal),
		getNextPageParam: (
			lastPage: IChatTimeline[],
			allPages: IChatTimeline[][],
			lastPageParam: unknown
		) => {
				if (lastPage.length === limit) {
				return (lastPageParam as number) + 1
			}
			return null
		},
		initialPageParam: 0,
		enabled: isPathnameIsLoans && getActiveLoan() != null,
		staleTime: Infinity,
	})

	const messages = messagesData?.pages.flatMap(page => page) || [];

	const {mutate: sendMessageMutation, isPending: isSendingMessagePending} = useMutation({
		mutationFn: (message: ICreateMessage) => messageService.sendMessage(getActiveLoan().id, message),
		onSuccess: async (data) => {
			updateNewSystemMessageLocally(data);
			setSpeechTranslation('');
			setTriggerNewMessage(prev => !prev);
		},
		onError: (e) => {
			setIsTypingEffectInProgress(false);
			console.error(e);
		}
	});

	const sendFeedbackMutation = useMutation({
		mutationFn: (feedback: IFeedbackRequest) => messageService.sendFeedback(activeLoanId, messageId, feedback),
		onSuccess: () => {
			setStep(CurrentStep.STEP3);
		},
		onError: (e) => {
			console.error(e)
			setStep(CurrentStep.STEP4);
		}
	});

	const speechToTextMutation = useMutation({
		mutationFn: (file: FormData) => messageService.speechToText(file),
		onSuccess: (data) => {
			setSpeechTranslation(data.translation);
		},
		onError: (e) => {
			console.error(e);
		}
	});

	const sendFeedbackHandler = (feedBack: IFeedbackRequest): void => {
		sendFeedbackMutation.mutate(feedBack);
		setStep(CurrentStep.STEP2);
	}

	const insertNewMessagesLocally = (message: string): void => {
		queryClient.setQueryData(
			[QueryKey.MESSAGES, activeLoanId],
			(
				cachedMessages: InfiniteData<IChatTimeline[], unknown>
			): InfiniteData<IChatTimeline[], unknown> => {
				const firstPage = cachedMessages.pages[0];

				const newUserMessage: IChatTimeline = {
					id: firstPage[0]?.id + 1 || Math.floor(Math.random() * 10000),
					timestamp: new Date().toISOString(),
					type: 'message',
					user: 'user',
					user_id: null,
					message_text: message,
					thumbs_up: false,
					thumbs_down: false,
				}

				const newSystemMessage: IChatTimeline = {
					id: firstPage[0]?.id + 2 || newUserMessage.id + 1,
					timestamp: new Date().toISOString(),
					type: 'message',
					user: 'system',
					user_id: null,
					message_text: '',
					thumbs_up: false,
					thumbs_down: false,
					shouldUseTypingEffect: true,
					isAnimationFinished: false,
				}

				firstPage.unshift(newSystemMessage, newUserMessage)
				return cachedMessages;
			}
		)
	}

	const sendMessageHandler = (
		ref: MutableRefObject<HTMLTextAreaElement>
	): void => {
		const message = ref.current.value.trim();
		if(!message) return;
		insertNewMessagesLocally(message);
		setTriggerNewMessage(prev => !prev);
		scrollDown('instant' as ScrollBehavior);
		ref.current.value = '';
		sendMessageMutation({ message_text: message });
	}
	
	const isSendingButtonDisabled = isSendingMessagePending || isMessagesLoading;

	const shouldUseTypingEffect = (index: number): boolean =>
		messages[index]?.shouldUseTypingEffect &&
		!messages[index]?.isAnimationFinished &&
		index === 0;

	const updateNewSystemMessageLocally = (message_text: string): void => {
		updateLastItemInInfinitiveQueryCache<IChatTimeline[]>([
			QueryKey.MESSAGES,
			activeLoanId,
		],
		'message_text',
		message_text
		)
	} 

  return {
		sortedMessages: messages,
		refetchMessages,
		isMessagesLoading,
		isMessagesPending,
		sendMessageHandler,
		isSendingMessagePending,
		sendMessageMutation,
		isSendingButtonDisabled,
		shouldUseTypingEffect,
		isMessagesFetching,
		isMessagesFetchingNextPage,
		hasMessagesNextPage,
		fetchNextMessagesPage,
		sendFeedbackHandler,
		speechToTextMutation,
		handleIsTypingEffectInProgress,
	}
}
