import { FC, InputHTMLAttributes, useContext, useEffect, useRef } from 'react'
import { ModalContext } from '../../../../context'
import { useModal } from '../../../../hook/useModal'

interface IHiddenFileProps extends InputHTMLAttributes<HTMLInputElement> {
}

const HiddenInput: FC<IHiddenFileProps> = ({type, accept}) => {
	const { setInputRef } = useContext(ModalContext)
	const inputRef = useRef<HTMLInputElement>(null);
	const {handleFileChange} = useModal();

	useEffect(() => {
		inputRef && setInputRef(inputRef);
	}, [inputRef, setInputRef])


	return (
		<input
			type={type}
			style={{ display: 'none' }}
			ref={inputRef}
			accept={accept}
			onChange={handleFileChange}
		/>
	)
}

export { HiddenInput }
