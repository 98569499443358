import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { IStyle } from "../../../../interfaces";

export const UserMessageAvatar = styled(Box)(
	({ themeMode }: { themeMode: 'light' | 'dark' }) => ({
		width: '40px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		background: `${themeMode === 'light' ? '#03363D' : '#E3F4F2'}`,
		opacity: 0.4,
		color: `${themeMode === 'light' ? '#E3F4F2' : '#03363D'}`,
		fontWeight: 600,
		fontSize: '18px',
	})
);

export const SingleMessageWrapper = styled(Box)(({theme}: IStyle) => ({
	width: '100%',
	display: 'flex',
	gap: '15px',
	marginBlock: '10px',
	boxSizing: 'border-box',
	paddingLeft: '5px',
}));

export const MessageMarginSetter = styled(Box)(({theme}: IStyle) => ({
	marginBlock: '9px',
}));