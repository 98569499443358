import { Document, Page } from "@react-pdf/renderer";

const MyDocument = () => {
  return (
		<Document>
			<Page size='A4' />
		</Document>
	)
};

export { MyDocument };