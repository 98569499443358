import { getThemeReducer, useAppSelector } from '../../../../../store';
import {
	ModalBodyWrapper,
	UploadIcon,
	UploadTextComplete,
	UploadWrapper,
	ModalSubText,
} from '../../../allModals.style'

const UploadSuccess = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<>
			{/* @ts-ignore */}
			<ModalBodyWrapper header={true} sx={{ themeMode }}>
				<UploadWrapper>
					<UploadIcon src='/images/svgs/uploadSuccess.svg' />
				</UploadWrapper>
				<UploadTextComplete>File Has uploaded</UploadTextComplete>
				<ModalSubText>
					The upload was successful. you can now close this window
				</ModalSubText>
			</ModalBodyWrapper>
		</>
	)
}
export { UploadSuccess }
