import {FC} from 'react';
import { ListItemMonths } from '../../archive.style';
import { Box, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowIcon } from '../../../account/settings.style';
import { useArchive } from '../../../../hook/useArchive';
import { ActiveMonthItems } from '../ActiveMonthItems/ActiveMonthItems';
import { getThemeReducer, useAppSelector } from '../../../../store';

interface IMonthProps {
	year: number;
  month: string;
	index: number;
}

const Month: FC<IMonthProps> = ({year, month, index}) => {
 const { clickDateHandler, isActiveDate } =
		useArchive()
	const {themeMode} = useAppSelector(getThemeReducer)

  return (
		<>
			<ListItemMonths onClick={() => clickDateHandler(month)}>
				<Box sx={{flex: '0 0 150px'}}>
					<ListItemText>{month}</ListItemText>
				</Box>
					<ListItemIcon>
						<ArrowIcon
							src={`/images/svgs/arrow-right-${themeMode}.svg`}
							width='16'
							height='16'
							sx={{paddingTop: '3px', rotate: isActiveDate(month) && 'rotate(90deg)'}}
						/>
					</ListItemIcon>
			</ListItemMonths>
			{isActiveDate(month) && <ActiveMonthItems year={year} index={index} />}
		</>
	)
};

export { Month };