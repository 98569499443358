import { CardWrapper, CardWrapperMain } from './settings.style'
import { FormWrapper } from '../../components/common/FormWrapper/FormWrapper'
import { useActiveList } from '../../hook/useActiveList'
import { useEffect } from 'react'
import {
	getCollapseReducer,
	getThemeReducer,
	useAppSelector,
} from '../../store'
import { SettingsPageBg } from '../archive/archive.style'

const Account = () => {
	const { updateActiveList } = useActiveList()
	const { themeMode } = useAppSelector(getThemeReducer)
	const { collapsed } = useAppSelector(getCollapseReducer)

	useEffect(() => {
		updateActiveList()
	}, [])

	return (
		<SettingsPageBg sx={{ themeMode }} collapsed={collapsed}>
			<CardWrapperMain>
				<CardWrapper sx={{ themeMode }}>
					<FormWrapper
						title='Account'
						formConfigKey='user'
						isFormFinished={true}
					/>
				</CardWrapper>
			</CardWrapperMain>
		</SettingsPageBg>
	)
}
export { Account }
