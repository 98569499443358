import { getThemeReducer, useAppSelector } from '../../../../../store'
import {
	ModalBodyWrapper,
	UploadIcon,
	UploadTextComplete,
	UploadWrapper,
} from '../../../allModals.style'

const UploadDocumentSuccess = () => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<>
			{/* @ts-ignore */}
			<ModalBodyWrapper sx={{ themeMode }}>
				<UploadWrapper marginBottom='0px'>
					<UploadIcon
						src={
							themeMode === 'light'
								? '/images/svgs/success-light.svg'
								: '/images/svgs/success-dark.svg'
						}
					/>
				</UploadWrapper>
				<UploadTextComplete width='80%'>
					The File Has Been Uploaded Successfully.
				</UploadTextComplete>
			</ModalBodyWrapper>
		</>
	)
}
export { UploadDocumentSuccess }
