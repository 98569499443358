import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppRootState } from '../store';

interface InitialState {
	themeMode: 'light' | 'dark';
}

const initialState: InitialState = {
	themeMode: 'dark',
}

const themeSlice = createSlice({
	name: 'themeSlice',
	initialState,
	reducers: {
		toggleTheme: (state: InitialState) => {
			state.themeMode = state.themeMode === 'dark' ? 'light' : 'dark';
		},
		setTheme: (state: InitialState, action: PayloadAction<'light' | 'dark'>) => {
			state.themeMode = action.payload
		},
	},
})

const getThemeReducer = (state: AppRootState) => state.themeReducer;

const { reducer: themeReducer, actions: themeActions } = themeSlice

export { themeReducer, themeActions, getThemeReducer }
