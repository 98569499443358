import { urls } from '../constants'
import { IBulkUpdateResponse, IDocument, IDocumentParams, IUpdatedDocument, IUploadDocumentResponse } from '../interfaces'
import { apiService } from './api.service'

export const documentService = {
	getByLoanId: async (loanId: number, signal: AbortSignal, params?: IDocumentParams) => {
		const { data } = await apiService.get<IDocument[]>(urls.documents(loanId), {
			params,
			signal
		})
		return data
	},
	bulkUpdate: (loanId: number, body: IUpdatedDocument[]) =>
		apiService.put<IBulkUpdateResponse>(urls.bulkUpdate(loanId), body),
	uploadNewDocument: async (loanId: number, document: FormData) => {
		const { data } = await apiService.post<IUploadDocumentResponse>(
			urls.uploadNewDocument(loanId),
			document,
			{ headers: { 'Content-Type': 'multipart/form-data' } }
		);
		return data;
	},
	updateDocument: async (loanId: number, document_id: number, updatedFields: Partial<IDocument>) => {
		const {data} = await apiService.put<IDocument>(urls.updateDocument(loanId, document_id), updatedFields);
		return data;
	},
	docStream: async (variables: {loanId: number, document_id: number}) => {
		const {loanId, document_id} = variables;
		return apiService.get<string>(urls.docStream(loanId, document_id), {responseType: 'blob'});
	}
}
