import styled, { keyframes } from "styled-components";

export enum Animation {
  JUMP = 'jump',
  ROTATE = 'rotate'
}

export interface IconWrapperProps {
  animation: Animation;
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const jump = keyframes`
   0%, 50% {
        transform: scale(1);
    }
    25%, 75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`

export const IconWrapper = styled.div<IconWrapperProps>`
	animation: ${props => (props.animation === Animation.JUMP ? jump : rotate)}
		${props => (props.animation === Animation.JUMP ? '2s' : '0.6s')} linear
		infinite;
	display: flex;
	align-items: center;
	justify-content: center;
	position: 'fixed';
`