import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { QueryKey } from "../enum"
import { useActiveList } from "./useActiveList"
import { useLoans } from "./useLoans"
import { documentService } from "../services"
import { useContext, useMemo, useState } from "react"
import { CurrentStep, IDocument, IUpdatedDocument } from "../interfaces"
import { documentConstants } from "../constants"
import { ModalContext, RightNavbarContext } from "../context"
import { useMessages } from "./useMessages"

export const useDocuments = () => {
  const { getActiveLoan } = useActiveList();
	const { isPathnameIsLoans } = useLoans();
	const {refetchMessages} = useMessages();
	const [activeCategory, setActiveCategory] = useState<string>('');
	const queryClient = useQueryClient();
	const {setChangedDocuments, changedDocuments, setResponseMessage, responseMessage} = useContext(RightNavbarContext);
	const {setStep, document_id} = useContext(ModalContext);

	const activeLoanId = getActiveLoan()?.id;	

  const {
		data: documents,
		refetch: refetchDocuments,
		isLoading: isDocumentsLoading,
		isPending: isDocumentsPending,
		isFetching: isDocumentsFetching,
	} = useQuery({
		queryKey: [QueryKey.DOCUMENTS, activeLoanId],
		queryFn: ({signal}) => documentService.getByLoanId(activeLoanId, signal, {status: 'finished', deleted: false}),
		enabled: isPathnameIsLoans && getActiveLoan() != null,
		staleTime: Infinity,
	});
	
	const documentsBulkUpdateMutation = useMutation({
		mutationFn: (changedDocs: IUpdatedDocument[]) => {
			return documentService.bulkUpdate(activeLoanId, changedDocs)
	},
		onSuccess: async (data) => {
			setResponseMessage(data.data.message);
			setChangedDocuments([]);
			await Promise.all([refetchDocuments(), refetchMessages()])
		},
		onError: () => {
			setResponseMessage(documentConstants.bulkUpdateErrorMessage)
		}
});

	const uploadNewDocumentMutation = useMutation({
		mutationFn: (document: FormData) => {
			return documentService.uploadNewDocument(activeLoanId, document);
		},
		onSuccess: () => {
				setStep(CurrentStep.STEP3)
				refetchDocuments();
				refetchMessages();
			},
			onError: e => {
				setStep(CurrentStep.STEP4)
				console.error(e)
	}});

	const updateDocumentMutation = useMutation({
		mutationFn: (updatedFields: Partial<IDocument>) => documentService.updateDocument(activeLoanId, document_id, updatedFields),
		onSuccess: () => {
			refetchDocuments();
			setStep(CurrentStep.STEP4);
		},
		onError: (e) => {
			console.error(e);
			setStep(CurrentStep.STEP3);
		}
	})

	const updateDocumentHandler = (
		updatedFields: Partial<IDocument>
	): void => {
		updateDocumentMutation.mutate(updatedFields)
		setStep(CurrentStep.STEP2)
	}

		
	const sortedDocuments: Record<string, IDocument[]> = useMemo(() => {
		if (!documents) {
			return null
		}
		return documents.reduce(
			(acc: Record<string, IDocument[]>, document: IDocument) => {
				if (!acc[document.type]) {
					acc[document.type] = []
				}
				acc[document.type].push(document)
				return acc
			},
			{}
		)
	}, [documents])

	const onActiveChange = (id: number, isActive: boolean): void => {
		queryClient.setQueryData(
			[QueryKey.DOCUMENTS, activeLoanId],
			(cachedDocs: IDocument[]): IDocument[] => {
				const updatedDocs = cachedDocs.map(doc => {
					if (doc.id === id) {
						updateChangedDocumentsArray(doc, isActive, id);
						return { ...doc, active: isActive }
					}
					return doc
				})
				return updatedDocs 
			}
		)
}

	const updateChangedDocumentsArray = (doc: IDocument, isActive: boolean, id: number): void => {
		let updatedDocumentsArray: IDocument[];
		if(responseMessage) {
			setResponseMessage('');
		}
		const changedDoc = { ...doc, active: isActive };
		const isDocumentAlreadyInChangedArray = !!changedDocuments.find(
			doc => doc.id === changedDoc.id
		);
		if (isDocumentAlreadyInChangedArray) {
			updatedDocumentsArray = changedDocuments.filter(
				doc => doc.id !== changedDoc.id
			)
		} else {
			updatedDocumentsArray = [
				...changedDocuments.filter(doc => doc.id !== id),
				changedDoc,
			]
		}
		setChangedDocuments(updatedDocumentsArray) 
	}

	const parseBorrowers = (borrowers: string[]) => {
		return borrowers.map(b => {
			const borrowers = JSON.parse(b).borrowers
			return borrowers ? borrowers.join(', ') : ''
		}).join(', ');
	}

	const isActiveCategory = (category: string) => activeCategory === category;
	const documentCategories = useMemo(
		() => sortedDocuments && Object.keys(sortedDocuments).sort(),
		[sortedDocuments]
	)

	 const toggleCategoryBox = (category: string): void => {
			const activeCategory = !isActiveCategory(category) ? category : '';
			setActiveCategory(activeCategory);
		}

	const getActiveDocsNumberInCategory = (documents: IDocument[]): number => {
		return documents.filter(doc => doc.active).length
	}

	const getAmountOfSelectedDocumentsInGeneral = (): number => {
		if(!documents || !documents?.length) {
			return 0;
		}
		let sum = 0;
		for(let document of documents) {
			if(document.active) {
				sum++;
			}
		}

		return sum;
	}
	
	const checkIsTooManyDocumentsSelected = () => {
		return getAmountOfSelectedDocumentsInGeneral() > documentConstants.selectedHighWaterMark
	}

	const onSaveClickHandler = () => {
		documentsBulkUpdateMutation.mutate(
			changedDocuments.map(({ id, active }: IDocument) => ({
				document_id: String(id),
				active,
			}))
		)
	}

	const onDiscardClickHandler = () => {
		changedDocuments.forEach(doc => {
			onActiveChange(doc.id, !doc.active);
		})
		setChangedDocuments([]);
	}

  return {
		documents,
		sortedDocuments,
		refetchDocuments,
		isDocumentsLoading,
		isDocumentsFetching,
		parseBorrowers,
		toggleCategoryBox,
		isActiveCategory,
		documentCategories,
		getActiveDocsNumberInCategory,
		onActiveChange,
		isDocumentsPending,
		getAmountOfSelectedDocumentsInGeneral,
		checkIsTooManyDocumentsSelected,
		documentsBulkUpdateMutation,
		onSaveClickHandler,
		onDiscardClickHandler,
		uploadNewDocumentMutation,
		updateDocumentHandler,
	}
}