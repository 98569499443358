import { styled } from '@mui/system';
import { Box, Typography, ListItem } from "@mui/material";
import { Divider } from "@mui/material";
import { IStyle } from "../../interfaces";

export const SideNavComponent = styled(Box)(
	({ theme, collapsed, drawer }: IStyle) => ({
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: `${drawer ? '100vh' : 'calc(100vh - 100px)'}`,
		paddingTop: `${drawer && '100px'}`,
		overflowY: 'hidden',
		overflowX: 'hidden',
		background: theme.palette.background.default,
		minWidth: `${collapsed ? '58px' : '161px'}`,
		width: `${collapsed ? '58px' : '161px'}`,
		[theme.breakpoints.down('lg')]: {
			paddingTop: `${drawer && '80px'}`,
			height: `${drawer ? '100vh' : 'calc(100vh - 80px)'}`,
		},
		[theme.breakpoints.down('sm')]: {
			width: `${collapsed ? '50px' : '355px'}`,
			height: '100vh',
		},
		'@media(max-height: 800px)': {
			overflowY: 'auto',
		},
	})
)

export const SideNavIconComponent: any = styled(ListItem)(({ theme , collapsed }: any) => ({
  height: '60px',
  minWidth: `${collapsed ? '58px' : '161px'}`,
  boxShadow: 'none',
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    minWidth: '58px',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: `${collapsed ? '75px' : '355px'}`,
    justifyContent: 'start',
    margin: '0px 16px',
  },
}));

export const HoveredElement: any = styled(Box)(({ theme, themeMode, collapsed }: any) => ({
  display: 'flex',
  padding: '8px',
  width: `${collapsed ? '48%' : '80%'}`,
  alignItems: 'center',
  "&:hover": {
    backgroundColor: themeMode === "light" ? "#EEEDE9" : "#7D8A8F",
    borderRadius: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: `${collapsed ? '50%' : '80%'}`,
  },
}))

export const SideNavIconImageComponent: any = styled('img')(({ }) => ({
  height: '28px',
  width: '28px',
  marginRight: '8px',
}));

export const FontComponent = styled(Typography)(({ theme, collapsed }: IStyle) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19.2px',
  display: `${!collapsed ? 'flex' : 'none'}`,
  alignItems: 'center',
  height: '56px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.8px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },

}));

export const MenuFontComponent = styled(Typography)(({ theme, collapsed }: any) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '19.2px',
  display: `${!collapsed ? 'flex' : 'none'}`,
  alignItems: 'center',
  height: '56px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16.8px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },

}));


export const SimpleDivider = styled(Divider)(({ theme, collapsed }: IStyle) => ({
  margin:  '12px 10px',
  background: theme.palette.text.primary,
  opacity: 0.2
}));

export const InnerHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%'
}));

export const SideBarWrapper = styled(Box)(({ theme }) => ({
  minHeight: 'calc(100vh - 101px)',
  minWidth: 492,
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    height: 'calc(100vh - 81px)',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
}));


export const OutletWrapper = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  minWidth: 'calc(100% - 420px)',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    height: 'calc(100vh - 80px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 84px)',
  },
}));

export const MainWrapper = styled(Box)(({ theme, sx: {themeMode} }: IStyle) => ({
  background: `${themeMode === 'light' ? 'white' : '#1B2C33'}`,
  minHeight: '100vh',
  overflowY: 'hidden',
}));

export const DrawerMenuIcon = styled('img')(({ }) => ({
  height: '40px',
  width: '40px',
}));

export const SuperAdmin = styled(Typography)(({theme}) => ({
  fontSize: '13px',
  fontWeight:400 , 
  lineHeight: '16.93px',
   fontFamily: 'Roboto Slab', 
   color: theme.palette.text.primary, 
   opacity: '0.8'
}));

export const DividerBox = styled(Box)(({ theme, collapsed }: IStyle) => ({
  display: 'flex',
  justifyContent: `${collapsed ? 'center' : 'center' }`,
}));

export const MenuNavElementWrapper = styled(Box)(({ theme }: IStyle) => ({
	position: 'absolute',
	bottom: '12px',
	width: '100%',
	'@media (max-height: 780px)': {
		position: 'static',
	},
}))