import { FC, useContext } from 'react'
import {
	ButtonWrapper,
	NewLoanBtnWrapper,
	NewLoanMobileText,
	PlusIcon,
} from '../../../layouts/innerSideBar/innerSideBar.style'
import { ModalContext } from '../../../context'
import { ModalWrapper } from '../../allModals/upload/ModalWrapper'

interface INewLoanWrapperProps {
	src: string
}

const NewLoanWrapper: FC<INewLoanWrapperProps> = ({ src }) => {
	const { openModal, isModalOpen, modalKey } = useContext(ModalContext)

	return (
		<NewLoanBtnWrapper>
			<ButtonWrapper
				startIcon={<PlusIcon src={src} />}
				onClick={() => openModal('loan')}
			>
				<NewLoanMobileText>New Loan</NewLoanMobileText>
			</ButtonWrapper>
			<ModalWrapper open={isModalOpen && modalKey === 'loan'} width='50%' />
		</NewLoanBtnWrapper>
	)
}

export { NewLoanWrapper }
