import { Box, Typography, Button, TextField, ListItem } from '@mui/material'
import { styled } from '@mui/system'
import FormGroup from '@mui/material/FormGroup'
import { IStyle } from '../../interfaces'

interface IModalBodyWrapper extends IStyle {
	header?: boolean
	footer?: boolean
	isDragOver?: boolean
	paddingBlock?: string
	bottomBorderRadius?: boolean;
}

interface IUploadMimicIcon extends IStyle {
	animateBorderColor: string
}

export const ModalBodyWrapper = styled(Box)(
	({
		theme,
		header,
		isDragOver,
		sx: { themeMode },
		footer = false,
		paddingBlock = '24px',
		bottomBorderRadius
	}: IModalBodyWrapper) => ({
		paddingInline: '20px',
		paddingBlock,
		backgroundColor:
			isDragOver && themeMode === 'dark'
				? '##37484f'
				: isDragOver && themeMode === 'light'
				? '#c6c5c5'
				: theme.palette.background.paper,
		borderTopLeftRadius: `${header ? '0px' : '16px'}`,
		borderTopRightRadius: `${header ? '0px' : '16px'}`,
		borderBottomLeftRadius: `${!footer && !bottomBorderRadius ? '0px' : '16px'}`,
		borderBottomRightRadius: `${!footer && !bottomBorderRadius ? '0px' : '16px'}`,
		height: `${!footer ? 'auto' : '50vh'}`,
		overflow: 'auto',
	})
)

export const BodyText = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontWeigt: 600,
	lineHeight: '25.2px',
}))

export const BodyTextStep1 = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontWeigt: 600,
	lineHeight: '25.2px',
	display: 'flex',
	justifyContent: 'center',
	marginBlock: '0px 10px',
}))

export const BodySubText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeigt: 400,
	lineHeight: '22.4px',
	textAlign: 'center',
}))

export const BodySubtitle = styled(Typography)(({ theme }) => ({
	fontSize: '22px',
	fontWeigt: 600,
	lineHeight: '22.4px',
	marginBottom: '20px',
}))

export const ModalSubText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeigt: 400,
	lineHeight: '22.4px',
	marginTop: '16px',
	display: 'flex',
	justifyContent: 'center',
}))

export const BodySubTextError = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeigt: 400,
	lineHeight: '22.4px',
	marginTop: '16px',
	display: 'flex',
	justifyContent: 'center',
	color: 'red',
}))

export const TextFieldWrapper = styled(TextField)(({ theme }) => ({
	width: '100%',
	margin: '10px 0px',
}))

export const ModalTextField = styled(TextField)(({ theme }) => ({
	width: '512px',
}))

export const FormGroupWrapper = styled(FormGroup)(({ theme }) => ({
	marginTop: '21px',
}))

export const TrashIconModalWrapper = styled(Box)(
	({ theme, sx: {themeMode} }: IStyle) => ({
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: themeMode === 'light' ? '#F7F6F4' : '#465359',
		height: '80px',
		width: '80px',
		margin: '0 auto',
		borderRadius: '50%',
	})
)

export const TrashIconModal = styled('img')(({ theme }) => ({
	height: '36px',
	width: '40px',
	margin: 'auto 0',
}))

export const DeleteModalBody = styled(Box)(({ theme }) => ({
	marginTop: '28px',
	display: 'flex',
	justifyContent: 'center',
}))

export const DeleteModalHeading = styled(Typography)(({ theme }) => ({
	fontSize: '24px',
	fontWeight: 600,
	lineHeight: '33.6px',
}))

export const DeleteModalSubHeading = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '25.6px',
	textAlign: 'center',
}))

export const SubheadingWrapper = styled(Box)(({ theme }) => ({
	padding: '0px  50px',
}))

export const SubheadingInner = styled(Box)(({ theme }) => ({
	marginTop: '16px',
	display: 'flex',
	justifyContent: 'center',
}))

export const FormGroupWrapperPrice = styled(FormGroup)(({ theme }) => ({
	padding: '0px 20px',
	marginTop: '24px',
}))

export const PlanTextWrapper = styled(Box)(({ theme }) => ({
	padding: '0px 125px',
	display: 'flex',
	justifyContent: 'center',
}))

export const PlanText = styled(Typography)(({ theme }) => ({
	fontSize: '30px',
	fontWeight: 600,
	lineHeigth: '42px',
}))

export const OffersBox = styled(Box)(({ theme }) => ({
	padding: '0px 125px',
	display: 'flex',
	justifyContent: 'center',
}))

export const OffersText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	lineHeigth: '19.6px',
}))

export const PricingBoxWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '730px',
}))

export const PricingBoxMain = styled(Box)(({ theme }) => ({
	border: '1px solid black',
	borderRadius: '10px',
	width: '348px',
	marginTop: '40px',
}))

export const PackageText = styled(Typography)(({ theme }) => ({
	fontSize: '28px',
	lineHeight: '33.6px',
	fontWeight: 400,
	padding: '24px 20px',
}))

export const PackagePrice = styled(Typography)(({ theme }) => ({
	fontSize: '40px',
	lineHeight: '56px',
	fontWeight: 400,
	padding: '0px 20px',
}))

export const PackageBtn = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
}))

export const SubscribeBtn = styled(Button)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	lineHeight: '25.2px',
	height: '56px',
	width: '308px',
	margin: '20px 0px',
	background: theme.palette.background.default,
	color: theme.palette.text.primary,
	'&:hover': {
		backgroundColor: theme.palette.background.default,
	},
}))

export const SubscribeAdvanceBtn = styled(Button)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	lineHeight: '25.2px',
	height: '56px',
	width: '308px',
	margin: '20px 0px',
	background: theme.palette.primary.light,
	color: theme.palette.background.paper,
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
	},
}))

export const UploadWrapper = styled(Box)(({ theme, marginBottom = '30px' }: any) => ({
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	alignItems: 'center',
	marginBottom,
}))

export const UploadIcon = styled('img')(({ theme }: any) => ({
	height: '132px',
	width: '132px',
}))

export const FeedbackSuccessIcon = styled('img')(({ theme }: any) => ({
	height: '53px',
	width: '53px',
}))

export const UploadMimicIcon = styled(Box)(
	({ theme, animateBorderColor }: IUploadMimicIcon) => ({
		height: '132px',
		width: '132px',
		marginBottom: '30px',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '10px solid #F7F6F4',
		borderRadius: '50%',
		animation: `${animateBorderColor} 1s linear`,
	})
)

export const UploadMimicIconText = styled(Typography)(({ theme }) => ({
	fontSize: '30px',
	fontWeight: 900,
	zIndex: 100,
}))

export const UploadText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	lineHeight: '25.6px',
	fontWeight: 500,
	textAlign: 'center',
}))

export const UploadTextComplete = styled(Typography)(
	({ theme, marginTop = '18px', width = '100%' }: { theme?: any; marginTop?: string, width?: string }) => ({
		fontSize: '24px',
		lineHeight: '33.6px',
		fontWeight: 600,
		textAlign: 'center',
		fontFamily: 'Bai Jamjuree',
		marginTop,
		width,
		marginInline: 'auto',
	})
)

export const ArrowIconModal = styled('img')<any>(({ theme }: any) => ({
	height: '20px',
	width: '20px',
}))

export const GuideListItem = styled(ListItem)<any>(
	({ theme, themeMode }: any) => ({
		background: `${themeMode === 'light' ? '#F7F6F4' : '#12252D'}`,
		width: '100%',
		borderRadius: '16px',
		marginBottom: '15px',
		flexDirection: 'column',
		padding: '20px',
	})
)

export const GuideItemBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}))

export const InnerListBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}))

export const ItemText = styled(Typography)(({ theme }) => ({
	fontSize: '20px',
	fontWeight: 600,
	fontFamily: 'Bai Jamjuree',
	lineHeight: '28px',
}))

export const Timer = styled(Typography)(() => ({
	fontSize: '22px',
	fontWeight: 700,
	fontFamily: 'Bai Jamjuree',
	color: '#FE6A57',
}))
