import { useEffect, useState } from 'react'
import {
	ModalBodyWrapper,
	UploadWrapper,
	BodySubText,
} from '../../../allModals.style'
import { timerConstants } from '../../../../../constants/timerConstants'
import { AnimatedCircle } from '../../../../common/AnimatedCircle/AnimatedCircle'
import { getThemeReducer, useAppSelector } from '../../../../../store'

const Step2 = () => {
	const { themeMode } = useAppSelector(getThemeReducer)
	const [counter, setCounter] = useState<number>(timerConstants.modalTimer)
	const percentage =
		100 - Math.floor((counter / timerConstants.modalTimer) * 100)

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCounter(prev => {
				if (prev <= 0) {
					clearInterval(intervalId)
					return prev
				}
				return prev - 1
			})
		}, 1000)

		return () => clearInterval(intervalId)
	}, [])

	return (
		<ModalBodyWrapper
			header={true}
			sx={{ themeMode }}
			paddingBlock='0px 10px'
		>
			<UploadWrapper marginBottom='0px'>
				<AnimatedCircle percentage={percentage} />
			</UploadWrapper>
			<BodySubText>
				Please do not close this window until the upload is complete.
			</BodySubText>
		</ModalBodyWrapper>
	)
}
export { Step2 }
