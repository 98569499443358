
export const setFileExtension = (contentType: string): string => {
	let ext: string
	switch (contentType) {
		case 'application/vnd.ms-excel': {
			ext = '.xls'
			break
		}
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
			ext = '.xlsx'
			break
		}
		case 'application/octet-stream': {
			ext = '.pdf'
			break;
		}
		default: {
			ext = '.txt'
		}
	}

	return ext
}
