import React from 'react';
import { getThemeReducer, useAppSelector } from '../store';

const Reload = () => {
  	const { themeMode } = useAppSelector(getThemeReducer)

    return (
        <>
        {
        themeMode === 'light' ?
        (<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.65499 6.5977H9.55501L9.55499 6.69768C9.55483 7.43651 9.3245 8.15693 8.896 8.75881C8.46751 9.36068 7.86215 9.81411 7.16407 10.0561C6.46598 10.298 5.70986 10.3165 5.0008 10.1089C4.29175 9.90126 3.66498 9.4779 3.20764 8.89765C2.75029 8.31739 2.48508 7.60907 2.44888 6.87113C2.41268 6.13319 2.60727 5.40231 3.00562 4.78007C3.40398 4.15783 3.98629 3.67516 4.67162 3.39914C5.30072 3.14577 5.98795 3.07809 6.65218 3.20209L5.60591 4.24837L5.5352 4.31908L5.60591 4.38979L6.06159 4.84548L6.1323 4.91619L6.20302 4.84548L8.0904 2.9581L8.16105 2.88745L8.09046 2.81673L6.20631 0.92935L6.13585 0.858768L6.06508 0.92904L5.60616 1.38473L5.53471 1.45567L5.60614 1.52663L6.3941 2.30947C5.60007 2.23804 4.79949 2.38352 4.07886 2.73307C3.26928 3.12576 2.5995 3.75713 2.15974 4.54213C1.71999 5.32714 1.53138 6.22806 1.61932 7.12354C1.70727 8.01902 2.06754 8.86603 2.65161 9.55049C3.23569 10.2349 4.0155 10.7239 4.886 10.9516C5.75651 11.1793 6.67588 11.1348 7.52028 10.8239C8.36467 10.5131 9.09353 9.95096 9.60866 9.21323C10.1238 8.47549 10.4004 7.59759 10.4014 6.6978L10.4015 6.5977H10.3014H9.65499Z" fill="#03363D" stroke="#03363D" stroke-width="0.2" />
        </svg>) : (
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.65499 6.5977H8.55501L8.55499 6.69768C8.55483 7.43651 8.3245 8.15693 7.896 8.75881C7.46751 9.36068 6.86215 9.81411 6.16407 10.0561C5.46598 10.298 4.70986 10.3165 4.0008 10.1089C3.29175 9.90126 2.66498 9.4779 2.20764 8.89765C1.75029 8.31739 1.48508 7.60907 1.44888 6.87113C1.41268 6.13319 1.60727 5.40231 2.00562 4.78007C2.40398 4.15783 2.98629 3.67516 3.67162 3.39914C4.30072 3.14577 4.98795 3.07809 5.65218 3.20209L4.60591 4.24837L4.5352 4.31908L4.60591 4.38979L5.06159 4.84548L5.1323 4.91619L5.20302 4.84548L7.0904 2.9581L7.16105 2.88745L7.09046 2.81673L5.20631 0.92935L5.13585 0.858768L5.06508 0.92904L4.60616 1.38473L4.53471 1.45567L4.60614 1.52663L5.3941 2.30947C4.60007 2.23804 3.79949 2.38352 3.07886 2.73307C2.26928 3.12576 1.5995 3.75713 1.15974 4.54213C0.719986 5.32714 0.531375 6.22806 0.61932 7.12354C0.707265 8.01902 1.06754 8.86603 1.65161 9.55049C2.23569 10.2349 3.0155 10.7239 3.886 10.9516C4.75651 11.1793 5.67588 11.1348 6.52028 10.8239C7.36467 10.5131 8.09353 9.95096 8.60866 9.21323C9.12379 8.47549 9.40044 7.59759 9.40136 6.6978L9.40146 6.5977H9.30136H8.65499Z" fill="white" stroke="white" stroke-width="0.2"/>
            </svg> 
        )
        }
        </>

    )
};

export default Reload;