import styled, { keyframes } from "styled-components";
import { ILoaderProps } from "./Loader";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
export const StyledLoader = styled.div`
	width: 50px;
	height: 50px;
	background-color: transparent;
	border: ${props => `6px solid ${props.color}`};
	border-radius: '50%';
	border-left: none;
	border-bottom: none;
`
export const LoaderWrapper = styled.div<ILoaderProps>`
	width: 100%;
	display: flex;
	justify-content: ${props => props.justifyContent || 'center'};
	align-items: center;
`