import { getThemeReducer, useAppSelector } from '../../../../../store';
import {
	ModalBodyWrapper,
	UploadIcon,
	UploadTextComplete,
	UploadWrapper,
	ModalSubText,
} from '../../../allModals.style'
import { HiddenInput } from '../../input/HiddenInput'

const UploadError = () => {
	const { themeMode } = useAppSelector(getThemeReducer);

	return (
		<>
			{/* @ts-ignore */}
			<ModalBodyWrapper header={true} sx={{ themeMode }}>
				<UploadWrapper>
					<UploadIcon src='/images/svgs/uploadError.svg' />
				</UploadWrapper>
				<UploadTextComplete marginTop='0px'>
					File Failed to Upload
				</UploadTextComplete>
				<ModalSubText>
					Please do not close this window until the upload is complete.
				</ModalSubText>
				<HiddenInput
					type='file'
					accept='application/zip,application/x-zip-compressed'
				/>
			</ModalBodyWrapper>
		</>
	)
}
export { UploadError }
