import { useEffect } from 'react'
import { useLoans } from './useLoans'
import { useActiveList } from './useActiveList'
import { titles } from './../constants/titles'
import { useLocation } from 'react-router-dom'

export const useTitle = (title?: string) => {
	const { isPathnameIsLoans, isLoansLoading } = useLoans()
	const { getActiveLoan } = useActiveList()
  const {pathname} = useLocation();

  const parsePathname = (): string => {
    const pathnameWithoutSlash = pathname.replace('/', '');
		if(pathnameWithoutSlash) {
			return pathnameWithoutSlash[0].toUpperCase() + pathnameWithoutSlash.slice(1);
		}
  } 

	useEffect(() => {
    const activeLoan = getActiveLoan();

		document.title = !isPathnameIsLoans
			? `${titles.commonSettings} - ${parsePathname()}`
			: isLoansLoading || !getActiveLoan()?.file_name
			? 'Loans'
			: `${activeLoan?.file_name} - ${activeLoan?.loan_number}`
	}, [title, isPathnameIsLoans, isLoansLoading, getActiveLoan])

	return {parsePathname}
}
