import {
	MsgBoxWrapper,
} from '../../../pages/home/home.style'
import {
	Fragment,
	memo,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useMessages } from '../../../hook/useMessages'
import { Message } from './Message/Message'
import { useEdit } from '../../../hook/useEdit'
import { ActionWrapper, InfinitiveScrollWrapper } from './messages.style'
import { ActiveListContext, ChatContext } from '../../../context'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from '../Loader/Loader'
import { Box } from '@mui/material'
import { ToolTip } from '../../../enum'
import { getThemeReducer, useAppSelector } from '../../../store'

const Messages = memo(() => {
	const {
		isMessagesPending,
		sortedMessages,
		shouldUseTypingEffect,
		fetchNextMessagesPage,
		hasMessagesNextPage,
		isMessagesFetchingNextPage,
	} = useMessages()
	const { activeEntity } = useContext(ActiveListContext)
	const { insertLink } = useEdit()
	const msgBoxWrapper = useRef<HTMLDivElement>()
	const { setMsgWrapper } = useContext(ChatContext)
	const [shouldAutoFetch, setShouldAutoFetch] = useState<boolean>(false)
	const { themeMode } = useAppSelector(getThemeReducer)

	useEffect(() => {
		if (msgBoxWrapper) {
			if (
				msgBoxWrapper?.current?.scrollHeight ===
					msgBoxWrapper?.current?.clientHeight &&
				hasMessagesNextPage
			) {
				setShouldAutoFetch(true)
				fetchNextMessagesPage()
			} else {
				setShouldAutoFetch(false)
			}
		}
	}, [
		msgBoxWrapper,
		sortedMessages,
		hasMessagesNextPage,
		fetchNextMessagesPage,
	])

	useEffect(() => {
		setMsgWrapper(msgBoxWrapper)
	}, [setMsgWrapper])

	return (
		<>
			{isMessagesPending ? (
				<Loader />
			) : (
				<MsgBoxWrapper
					sx={{ themeMode }}
					id='scrollableTarget'
					ref={msgBoxWrapper}
				>
					{activeEntity?.tooltipTitle === ToolTip.REFRESH && <Loader />}
					<InfinitiveScrollWrapper>
						<InfiniteScroll
							dataLength={sortedMessages.length}
							next={fetchNextMessagesPage}
							inverse={true}
							loader={<Loader />}
							style={{
								display: 'flex',
								flexDirection: 'column-reverse',
							}}
							hasMore={hasMessagesNextPage}
							scrollableTarget='scrollableTarget'
						>
							{sortedMessages &&
								sortedMessages.map((item, index) => (
									<Fragment key={item.id}>
										{item.type === 'message' ? (
											<Message
												key={item.id}
												message={item}
												shouldUseTypingEffect={shouldUseTypingEffect(index)}
											/>
										) : (
											<Box
												key={item.id}
												sx={{ width: '90%', margin: '0 auto' }}
											>
												<ActionWrapper
													className='actionMessageWrapper'
													sx={{ themeMode }}
												>
													{insertLink(
														item.description,
														item.details,
														String(item.id)
													)}
												</ActionWrapper>
											</Box>
										)}
									</Fragment>
								))}
						</InfiniteScroll>
					</InfinitiveScrollWrapper>
					{shouldAutoFetch && isMessagesFetchingNextPage && <Loader />}
				</MsgBoxWrapper>
			)}
		</>
	)
})

export { Messages }