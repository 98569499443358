import { ChangeEventHandler, FC, memo, PropsWithChildren, useContext, useState } from 'react'
import { Box } from '@mui/material'
import ModalBox from '../../common/modal'
import { ModalContext } from '../../../context'
import { useModal } from '../../../hook/useModal'
import { feedback_type, IFeedbackRequest } from '../../../interfaces/feedback.interface'
import { CheckboxName } from '../feedback/dislike/checkboxLabels'
import { getThemeReducer, useAppSelector } from '../../../store'

interface IModalWrapper extends PropsWithChildren {
	open: boolean;
	width?: string;
	isActionButtonDisabled?: boolean; 
}

const ModalWrapper: FC<IModalWrapper> = memo(
	({ width = '512px', open, isActionButtonDisabled }) => {
		const [feedback_text, setfeedback_text] = useState<string>('')
		const [checkboxes, setCheckboxes] =
			useState<Record<CheckboxName, boolean>>(null)
		const { mainModalConfig } = useModal()
		const { step, modalKey, closeModal } = useContext(ModalContext)
		const config = mainModalConfig[modalKey]?.config[step]
		const { themeMode } = useAppSelector(getThemeReducer)
		const [isDisabled, setIsDisabled] = useState<boolean>(!!isActionButtonDisabled)

		const onChangefeedback_text: ChangeEventHandler = (e): void => {
			const target = e.target as HTMLTextAreaElement
			setfeedback_text(target.value)
		}

		const generateFeedbackBody = (): IFeedbackRequest => {
			const commonBody = {
				created_at: new Date().toISOString(),
				feedback_text,
			}

			return modalKey === 'like'
				? {
						...commonBody,
						feedback_type: feedback_type.THUMBS_UP,
				  }
				: {
						...commonBody,
						feedback_type: feedback_type.THUMBS_DOWN,
						...checkboxes,
				  }
		}

		return (
			<Box>
				<ModalBox
					isDisabled={isDisabled}
					open={open}
					handleClose={config?.preventClose ? null : closeModal}
					width={width}
					cancelButtonText={config?.cancelButtonText}
					actionButtonText={config?.actionButtonText}
					headerText={config?.headerText}
					onClick={
						['like', 'dislike'].includes(modalKey)
							? () => config?.onClick(generateFeedbackBody())
							: config?.onClick
					}
					crossIcon={config?.crossIcon}
					showLikeIcon={config?.showLikeIcon && config?.showLikeIcon[themeMode]}
					headerRightCornerIcon={config?.headerRightCornerIcon}
					headerIconTooltipText={config?.headerIconTooltipText}
				>
					{config && (
						<config.Component
							onChangefeedback_text={onChangefeedback_text}
							setCheckboxes={setCheckboxes}
							successStateText={config?.successStatusText}
							failureStateText={config?.failureStatusText}
							setIsDisabled={setIsDisabled}
						/>
					)}
				</ModalBox>
			</Box>
		)
	}
)

export { ModalWrapper }
