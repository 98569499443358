import {FC, memo} from 'react';
import { IChatTimeline, ModalKey } from '../../../interfaces';
import { AnswerInnerWrapper, AnswerWrapper, AvatarIcon, AvatarMain, AvatarWrapper, DisLikeIcon, FeedbackIconWrapperComponent, LikeIcon, MsgWrapper, QuestionBody, QuestionInnerWrapper, QuestionWrapper } from '../../../pages/home/home.style';
import { SingleMessageWrapper } from '../Messages/Message/message.style';
import { ActionMobileWrapper, UserMessageAvatarMobile } from './messagesMobile.styles';
import { getThemeReducer, useAppSelector } from '../../../store';
import { User } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import { chatConstants } from '../../../constants';
import { useTypewriter } from '../../../hook/useTypewriter';
import { TypewriterComponent } from '../Typewriter/TypewriterComponent';
import { StyledMarkdown } from '../../../layouts/innerSideBar/innerSideBar.style';
import { LikeIconWrapper } from '../modal/header/modal.header.style';

interface IMessageMobileProps {
	item: IChatTimeline
	user: User
	scrollDown: (behavior: ScrollBehavior) => void
	shouldUseTypingEffect: boolean
	handleIsTypingEffectInProgress: (val: boolean) => void
	openModalFeedback: (modalKey: ModalKey, messageId: number) => void
	insertLink: (str: string, details: string, id: string) => React.ReactNode[]
}

const MessageMobile: FC<IMessageMobileProps> = memo(({item, user, scrollDown, shouldUseTypingEffect, handleIsTypingEffectInProgress, openModalFeedback, insertLink}) => {
  const {themeMode} = useAppSelector(getThemeReducer);
  const {refetchEntities, updateQueryCache, parseMarkdownHandler} = useTypewriter();

  return (
		<>
			{item.type === 'message' ? (
				<MsgWrapper>
					<SingleMessageWrapper>
						<AvatarWrapper>
							<AvatarMain>
								{item.user === 'system' ? (
									<AvatarIcon
										sx={{ background: 'transparent' }}
										src='/images/svgs/profile-1.svg'
									/>
								) : (
									<UserMessageAvatarMobile themeMode={themeMode}>
										{user?.email.substring(0, 1).toUpperCase()}
									</UserMessageAvatarMobile>
								)}
							</AvatarMain>
						</AvatarWrapper>
						<QuestionWrapper>
							<QuestionInnerWrapper
								onResize={() => scrollDown('instant' as ScrollBehavior)}
								sx={{ themeMode }}
							>
								<QuestionBody>
									{shouldUseTypingEffect ? (
											<TypewriterComponent
												textToType={item.message_text}
												interval={chatConstants.typingEffectInterval}
												handleIsTypingEffectInProgress={
													handleIsTypingEffectInProgress
												}
												refetchEntities={refetchEntities}
												updateQueryCache={updateQueryCache}
												parseMarkdownHandler={parseMarkdownHandler}
											/>
									) : (
										<StyledMarkdown>{item.message_text}</StyledMarkdown>
									)}
								</QuestionBody>
							</QuestionInnerWrapper>
						</QuestionWrapper>
						{item.user === 'system' && (
							<AnswerWrapper>
								<AnswerInnerWrapper>
									<Grid container columns={12}>
										<Grid xs={6} item>
											<LikeIconWrapper>
												<LikeIcon
													src={
														themeMode === 'light'
															? '/images/svgs/like.svg'
															: '/images/svgs/like-dark.svg'
													}
													onClick={() => openModalFeedback('like', item.id)}
												/>
											</LikeIconWrapper>
										</Grid>
										<Grid xs={6} item>
											<FeedbackIconWrapperComponent sx={{ themeMode }}>
												<DisLikeIcon
													src={
														themeMode === 'light'
															? '/images/svgs/dislike.svg'
															: '/images/svgs/dislike-dark.svg'
													}
													onClick={() => openModalFeedback('dislike', item.id)}
												/>
											</FeedbackIconWrapperComponent>
										</Grid>
									</Grid>
								</AnswerInnerWrapper>
							</AnswerWrapper>
						)}
					</SingleMessageWrapper>
				</MsgWrapper>
			) : (
				<ActionMobileWrapper sx={{ themeMode }} key={item.id}>
					{insertLink(item.description, item.details, String(item.id))}
				</ActionMobileWrapper>
			)}
		</>
	)
});

export { MessageMobile };