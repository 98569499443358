
export interface BillingModalItem {
	modalTitle: string;
	modalSubtitle: string;
	items: BillingItem[];
}

export interface BillingItem {
	subTitle: string;
	title: string;
	items: string[];
	buttonText: string;
	buttonType: 'cancel' | 'action';
}

export const billingConstants: BillingModalItem = {
	modalTitle: 'Please choose your subscription plan',
	modalSubtitle:
		'We offer flexible pricing plans to meet your needs and budget. Choose from our Basic, Advanced plans to get the right level of service for you.',
	items: [
		{
			subTitle: 'Pro',
			title: 'Coming Soon',
			items: [
				'Up to 150 mortgage applications per month',
				'Full access to mortgage dashboard features',
				'Email and chat support',
			],
			buttonType: 'cancel',
			buttonText: 'Notify Me',
		},
		{
			subTitle: 'Enterprise',
			title: 'Custom',
			items: [
				'Unlimited mortgage applications per month',
				'Full access to mortgage dashboard features',
				'Email, chat, and phone support',
			],
			buttonType: 'action',
			buttonText: 'Contact Sales',
		},
	],
}