import { useMutation, useQuery } from "@tanstack/react-query"
import { QueryKey } from "../enum"
import { billingService } from "../services"
import { IBillingAccount } from "../interfaces"
import { useContext } from "react"
import { ActiveListContext, ModalContext } from "../context"
import { useNavigate } from "react-router-dom"

export const useBilling = () => {
	const {openModal} = useContext(ModalContext);
	const navigate = useNavigate();
	const {setActiveList} = useContext(ActiveListContext);

  const {
		data: billing,
		refetch: refetchBilling,
		isPending: isBillingPending,
		...billingOptions
	} = useQuery({
		queryKey: [QueryKey.BILLING],
		queryFn: ({signal}) => billingService.getInfo(signal),
		staleTime: Infinity,
	})

	const updateBillingMutation = useMutation({
		mutationFn: (updatedFields: Partial<IBillingAccount>) =>
			billingService.updateBillingInfo(updatedFields),
		onSuccess: () => {
			refetchBilling()
		},
		onError: e => {
			console.error(e)
		},
	})

	const updateBillingHandler = (updatedFields: Partial<IBillingAccount>): void => {
		updateBillingMutation.mutate(updatedFields)
	}

	const shouldAutoOpenBillingModal = (billingEntity: IBillingAccount): boolean => {
		return !billingEntity?.stripe_customer_id ||
			[billingEntity?.account_status, billingEntity?.subscription_status].some(
				status => status !== 'active'
			)
	} 

	const openBillingModalIfNeeded = (): void => {
		if (!billing) return
		const billingEntity = billing as IBillingAccount;
		shouldAutoOpenBillingModal(billingEntity) && openModal('billing');
	}

	const handleClickBillingNavItem = (): void => {
		if (!billing) return
		const billingEntity = billing as IBillingAccount;
		if(shouldAutoOpenBillingModal(billingEntity)) {
			openModal('billing')
		} else {
			setActiveList('Billing')
			navigate('/billing');
		} 
	}

	return {
		updateBillingHandler,
		updateBillingMutation,
		billingOptions,
		billing,
		isBillingPending,
		handleClickBillingNavItem,
		openBillingModalIfNeeded,
	}
}