import { useLocation, useNavigate } from "react-router-dom";
import { headerButtons } from "../../../constants/headerButtons";
import { ButtonComponent, ButtonInnerComponent, HeaderButton, PlusBtn, PlusBtnIcon } from "../../../layouts/header/header.style";
import { FC, memo, useRef } from "react";
import { getThemeReducer, useAppSelector } from "../../../store";

interface IHeaderButtonsProps {
	handleopenModal: () => void
}

const HeaderButtons: FC<IHeaderButtonsProps> = memo(
	({ handleopenModal }) => {
		const { pathname } = useLocation()
		const { themeMode } = useAppSelector(getThemeReducer)
		const navigate = useNavigate()
   	const loanRef = useRef<HTMLButtonElement>(null)

		return (
			<ButtonComponent>
				<ButtonInnerComponent>
					{headerButtons.map(button => {
						return (
							<HeaderButton
								key={button.title}
								variant={button.variant}
								active={pathname.includes(button.url)}
								themeMode={themeMode}
								onClick={() => navigate(button.url)}
								ref={button.ref ? loanRef : null}
							>
								{button.title}
							</HeaderButton>
						)
					})}
					<PlusBtnIcon onClick={handleopenModal}>
						<PlusBtn src='/images/svgs/plus.svg' />
					</PlusBtnIcon>
				</ButtonInnerComponent>
			</ButtonComponent>
		)
	});

export { HeaderButtons };