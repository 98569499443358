import { FC } from 'react'

import { InnerShadow, ModalLoadingWrapper, UploadText } from './AnimatedCircle.styles'
import './animatedCircle.css';
import { getThemeReducer, useAppSelector } from '../../../store';

interface IAnimatedCircleProps {
	percentage: number
}

const AnimatedCircle: FC<IAnimatedCircleProps> = ({ percentage }) => {
	const { themeMode } = useAppSelector(getThemeReducer)

	return (
		<ModalLoadingWrapper>
			<InnerShadow sx={{ themeMode }}>
				<UploadText sx={{ themeMode }}>{percentage.toString()}%</UploadText>
			</InnerShadow>
			<div className={`hold-${themeMode} hold left`}>
				<div className='fill' />
			</div>
			<div className={`hold-${themeMode} hold right`}>
				<div className='fill'></div>
			</div>
		</ModalLoadingWrapper>
	)
}

export { AnimatedCircle }
