import {memo, useContext, useEffect} from 'react';
import { CustomList, InnerSideBarWrapper } from '../../../layouts/innerSideBar/innerSideBar.style';
import { useActiveList } from '../../../hook/useActiveList';
import { useLoans } from '../../../hook/useLoans';
import { InnerSideBarItem } from './InnerSideBarItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../Loader/Loader';
import { useParams } from 'react-router-dom';
import { getThemeReducer, useAppSelector } from '../../../store';
import { ActiveListContext } from '../../../context';

const InnerSideBar = memo(
	() => {
		const { listItems } = useActiveList()
		const { themeMode } = useAppSelector(getThemeReducer)
		const {loanId} = useParams();
		const { isLoansLoading, isPathnameIsLoans, hasLoansNextPage, fetchLoansNextPage, sortedLoans } = useLoans()
		const {updateActiveList} = useActiveList();
		const {setActiveList} = useContext(ActiveListContext);

			useEffect(() => {
				if (!isLoansLoading && isPathnameIsLoans) {
					const activeLoanByPathname = sortedLoans.find(
						loan => loan.id === +loanId
					)
					if (activeLoanByPathname) {
						setActiveList(loanId)
					} else {
						updateActiveList()
					}
				}
			}, [isLoansLoading])

		const mappedContent = listItems.map((item: any) => (
								<InnerSideBarItem
									item={item}
									isActive={String(item.id) === loanId}
								/>
							))

		return (
			<InnerSideBarWrapper
				sx={{ themeMode }}
				id='innerSideBarWrapperMobile'
				is_pathname_is_loans={isPathnameIsLoans}
			>
				<CustomList>
					{isLoansLoading ? (
						<Loader />
					) : !isPathnameIsLoans ? (
						<>{mappedContent}</>
					) : (
						<InfiniteScroll
							dataLength={listItems.length}
							hasMore={hasLoansNextPage}
							next={fetchLoansNextPage}
							loader={<Loader />}
							scrollableTarget='innerSideBarWrapperMobile'
						>
							{mappedContent}
						</InfiniteScroll>
					)}
				</CustomList>
			</InnerSideBarWrapper>
		)
	}
);

export { InnerSideBar };