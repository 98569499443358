import {
	CardContentWrapper,
	CardTitle,
} from '../../../pages/account/settings.style'
import { Form } from './Form/Form'
import { Box } from '@mui/material'
import { IFormConfigKey, useConfig } from '../../../hook/useConfig'
import { FC } from 'react'
import { Loader } from '../Loader/Loader'

interface IFormWrapperProps {
	title: string
	formConfigKey?: IFormConfigKey
	isFormFinished?: boolean;
}
const FormWrapper: FC<IFormWrapperProps> = ({ title, formConfigKey, isFormFinished }) => {
	const { formConfig } = useConfig()

	return (
		<CardContentWrapper>
			<CardTitle>{title}</CardTitle>
			{formConfigKey ? (
				formConfig[formConfigKey].isPending ? (
					<Loader />
				) : (
					<Form
						configData={formConfig}
						formConfigKey={formConfigKey}
						title={title}
						isFormFinished={isFormFinished}
					/>
				)
			) : (
				<Box>Coming soon!</Box>
			)}
		</CardContentWrapper>
	)
}

export { FormWrapper }
