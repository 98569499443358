interface ThemeModeDepends {
  light: string;
  dark: string;
}

export interface INavItem {
	label?: string
	link?: string
	subLinks?: string[];
	src: ThemeModeDepends
	labelObj?: ThemeModeDepends
	active?: ThemeModeDepends
	needDivider: boolean
}

export const navItems: INavItem[] = [
	{
		label: 'Home',
		link: 'loans',
		src: {
			light: '/images/svgs/home.svg',
			dark: '/images/svgs/home-dark-inactive.svg',
		},
		active: {
			light: '/images/svgs/home-active.svg',
			dark: '/images/svgs/home-dark.svg',
		},
		needDivider: true,
	},
	{
		label: 'Archive',
		link: 'archive',
		src: {
			light: '/images/svgs/archive.svg',
			dark: '/images/svgs/collection-dark.svg',
		},
		active: {
			light: '/images/svgs/archive-active.svg',
			dark: '/images/svgs/archive-dark-active.svg',
		},
		needDivider: true,
	},
	{
		labelObj: {
			light: 'Dark Mode',
			dark: 'Light Mode',
		},
		src: {
			light: '/images/svgs/moon.svg',
			dark: '/images/svgs/moon-dark.svg',
		},
		needDivider: true,
	},
	{
		label: 'Help Center',
		src: {
			light: '/images/svgs/call.svg',
			dark: '/images/svgs/call-dark.svg',
		},
		active: {
			light: '/images/svgs/call-active-light.svg',
			dark: '/images/svgs/call-active-dark.svg',
		},
		needDivider: true,
	},
	{
		label: 'Billing',
		link: 'billing',
		src: {
			light: '/images/svgs/vector-light.svg',
			dark: '/images/svgs/Vector-dark.svg',
		},
		active: {
			light: '/images/svgs/vector-light-active.svg',
			dark: '/images/svgs/vector-dark-active.svg',
		},
		needDivider: true,
	},
	{
		label: 'Settings',
		link: 'settings',
		subLinks: ['account', 'company', 'integration', 'notification'],
		src: {
			light: '/images/svgs/settings-light-inactive.svg',
			dark: '/images/svgs/settings-dark-inactive.svg',
		},
		active: { 
			light: '/images/svgs/setting-active.svg',
			dark: '/images/svgs/setting-dark.svg',
		},
		needDivider: true,
	},
	{
		label: 'Power Ups',
		src: {
			light: '/images/svgs/Group.svg',
			dark: '/images/svgs/powerups-dark.svg',
		},
		active: {
			light: '',
			dark: '',
		},
		needDivider: false,
	},
	{
		label: 'Menu',
		src: {
			light: '/images/svgs/moreOption.svg',
			dark: '/images/svgs/moreOption-dark.svg',
		},
		needDivider: false,
	},
]
