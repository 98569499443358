import { MutableRefObject, useCallback, useContext, useEffect, useState } from "react"
import { ChatContext } from "../context"
import { getMobileReducer, useAppSelector } from "../store";

export const useWrapper = () => {
	const [wrapper, setWrapper] = useState<MutableRefObject<HTMLDivElement>>(null)
  const { msgWrapper, msgWrapperMobile } = useContext(ChatContext);
	const {isMobile} = useAppSelector(getMobileReducer);

  useEffect(() => {
		setWrapper(isMobile ? msgWrapperMobile : msgWrapper)
	}, [isMobile, setWrapper, msgWrapper, msgWrapperMobile]);

  const scrollDown = useCallback((behavior: ScrollBehavior): void => {
		if (wrapper && wrapper.current && wrapper.current.scrollTop) {
			if (behavior === 'smooth') {
				wrapper.current.scrollTo({
					top: wrapper.current.scrollHeight + Infinity,
					behavior: 'smooth',
				})
			} else {
				wrapper.current.scrollTop = wrapper.current.scrollHeight + Infinity
			}
		}
	}, [wrapper]);

  return {
		wrapper,
		scrollDown
	}
}