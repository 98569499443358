import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalLoadingWrapper = styled(Box)(() => ({
	width: '132px',
	height: '132px',
	margin: '30px auto',
	position: 'relative',
  zIndex: '1',
}))

export const InnerShadow = styled(Box)(({ sx: { themeMode } }: any) => ({
	position: 'absolute',
	borderRadius: '100%',
	top: '50%',
	left: '50%',
	width: '112px',
	height: '112px',
	marginLeft: '-56px',
	marginTop: '-56px',
	background: `${themeMode === 'dark' ? '#1B2C33' : '#ffffff'}`,
	zIndex: '5',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

export const UploadText = styled(Box)(({ sx: { themeMode } }: any) => ({
	zIndex: '10',
	fontSize: '30px',
	fontWeight: '700',
	fontFamily: 'Roboto Slab',
	color: `${themeMode === 'dark' ? '#FFFFFF' : '#03363D'}`,
	marginLeft: '5px',
}))