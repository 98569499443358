import { getThemeReducer, useAppSelector } from "../../../store";
import { ModalBodyWrapper } from "../allModals.style";
import { BillingModalFlexContainer, BillingModalSubTitle, BillingModalTitle } from "./Billing.style";
import { billingConstants, BillingItem } from "./billingConstants";
import { BillingModalItemComponent } from "./BillingModalItemComponent";

const BillingModal = () => {
  const {themeMode} = useAppSelector(getThemeReducer);
  const {modalSubtitle, modalTitle, items} = billingConstants;
  return (
		<ModalBodyWrapper bottomBorderRadius={true} sx={{ themeMode }} header={true} paddingBlock={'30px'}>
      <BillingModalTitle>{modalTitle}</BillingModalTitle>
      <BillingModalSubTitle>{modalSubtitle}</BillingModalSubTitle>
      <BillingModalFlexContainer>
        {items.map((item: BillingItem) => <BillingModalItemComponent key={item.title} billingItem={item}  />)}
      </BillingModalFlexContainer>
		</ModalBodyWrapper>
	)
};

export { BillingModal };