import { useState } from "react"

export type YearAndMonths = {
	year: number
	months: string[]
}

export type StartEndDates = {
	start_date: Date | string;
	end_date: Date | string;
}


export const useArchive = () => {
  const [activeDate, setActiveDate] = useState<string | number>(null);

  const isActiveYear = (date: string | number): boolean => {
    return activeDate === date;
  }

  const clickDateHandler = (date: string | number): void => {
    const currentActiveDate = isActiveYear(date) ? '' : date;
    setActiveDate(currentActiveDate)
  }

	const oldestDataYear = 2023;

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]

	const getMonthArray = (year: number): string[] => {
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()

		if (year < currentYear) {
			return months
		} else {
			return months.slice(0, currentMonth + 1)
		}
	}

	 const getYearsAndMonths = (): YearAndMonths[] => {
		const yearsAndMonths = []
		let currentYear = new Date().getFullYear()
		while (currentYear >= oldestDataYear) {
			const yearAndMonths = {} as YearAndMonths
			yearAndMonths.year = currentYear
			yearAndMonths.months = getMonthArray(currentYear)
			yearsAndMonths.push(yearAndMonths)
			currentYear--
		}

		return yearsAndMonths
	}

	const getMonthLastDay = (year: number, monthIndex: number): number => {
		const currentMonth = months[monthIndex]
		let lastDay = 31

		while (
			new Date(`${year}, ${currentMonth}, ${lastDay}`).getMonth() !== monthIndex
		) {
			lastDay--
		}
		return lastDay
	}

	const parseMonthIndex = (monthIndex: number): string => {
		return String(monthIndex + 1).padStart(2, '0')
	}

	const getStartEndDates = (
		year: number,
		monthIndex: number
	): StartEndDates => {
		const start_date = `${year}-${parseMonthIndex(monthIndex)}-01`
		const end_date = `${year}-${parseMonthIndex(monthIndex)}-${getMonthLastDay(
			year,
			monthIndex
		)}`
			return {start_date, end_date}
	} 

  const yearsAndMonths = getYearsAndMonths();

  return {
		isActiveDate: isActiveYear,
		clickDateHandler,
		activeDate,
		setActiveDate,
		getMonthLastDay,
		yearsAndMonths,
		getStartEndDates,
		months
	}
}