import { Box } from '@mui/material'
import { FC } from 'react'
import { CustomActionWrapperStatus } from '../../ActionWrapperStatus/CustomActionWrapperStatus'

interface IUpdateStatusProps {
	status: 'idle' | 'pending' | 'success' | 'error'
	isTouched: boolean
	title: string
}

const UpdateStatus: FC<IUpdateStatusProps> = ({ status, isTouched, title }) => {
	return (
		<>
			{!['idle', 'pending'].includes(status) && !isTouched && (
				<Box sx={{ position: 'absolute', top: -30 }}>
					<CustomActionWrapperStatus
						src={
							status === 'success'
								? '/images/svgs/success-dark.svg'
								: status === 'error' && '/images/svgs/danger-circle-dark.svg'
						}
						isSuccess={status === 'success'}
						isError={status === 'error'}
						message={
							status === 'success'
								? `${title} info has been successfully updated`
								: 'The error occurred during updating account. Please, try again!'
						}
					/>
				</Box>
			)}
		</>
	)
}

export { UpdateStatus }
