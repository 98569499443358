export enum CheckboxName {
  NOT_TRUE = 'not_true',
  HARMFUL = 'harmful',
  NOT_HELPFUL = 'not_helpful'
};

export interface ICheckbox {
  label: string;
  checked: boolean;
  name: CheckboxName
}

export const checkboxLabels = [
	{ label: 'This is harmful/unsafe', checked: true, name: CheckboxName.HARMFUL},
	{ label: "This isn't true", checked: true, name: CheckboxName.NOT_TRUE},
	{ label: "This isn't helpful", checked: true, name: CheckboxName.NOT_HELPFUL},
]