import { getThemeReducer, useAppSelector } from "../../../store";
import { PowerUpWrapper } from "./powerUp.style";

const PowerUp = () => {
	const { themeMode } = useAppSelector(getThemeReducer)

  return (
    <PowerUpWrapper sx={{themeMode}}>Power Up</PowerUpWrapper>
  );
};

export { PowerUp };