const baseURL = `${process.env.REACT_APP_API_URL}api/v1/`;

const loans = 'loans/';
const chatTimeline = 'chat_timeline';
const suggestions = 'suggestions/';
const bulkUpdate = 'bulk_update';
const newLoan = 'new-loan/';
const newDocument = 'new_documents/';
const analyses = 'analyses/';
const feedback = 'feedback/';
const messages = 'messages/';
const documents = 'documents/';
const users = 'users/';
const me = 'me';
const company = 'companies/';
const speechToText = 'speech-to-text/';
const billingAccount = 'billing_accounts'
const stream = 'stream';

const urls = {
	loans,
	newLoan,
	loanById: (loanId: number): string => `${loans}${loanId.toString()}`,
	messages: (loanId: number): string => `${urls.loanById(loanId)}/${messages}`,
	chatTimeline: (loanId: number): string =>
		`${urls.loanById(loanId)}/${chatTimeline}`,
	documents: (loanId: number): string =>
		`${urls.loanById(loanId)}/${documents}`,
	suggestions: (loanId: number): string =>
		`${urls.loanById(loanId)}/${suggestions}`,
	bulkUpdate: (loanId: number): string =>
		`${urls.loanById(loanId)}/${bulkUpdate}`,
	uploadNewDocument: (loanId: number): string =>
		`${urls.loanById(loanId)}/${newDocument}`,
	updateDocument: (loanId: number, document_id: number): string => `${urls.loanById(loanId)}/${documents}${document_id}`,
	analyses: (loanId: number): string => `${urls.loanById(loanId)}/${analyses}`,
	feedback: (loanId: number, messageId: number): string =>
		`${urls.loanById(loanId)}/${messages}${messageId}/${feedback}`,
	me: `${users}${me}`,
	company: `${company}/${me}`,
	speechToText: `${speechToText}`,
	billingAccount: `${billingAccount}/${me}`,
	docStream: (loanId: number, document_id: number): string => `${urls.documents(loanId)}${document_id}/${stream}`, 
}

export {
  baseURL,
  urls,
}