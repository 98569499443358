import { memo, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
	InnerSideBarWrapper,
	ButtonWrapper,
	PlusIcon,
} from './innerSideBar.style'
import { ActiveList } from '../../components/common/ActiveList/ActiveList'
import { useLoans } from '../../hook/useLoans'
import { ModalContext } from '../../context'
import { ModalWrapper } from '../../components/allModals/upload/ModalWrapper'
import { getThemeReducer, useAppSelector } from '../../store'

const InnerSideNav = memo(() => {
	const location = useLocation()
	const { isPathnameIsLoans, hasLoansNextPage, fetchLoansNextPage, sortedLoans } = useLoans()
	const {openModal, isModalOpen, modalKey } = useContext(ModalContext);
	const loanWrapper = useRef<HTMLDivElement>(null);
	const [shouldAutoFetchNextPage, setShouldAutoFetchNextPage] =
		useState<boolean>(false)
	const { themeMode } = useAppSelector(getThemeReducer)

	useEffect(() => {
		if (loanWrapper) {
			if (
				loanWrapper?.current?.scrollHeight ===
					loanWrapper?.current?.clientHeight &&
				hasLoansNextPage
			) {
				setShouldAutoFetchNextPage(true)
				fetchLoansNextPage()
			} else {
				setShouldAutoFetchNextPage(false)
			}
		}
	}, [loanWrapper, sortedLoans, hasLoansNextPage, fetchLoansNextPage])

	return (
		<>
			<InnerSideBarWrapper
				ref={loanWrapper}
				sx={{ themeMode }}
				is_pathname_is_loans={isPathnameIsLoans}
				pathname={location.pathname}
				id='innerSideBarWrapper'
			>
				{isPathnameIsLoans && (
					<ButtonWrapper
						startIcon={<PlusIcon src='/images/svgs/add.svg' />}
						onClick={() => openModal('loan')}
					>
						New Loan
					</ButtonWrapper>
				)}
				<ActiveList shouldAutoFetchNextPage={shouldAutoFetchNextPage} />
			</InnerSideBarWrapper>
			<ModalWrapper open={isModalOpen && modalKey === 'loan'} />
		</>
	)
})
export default InnerSideNav
