import { Typography } from '@mui/material';
import {Box, styled} from '@mui/system';

export const PowerUpFormItemWrapper = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'flex-start',
	gap: '10px',
	marginBottom: '25px',
})) 

export const PowerUpItemTitle = styled(Typography)(() => ({
	fontWeight: 600,
	fontSize: '18px',
	marginBottom: '7px',
	fontFamily: 'Bai Jamjuree',
}))

export const PowerUpItemSubTitle = styled(Typography)(() => ({
	fontWeight: 400,
	fontSize: '15px',
	lineHeight: '25px',
	fontFamily: 'Bai Jamjuree',

}))